// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { NETWORK_IDS, TECHNOLOGIES, TRUST_FRAMEWORKS } from '#constants';
import { isOk, post } from '../../../helpers/axios.helper';
import { setError } from '../../../store/reducers/error';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';


// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AttachPresentationPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const formSent = props.formSent;
  const presentationRequest = props.presentationRequest;
  const data = presentationRequest.decoded ? presentationRequest.decoded.pr.data : [];

  const defaultSelectionStatus = presentationRequest.presentations || {};
  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [selectionStatus, setSelectionStatus] = useState(defaultSelectionStatus);

  const handleClose = () => {
    if (formSendState) return;

    setSelectionStatus(defaultSelectionStatus);
    setIsOpen(false);
  };

  const isFormValid = () => {
    const keys = Object.keys(selectionStatus);
    const signedTokens = keys
      .map((key) => selectionStatus[key])
      .filter((credential) => credential)
      .map((credential) => credential.storedJwt.signedToken);
    return keys.length > 0 && signedTokens.length === keys.length;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    setFormSendState(true);

    const keys = Object.keys(selectionStatus);
    const signedTokens = keys
      .map((key) => selectionStatus[key])
      .filter((credential) => credential)
      .map((credential) => credential.storedJwt.signedToken);

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid: 'default',
      sub: presentationRequest.decoded.iss,
      cbu: presentationRequest.decoded.cbu,
      validFrom: presentationRequest.decoded.nbf,
      validUntil: presentationRequest.decoded.exp,
      presentationContext: 'https://www.w3.org/2018/credentials/presentation/examples/v1',
      presentationRequestJti: presentationRequest.storedJwt.jti,
      cdi: presentationRequest.decoded.cdi,
      cdiName: presentationRequest.decoded.cdiName,
      procHash: presentationRequest.decoded.pr.procHash,
      credentials: signedTokens
    };

    let queryParams = new URLSearchParams();
    queryParams.append('propagate', true);
    queryParams.append('notUpdatePr', true);

    setFormSendState(true);
    const response = await post('/dit/presentation?' + queryParams, form);

    if (!isOk(response.status)) {
      dispatch(setError(response));
    }

    setFormSendState(false);
    setSelectionStatus(defaultSelectionStatus);
    formSent();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('presentation-attach-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('presentation-attach-popup-sending')} isLoading={formSendState}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingBottom={1}>
              <Typography>{translate('presentation-sign-popup-disclaimer')}</Typography>
            </Grid>

            {data.filter((credential) => !!credential).map((credential) => {
              if (selectionStatus[credential.field_name] === undefined) {
                let modifiableStatus = { ...selectionStatus };
                modifiableStatus[credential.field_name] = credential.emitted.length === 0 ? '' : credential.emitted[0];
                setSelectionStatus(modifiableStatus);
              }

              return (
                <Fragment key={credential.alias}>
                  <Grid item='true' paddingTop={1}>
                    <Typography>{credential.alias}</Typography>
                  </Grid>
                  <Dropdown
                    value={selectionStatus[credential.field_name]}
                    options={credential.emitted}
                    getOptionLabel={(emitted) => `${emitted.decoded.vc.credentialSubject[emitted.decoded.vc.catalog.name]} - LOA ${emitted.decoded.vc.credentialSubject.levelOfAssurance}`}
                    onChange={(emitted) => {
                      let modifiableStatus = { ...selectionStatus };
                      modifiableStatus[credential.field_name] = emitted;
                      setSelectionStatus(modifiableStatus);
                    }}
                    selectStyle={{
                      width: '20rem',
                      height: '4rem',
                      borderRadius: '10px',
                      fontSize: '1.125rem'
                    }}
                  />
                </Fragment>
              );
            })}
            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-sign-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-sign-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
