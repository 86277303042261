// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import PresentationRequestRow from '../Rows/PresentationRequestRow.component';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_ISSUER = false;
const DEFAULT_HIDE_NAME = false;
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_STATUS = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_TYPE = false;
const DEFAULT_PRESENTATION_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => { };
const DEFAULT_SET_ROWS_PER_PAGE = () => { };
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUESTS = [];

const issuerText = 'presentation-request-table-issuer';
const requesterText = 'presentation-request-table-receiver';
const requestNameText = 'presentation-request-table-request-name';

function getHeaders(translate, hideIssuer, hideName, hideStatus, hideActions, hideType) {
  return [
    {
      text: requestNameText
    },
    {
      text: issuerText
    },
    {
      text: requesterText
    },
    {
      text: 'presentation-request-table-type'
    },
    {
      text: 'presentation-request-table-valid-dates'
    },
    {
      text: 'presentation-request-table-status'
    },
    {
      text: 'presentation-request-table-actions'
    }
  ]
    .filter((header) => {
      if (hideIssuer && header.text === issuerText) {
        return false;
      }

      if (!hideIssuer && header.text === requesterText) {
        return false;
      }

      if (hideName && header.text === requestNameText) {
        return false;
      }

      if (hideStatus && header.text === 'presentation-request-table-status') {
        return false
      }

      if (hideActions && header.text === 'presentation-request-table-actions') {
        return false
      }

      if (hideType && header.text === 'presentation-request-table-type') {
        return false
      }

      return true;
    }).map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideIssuer = props.hideIssuer || DEFAULT_HIDE_ISSUER;
  const hideName = props.hideName || DEFAULT_HIDE_NAME;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideStatus = props.hideStatus || DEFAULT_HIDE_STATUS;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideType = props.hideType || DEFAULT_HIDE_TYPE;
  const presentationRequests = props.presentationRequests || DEFAULT_PRESENTATION_REQUESTS;
  const presentationCount = props.presentationCount || DEFAULT_PRESENTATION_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const buttonComponent = props.buttonComponent;
  const admin = props.admin || false;
  const disableOnClick = props.disableOnClick || false;

  let cdiPrs = presentationRequests;
  // let formPrs = cdiPrs.filter((pr) =>
  //   pr.decoded.pr.type.includes('Form'));
  // formPrs = formPrs.filter((pr, index) =>
  //   (formPrs.findIndex(obj => obj.decoded.cdi === pr.decoded.cdi) === index));
  // let delegatedPrs = cdiPrs.filter((pr) =>
  //   pr.decoded.pr.type.includes('Delegated'));

  if (admin) {
    cdiPrs = cdiPrs.filter((pr) =>
      pr.decoded.pr.type.includes('Form'));
  }

  console.log(cdiPrs);
  let acceptedPrs = cdiPrs.filter((pr) => pr.storedJwt.status === 'Accepted' && pr.decoded.pr.type.includes('Form'));
  console.log(acceptedPrs);
  cdiPrs = cdiPrs.filter((pr, index) =>
    (cdiPrs.findIndex(obj => obj.decoded.cdi === pr.decoded.cdi) === index));
  console.log(cdiPrs);
  cdiPrs.forEach((pr) => {
    if(acceptedPrs.some(e => e.decoded.cdi === pr.decoded.cdi)) {
      pr = JSON.parse(JSON.stringify(pr));
      pr.storedJwt.status = 'Accepted'
    }
  });
  console.log(cdiPrs);
const headers = getHeaders(translate, hideIssuer, hideName, hideStatus, hideActions, hideType);

return (
  <Grid item='true' paddingBottom={paddingBottom}>
    <TableContainer className='presentation-request-table-container'>
      <Table>
        <TableHeader headers={headers} />
        <TableBody>
          <SpinnerLoader
            isLoading={isLoading}
            text={translate('presentation-request-table-loading')}
            component={SingleTableRow}
            componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
            {cdiPrs.map((presentationRequest, index) => (
              <PresentationRequestRow
                key={index}
                translate={translate}
                presentationRequest={presentationRequest}
                buttonComponent={buttonComponent}
                hideSignButton={hideSignButton}
                hideName={hideName}
                hideIssuer={hideIssuer}
                disableOnClick={disableOnClick}
                admin={admin}
                hideStatus={hideStatus}
                hideActions={hideActions}
                hideType={hideType}
              />
            ))}
          </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={translate(`rows-per-page`)}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
