import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: false
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setError: (state, action) => {
      // Redux Toolkit allows us to write 'mutating' logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a 'draft state' and produces a brand new
      // immutable state based off those changes
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    }
  }
});

export const { setError, clearError } = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.session.value)`
export const selectError = (state) => state.error.error;

export default errorSlice.reducer;
