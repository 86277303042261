//REACT IMPORTS
import React from 'react';
import { Outlet } from "react-router-dom";
//MUI IMPORTS
import Container from '@mui/material/Container';
//CSS IMPORT
import './Landing.layout.css';
//VIEWS IMPORT

//COMPONENT
export default function LandingLayout(props) {
  return (
    <div id={'lading-layout-main-component'}>
      <Container disableGutters={true} maxWidth={false} sx={{m: 0}}>
        <Outlet />
      </Container>
    </div>
  )
}