// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS, PAGE_ROUTES, REDUCER_STATUS } from '#constants';
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../../store/reducers/customers';

import {
  fetchEntityPresentationsAsync,
  selectEntityPresentations,
  selectEntityPresentationsCount,
  selectEntityPresentationsPage,
  selectEntityPresentationsState
} from '../../../../store/reducers/entityPresentations';

import PresentationRequestTable from '../../../../components/private/Tables/PresentationRequestTable.component';
import PresentationFilters from '../../../../components/private/Filters/PresentationFilters.component';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityPresentations(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const [page, setPage] = useState(useSelector(selectEntityPresentationsPage));
  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);

  const presentations = useSelector(selectEntityPresentations);
  const presentationState = useSelector(selectEntityPresentationsState);
  const presentationCount = useSelector(selectEntityPresentationsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoading = presentationState === REDUCER_STATUS.LOADING;
  const needsReload = presentationState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (needsReload) {
    dispatch(
      fetchEntityPresentationsAsync({
        page,
        search,
        customer: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(
      fetchEntityPresentationsAsync({
        page,
        search,
        customer: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  };

  return (
    <ViewsLayout pageTitle={translate('entity-presentations-page-my-presentations')} layoutSize={layoutSize}>
      <Grid item='true'>
        <PresentationFilters
          translate={translate}
          users={customers}
          isRequest={false}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <PresentationRequestTable
        translate={translate}
        isLoading={isLoading}
        detailsRoute={PAGE_ROUTES.EntityPresentationSignedDetails}
        presentationRequests={presentations}
        presentationCount={presentationCount}
        page={page}
        setPage={setPage}
        hideSignButton
        hideDelegateButton
        buttonComponent={Fragment}
      />
    </ViewsLayout>
  );
}
