import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AdminRequestLibraryIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M8.71865 11.1383L13.0055 4.16309L17.2924 11.1383H8.71865ZM18.7948 22.6727C17.717 22.6727 16.8143 22.3055 16.0864 21.5709C15.3586 20.8364 14.9947 19.9303 14.9947 18.8526C14.9947 17.7749 15.3586 16.8721 16.0864 16.1443C16.8143 15.4164 17.717 15.0525 18.7948 15.0525C19.8725 15.0525 20.7786 15.4164 21.5131 16.1443C22.2476 16.8721 22.6149 17.7749 22.6149 18.8526C22.6149 19.9303 22.2476 20.8364 21.5131 21.5709C20.7786 22.3055 19.8725 22.6727 18.7948 22.6727ZM4.42578 22.0818V15.5473H10.9602V22.0818H4.42578ZM18.8064 21.8714C19.6387 21.8714 20.3479 21.5778 20.9342 20.9904C21.5205 20.403 21.8136 19.6899 21.8136 18.851C21.8136 18.0121 21.5199 17.3028 20.9326 16.7232C20.3452 16.1436 19.6321 15.8538 18.7932 15.8538C17.9542 15.8538 17.245 16.1448 16.6654 16.7268C16.0858 17.3088 15.796 18.0213 15.796 18.8643C15.796 19.6965 16.087 20.4058 16.669 20.992C17.251 21.5783 17.9635 21.8714 18.8064 21.8714ZM5.22706 21.2805H10.159V16.3486H5.22706V21.2805ZM10.1289 10.337H15.9022L13.0055 5.72361L10.1289 10.337Z'
        fill='#F0F3FF'
      />
    </SvgIcon>
  );
}
