import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  accessToken: sessionStorage.getItem('accessToken') || '',
  idToken: sessionStorage.getItem('idToken') || ''
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSession: (state, action) => {
      // Redux Toolkit allows us to write 'mutating' logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a 'draft state' and produces a brand new
      // immutable state based off those changes
      state.accessToken = 'Bearer ' + action.payload.accessToken;
      state.idToken = 'Bearer ' + action.payload.idToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      axios.defaults.headers.common['X-Id-Token'] = state.idToken;
      sessionStorage.setItem('accessToken', state.accessToken);
      sessionStorage.setItem('idToken', state.idToken);
    },
    clearSession: (state) => {
      state.accessToken = initialState.accessToken;
      state.idToken = initialState.idToken;
      axios.defaults.headers.common['Authorization'] = undefined;
      axios.defaults.headers.common['X-Id-Token'] = undefined;
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('idToken');
    }
  }
});

export const { setSession, clearSession } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.session.value)`
export const selectAccessToken = (state) => state.session.accessToken;
export const selectIdtoken = (state) => state.session.idToken;

export default sessionSlice.reducer;
