import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FileInput from '../../shared/FileInput/FileInput.component';

export default function DynamicForm(props) {
  const translate = props.translate;
  const format = props.format;
  const fixedValue = props.fixedValue;
  const form = JSON.parse(props.formJson);
  const formState = props.value;

  const readOnly = fixedValue !== undefined;
  let formatToCheck = format.toLowerCase();

  if (readOnly) {
    formatToCheck = 'value';
  }

  return (
    <>
      {Object.keys(form).map((formKey) => {
        switch (formatToCheck) {
          case 'value':
          default:
            return (
              <Grid key={formKey} container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('dynamic-form-credential-value')}
                  </Typography>
                </Grid>
                <Grid item='true' paddingTop={1} paddingRight={1} paddingBottom={1}>
                  <TextField
                    required
                    disabled={readOnly}
                    value={formState[formKey] || ''}
                    placeholder={translate('dynamic-form-credential-value-placeholder')}
                    onChange={(event) => {
                      let modifiableState = { ...formState };
                      modifiableState[formKey] = event.target.value;

                      if (props.onChange) {
                        props.onChange(modifiableState);
                      }
                    }}
                    sx={{
                      height: '2.5rem',
                      opacity: readOnly ? '0.5' : '1',
                      width: '22.125rem',
                      backgroundColor: 'white',
                      border: '1px solid #E5E5E5',
                      borderRadius: '0.313rem',
                      fontSize: '0.938rem',
                      '.MuiInputBase-root': {
                        height: '2.5rem',
                        '&:hover': {
                          border: '1.25px solid #E5E5E5'
                        }
                      },
                      '&.Mui-focused': {
                        border: 'none'
                      },
                      '&.MuiOutlinedInput-root:hover': {
                        border: 'none'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            );
          case 'file':
            return (
              <Grid key={formKey} container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('dynamic-form-attach-file')}
                  </Typography>
                </Grid>
                <Grid item='true' paddingTop={1} paddingRight={1} paddingBottom={1}>
                  <FileInput
                    required
                    disabled={readOnly}
                    value={formState[formKey] || ''}
                    placeholder={translate('dynamic-form-attach-file-placeholder')}
                    onChange={(file) => {
                      let modifiableState = { ...formState };
                      modifiableState[formKey] = file;

                      if (props.onChange) {
                        props.onChange(modifiableState);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            );
        }
      })}
    </>
  );
}
