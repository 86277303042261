import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import config from '#config';
import { decodeAllJwts, mapPresentationRequests } from '../../helpers/jwt.helpers';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';
import mockedData from '../../config/mockedData.config';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchEntityPresentationRequestsAsync = createAsyncThunk(
  'entityPresentationRequests/fetchPresentations',
  async ({ page, startDate, endDate, search, searchCdiName, issuer}) => {
    // count should be made user configurable.
    const limit = ROW_COUNT[0];
    const form = new URLSearchParams();
    let jwts = [];
    let endpoint = '/corporate/presentationRequest';
    let mappingFunc = mapPresentationRequests;

    let count = 0;
    if (config.INTERNAL.MOCKED) {
      jwts = mockedData.presentations;
      count = jwts.length;
    } else {
      form.append('offset', limit * page);
      form.append('limit', limit);
      form.append('isDeleted', false);

      if (search) {
        form.append('search', search);
      }

      if (searchCdiName) {
        form.append('searchCdiName', searchCdiName);
      }
      
      if (startDate) {
        form.append('validUntil', startDate);
      }

      if (endDate) {
        form.append('expiredFrom', endDate);
      }

      if (issuer) {
        form.append('issuer', issuer);
      }

      const response = await get(endpoint, form);

      if (isOk(response.status)) {
        jwts = getResponseJson(response);
        count = Number(response.headers['x-app-total-count']) || 0;
      }
    }

    const value = await mappingFunc(decodeAllJwts(jwts), true);
    return { page, count, value };
  }
);

export const entityPresentationRequestsSlice = createSlice({
  name: 'entityPresentationRequests',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearEntityPresentationRequestsCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntityPresentationRequestsAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchEntityPresentationRequestsAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.count = payload.count;
      });
  }
});

export const { clearEntityPresentationRequestsCache } = entityPresentationRequestsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectEntityPresentationRequests = (state) => state.entityPresentationRequests.value;
export const selectEntityPresentationRequestsState = (state) => state.entityPresentationRequests.status;
export const selectEntityPresentationRequestsPage = (state) => state.entityPresentationRequests.page;
export const selectEntityPresentationRequestsCount = (state) => state.entityPresentationRequests.count;

export default entityPresentationRequestsSlice.reducer;
