// Creates a function that takes a performance entry as argument
const reportWebVitals = (onPerfEntry) => {
  // If the perf entry exists and it's a function, we do the import and call the web vitals methods
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // Gets the 'Cumulative Layout Shift'
      getCLS(onPerfEntry);
      // Gets the 'First Input Delay'
      getFID(onPerfEntry);
      // Gets the 'First Contentful Paint'
      getFCP(onPerfEntry);
      // Gets the 'Largest Contentful Paint'
      getLCP(onPerfEntry);
      // Gets the Time to First Byte'
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
