// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import {
  fetchCatalogAsync,
  selectCatalogState,
  selectCatalog,
  selectCatalogCount,
  selectCatalogPage,
  selectCatalogRowsPerPage,
  clearCatalogCache
} from '../../../store/reducers/catalog';
import { setError } from '../../../store/reducers/error';

import { clearPresentationRequestTemplatesCache } from '../../../store/reducers/presentationRequestTemplates';

import { MAX_LENGTH, REDUCER_STATUS } from '#constants';

import CreatePRTCredentialSelectionFilters from './CreatePRTCredentialSelectionFilters.component';

import CatalogCredentialTable from '../Tables/CatalogCredentialTable.component';
import CatalogFilters from '../Filters/CatalogFilters.component';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

import services from '../../../services';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreatePRTCredentialSelection(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const onCancelOrExit = props.onCancelOrExit;
  const title = props.title;
  const description = props.description;

  const [formSendState, setFormSendState] = useState(false);
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [jurisdictions, setJurisdictions] = useState(undefined);
  const [page, setPage] = useState(useSelector(selectCatalogPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectCatalogRowsPerPage));
  const [selectedCredentials, setSelectedCredentials] = useState({});

  const catalog = useSelector(selectCatalog);
  const catalogCount = useSelector(selectCatalogCount);
  const catalogState = useSelector(selectCatalogState);

  const dispatch = useDispatch();

  const catalogLoading = catalogState === REDUCER_STATUS.LOADING;
  const catalogNeedsReload = catalogState === REDUCER_STATUS.NEEDS_REFRESH;

  if (catalogNeedsReload) {
    dispatch(
      fetchCatalogAsync({
        page,
        limit: rowsPerPage,
        search,
        jurisdictions
      })
    );
  }

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearCatalogCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCatalogCache());
  };

  const handleClose = () => {
    if (formSendState) return;

    setSelectedCredentials({});
    onCancelOrExit(false);
  };

  const isFormValid = () => {
    return title && description && Object.keys(selectedCredentials).length > 0;
  };

  const onCredentialSelectionChanged = (e, catalogEntry, loa) => {
    let modifySelectedCredentials = { ...selectedCredentials };

    if (e.target.checked) {
      modifySelectedCredentials[catalogEntry.name] = {
        loa: Number(loa),
        catalog: catalogEntry
      };
    } else {
      delete modifySelectedCredentials[catalogEntry.name];
    }

    setSelectedCredentials(modifySelectedCredentials);
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCatalogCache());
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    const credentialKeys = Object.keys(selectedCredentials);

    const form = {
      title,
      description,
      credentials: credentialKeys.map((credentialKey) => {
        return {
          type: credentialKey,
          loa: selectedCredentials[credentialKey].loa,
          credentialContext: selectedCredentials[credentialKey].catalog.context
        };
      })
    };

    setFormSendState(true);

    await services.orchestrator.createPresentationRequestTemplate(form, setErrorPopup);

    setFormSendState(false);
    dispatch(clearPresentationRequestTemplatesCache());
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xl'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('admin-prt-popup-create-template')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('admin-prt-popup-sending')} isLoading={formSendState}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px' paddingBottom='10px'>
              <Typography fontWeight='bold' fontSize='0.938rem'>
                {translate('admin-prt-popup-credential-selection-title')}
              </Typography>
            </Grid>

            <CatalogFilters
              translate={translate}
              isLoading={catalogLoading}
              search={search}
              setSearch={setSearch}
              onFilterSubmit={onFilterSubmit}
              refresh={onFilterSubmit}
              openAdvanced={() => setIsAdvancedFiltersOpen(true)}
            />

            <CreatePRTCredentialSelectionFilters
              translate={translate}
              handleClose={() => setIsAdvancedFiltersOpen(false)}
              isOpen={isAdvancedFiltersOpen}
              setJurisdictions={setJurisdictions}
            />

            <CatalogCredentialTable
              translate={translate}
              isLoading={catalogLoading}
              catalog={catalog}
              catalogCount={catalogCount}
              page={page}
              setPage={handleSetPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={handleSetRowsPerPage}
              onCredentialSelectionChanged={onCredentialSelectionChanged}
              selectedCredentials={selectedCredentials}
              hideActions={true}
              maxNameLength={MAX_LENGTH[0]}
              maxUseCaseLength={MAX_LENGTH[0]}
            />

            <Grid item='true' paddingBottom='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={onCancelOrExit}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('admin-prt-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('admin-prt-popup-submit-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
