import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function LogoutIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 24, height: 24 }}>
      <path
        d='M16.9992 7.99951L20.9992 11.9995M20.9992 11.9995L16.9992 15.9995M20.9992 11.9995H8.99922M11.9992 2.99951H6.2C5.07989 2.99951 4.51984 2.99951 4.09202 3.2175C3.71569 3.40925 3.40973 3.71521 3.21799 4.09153C3 4.51935 3 5.07941 3 6.19951V17.7995C3 18.9196 3 19.4797 3.21799 19.9075C3.40973 20.2838 3.71569 20.5898 4.09202 20.7815C4.51984 20.9995 5.07989 20.9995 6.2 20.9995H11.9992'
        stroke='#1E1E56'
        fill='none'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
