import { useDispatch, useSelector } from "react-redux";

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';

import { clearSession, selectIdtoken } from "../../../store/reducers/session";
import { PAGE_ROUTES } from "#constants";
import { Navigate } from "react-router-dom";
import config from "#config"

import LogoutIcon from '../../shared/Icons/LogoutIcon.component';
import axios from "axios";

const { Root } = PAGE_ROUTES;
const DEFAULT_TRANSLATE = (string) => { return string; };
const DEFAULT_ON_CLICK = () => {};

export default function Logout(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const onClick = props.onClick || DEFAULT_ON_CLICK;

  const dispatch = useDispatch();
  const idToken = useSelector(selectIdtoken);
  const handleLogout = async () =>  {

    if (idToken)
      window.location.href = config.API.AUTH_SERVER_URL + `/logout?id_token=${idToken.replace('Bearer ', '')}`; 
    else {
      let response = await axios.get(config.API.AUTH_SERVER_URL + '/logout', {headers: {Accept: 'application/json'}, withCredentials: true});
      window.location.href = response.data.logout_url;
    }
      dispatch(clearSession());
      onClick();
      return <Navigate to={Root} />
  };

  return (
    <MenuItem onClick={handleLogout}>
      <Grid container spacing={1}>
        <Grid item='true'>
          <LogoutIcon sx={{ color: '#662D91' }} />
        </Grid>
        <Grid item='true'>
          <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
            {translate('user-action-logout')}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );
}