// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS } from '#constants';

import SearchBar from '../../shared/SearchBars/SearchBar.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_STATUS = '';
const DEFAULT_SET_STATUS = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function ContactFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const status = props.status || DEFAULT_STATUS;
  const setStatus = props.setStatus || DEFAULT_SET_STATUS;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;

  const HandlerRefresh = () => {

    setSearch('');
    setStatus('');

    refresh();

  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='entitiesFilterContainer'
      paddingTop='0.5em'
      paddingBottom='0.5em'>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('contacts-filter-search-contact')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={() => {
            onFilterSubmit();
          }}
        />
      </Grid>

      <Dropdown
        value={status}
        options={CUSTOMER_STATUS.map((status) => {
          return { status };
        })}
        getOptionLabel={(status) => translate(`contacts-page-filter-status-${status.status.toLowerCase()}`)}
        getOptionValue={(status) => status.status}
        onChange={(status) => {
          setStatus(status);
          onFilterSubmit();
        }}
        label={translate('contacts-page-filter-status')}
      />

      <Grid item='true'>
        <Button
          color='primary'
          variant="outlined"
          sx={{
            height: '2.5rem',
            textTransform: 'none'
          }}
          onClick={HandlerRefresh}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('contacts-page-filter-refresh')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
