// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import TableCell from './TableCell.component';
import Hider from '../Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_HEADERS = [];
const DEFAULT_FONT_SIZE = '0.938rem';
const DEFAULT_FONT_WEIGHT = 'bold';
const DEFAULT_CELL_PROPS = {
  sx: {
    whiteSpace: 'nowrap',
    border: 'none'
  }
};
const DEFAULT_TYPOGRAPHY_PROPS = {};
const DEFAULT_TYPOGRAPHY_SX = {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function TableHeader(props) {
  const headers = props.headers || DEFAULT_HEADERS;
  const fontSize = props.fontSize || DEFAULT_FONT_SIZE;
  const fontWeight = props.fontWeight || DEFAULT_FONT_WEIGHT;
  const typographySx = { ...DEFAULT_TYPOGRAPHY_SX, ...props.typographySx, fontSize, fontWeight };
  const typographyProps = { ...DEFAULT_TYPOGRAPHY_PROPS, ...props.typographyProps, ...typographySx };
  const muiCellProps = { ...DEFAULT_CELL_PROPS, ...props.cellProps };
  const cellProps = { ...muiCellProps };

  return (
    <TableHead>
      <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
        {headers.map((header) => (
          <TableCell cellProps={cellProps} key={header.text} useTypography={false}>
            <Grid container justifyContent={header.align}>
              <Grid item='true'>
                <Typography {...typographyProps}>{header.text}</Typography>
              </Grid>
              <Hider isHidden={!header.component}>
                <Grid item='true' paddingLeft='0.75rem' display='flex' alignItems='center'>
                  {header.component}
                </Grid>
              </Hider>
            </Grid>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
