// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../../store/reducers/customers';

import {
  clearEntityCredentialRequestsCache,
  fetchEntityCredentialRequestsAsync,
  selectEntityCredentialRequests,
  selectEntityCredentialRequestsCount,
  selectEntityCredentialRequestsPage,
  selectEntityCredentialRequestsRowsPerPage,
  selectEntityCredentialRequestsState
} from '../../../../store/reducers/entityCredentialRequests';

import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import EntityCredentialRequestButtons from '../../../../components/private/Dropdowns/EntityCredentialRequestButtons';
import RequestCredentialPopup from '../../../../components/private/Popups/RequestCredentialPopup.component';
import CredentialFilters from '../../../../components/private/Filters/CredentialFilters.component';
import CredentialTable from '../../../../components/private/Tables/CredentialTable.component';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityCredentialRequests(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const credentialRequests = useSelector(selectEntityCredentialRequests);
  const credentialRequestsState = useSelector(selectEntityCredentialRequestsState);
  const credentialRequestsCount = useSelector(selectEntityCredentialRequestsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectEntityCredentialRequestsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectEntityCredentialRequestsRowsPerPage));

  const isCredentialRequestsLoading = credentialRequestsState === REDUCER_STATUS.LOADING;
  const credentialRequestsNeedsReload = credentialRequestsState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialRequestsNeedsReload) {
    dispatch(
      fetchEntityCredentialRequestsAsync({
        page,
        rowsPerPage,
        search,
        customer: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearEntityCredentialRequestsCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearEntityCredentialRequestsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearEntityCredentialRequestsCache());
  };

  return (
    <ViewsLayout
      pageTitle={translate('entity-credential-requests-page-my-credential-requests')}
      buttons={[
        {
          onClick: () => setIsOpen(true),
          text: translate('entity-credentials-page-request-credential')
        }
      ]}
      layoutSize={layoutSize}>
      <RequestCredentialPopup
        translate={translate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        users={customers}
      />

      <Grid item='true'>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={true}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        isLoading={isCredentialRequestsLoading}
        credentials={credentialRequests}
        credentialCount={credentialRequestsCount}
        isCredentialRequests={true}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideLevelOfAssurance={true}
        translatePrefix={'credential-request-table'}
        actionsComponent={EntityCredentialRequestButtons}
      />
    </ViewsLayout>
  );
}
