import { post, isOk, getResponseJson } from '../../helpers/axios.helper';

import config from '#config';

async function uploadFileToFileManagerInternal(file, procHash, fileApiEndpoint) {
  const uriSafeFileName = encodeURIComponent(procHash);

  let response = { status: 200, data: [] };

  try {
    const loginResponse = await post(
      `${fileApiEndpoint}/login`,
      { username: 'admin', password: 'admin', recaptcha: '' },
      { useApiUrl: false, withCredentials: false }, undefined, true
    );

    if (!isOk(loginResponse.status)) {
      return { status: 400 };
    }

    response = await post(
      `${fileApiEndpoint}/resources/${uriSafeFileName}?override=false`,
      file,
      { useApiUrl: false, withCredentials: false },
      { 'X-Auth': loginResponse.data, 'Content-Type': file.type }, true
    );
  } catch (exception) {
    response = exception;
  }

  response.uriSafeFileName = uriSafeFileName;
  return response;
}

async function makeFilePublic(procHash, fileApiEndpoint) {
  const uriSafeFileName = encodeURIComponent(procHash);

  let response = { status: 200, data: [] };

  try {
    const loginResponse = await post(
      `${fileApiEndpoint}/login`,
      { username: 'admin', password: 'admin', recaptcha: '' },
      { useApiUrl: false, withCredentials: false }, undefined, true
    );

    if (!isOk(loginResponse.status)) {
      return { status: 400 };
    }

    response = await post(
      `${fileApiEndpoint}/share/${uriSafeFileName}`,
      {},
      { useApiUrl: false, withCredentials: false },
      { 'X-Auth': loginResponse.data }, true
    );
  } catch (exception) {
    response = exception;
  }

  response.uriSafeFileName = uriSafeFileName;
  return response;
}

export async function uploadFileToFileManager(file, procHash, onError) {
  if (config.INTERNAL.MOCKED) {
    return `${window.location.protocol}${config.EXTERNAL_SERVICES.FILE_EXPLORER}${window.location.hostname}/files/$test123`;
  }

  const fileApiEndpoint = `${window.location.protocol}${config.EXTERNAL_SERVICES.FILE_EXPLORER}${window.location.hostname}/api`;
  const fileUpload = await uploadFileToFileManagerInternal(file, procHash, fileApiEndpoint);

  if (!isOk(fileUpload.status)) {
    onError(fileUpload);
    return null;
  }

  const sharedResult = await makeFilePublic(procHash, fileApiEndpoint);

  if (!isOk(sharedResult.status)) {
    onError(sharedResult);
    return null;
  }

  const shared = sharedResult.data;
  return `${window.location.protocol}${config.EXTERNAL_SERVICES.FILE_EXPLORER}${window.location.hostname}/api/public/dl/${shared.hash}?inline=true`;
}
