import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import { decodeAllJwts, mapCredentials } from '../../helpers/jwt.helpers';
import { CREDENTIAL_STATUS, REDUCER_STATUS, ROW_COUNT } from '#constants';

import config from '#config';
import mockedData from '../../config/mockedData.config';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCredentialsAsync = createAsyncThunk(
  'credentials/fetchCredentials',
  async ({ page, rowsPerPage, credentialType, search, customerId, status }) => {
    // count should be made user configurable.
    const limit = rowsPerPage === undefined ? initialState.rowsPerPage : rowsPerPage;
    const form = new URLSearchParams();
    let jwts = [];
    let endpoint = '/dit/credential';
    let didFilter = 'issuer';
    let customerFilter = 'subject';
    let mappingFunc = mapCredentials;

    let count = 0;
    if (config.INTERNAL.MOCKED) {
      jwts = mockedData.credentials;
      count = jwts.length;
    } else {
      const whoAmIResponse = await get('/corporate');

      if (isOk(whoAmIResponse.status)) {
        const whoAmI = getResponseJson(whoAmIResponse);
        form.append(didFilter, whoAmI.did);
        form.append('offset', limit * page);
        form.append('limit', limit);
        form.append('isDeleted', false);

        if (credentialType) {
          form.append('type', credentialType);
        }

        if (search) {
          const catalogForm = new URLSearchParams();
          catalogForm.append('search', search);
          const catalogResponse = await get('/catalog', catalogForm);
          if (isOk(catalogResponse.status)) {
            form.append('search', encodeURIComponent(JSON.stringify(getResponseJson(catalogResponse).map((catalogCredential) => catalogCredential.name)))
          );
          }
        }

        if (customerId) {
          form.append(customerFilter, customerId);
        }

        if (status) {
          switch (status) {
            case CREDENTIAL_STATUS[0]:
              form.append('status', status);
              form.append('expiresAfter', Math.floor(Date.now() / 1000));
              break;
            case CREDENTIAL_STATUS[1]:
              form.append('status', CREDENTIAL_STATUS[0]);
              form.append('expiredFrom', Math.floor(Date.now() / 1000));
              break;
            default:
              form.append('status', status);
              break;
          }
        }

        const response = await get(endpoint, form);

        if (isOk(response.status)) {
          jwts = getResponseJson(response);
          count = Number(response.headers['x-app-total-count']) || 0;
        }
      }
    }

    const value = await mappingFunc(decodeAllJwts(jwts), false);
    return { page, rowsPerPage, count, value };
  }
);

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearCredentialsCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCredentialsAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchCredentialsAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearCredentialsCache } = credentialsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectCredentials = (state) => state.credentials.value;
export const selectCredentialsState = (state) => state.credentials.status;
export const selectCredentialsPage = (state) => state.credentials.page;
export const selectCredentialsRowsPerPage = (state) => state.credentials.rowsPerPage;
export const selectCredentialsCount = (state) => state.credentials.count;

export default credentialsSlice.reducer;
