// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import PresentationRequestTemplateRow from '../Rows/PresentationRequestTemplateRow.component';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestTemplateTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_PRESENTATION_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUEST_TEMPLATES = [];
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_HIDE_FILE = false;
const DEFAULT_HIDE_ACTIONS = false;

const documentText = 'prt-table-document';
function getHeaders(translate, hideDocument, hideActions) {
  return [
    {
      text: 'prt-table-request-name'
    },
    {
      text: 'prt-table-purpose'
    },
    {
      text: 'prt-table-creation-date'
    },
    {
      text: documentText
    },
    {
      text: 'prt-table-actions'
    }
  ]
    .filter((header) => {
      if (hideDocument && header.text === documentText) {
        return false;
      }

      if (hideActions && header.text === 'prt-table-actions') {
        return false
      }

      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTemplateTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const presentationCount = props.presentationCount || DEFAULT_PRESENTATION_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const presentationRequestTemplates = props.presentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const openPopupInstead = props.openPopupInstead || DEFAULT_OPEN_POPUP_INSTEAD;
  const hideFile = props.hideFile || DEFAULT_HIDE_FILE;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;

  const headers = getHeaders(translate, hideFile, hideActions);

  return (
    <Grid item='true' paddingBottom={paddingBottom}>
      <TableContainer className='presentation-request-template-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('prt-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
              {presentationRequestTemplates.map((presentationRequestTemplate, index) => (
                <PresentationRequestTemplateRow
                  key={index}
                  translate={translate}
                  presentationRequestTemplate={presentationRequestTemplate}
                  openPopupInstead={openPopupInstead}
                  hideFile={hideFile}
                  hideCollapsible={true}
                  hideDelete={true}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    labelRowsPerPage={translate(`rows-per-page`)}
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
