import { decodeJwt, importPKCS8, SignJWT } from 'jose';
import config from '#config';
import { get, getResponseJson, isOk, post } from './axios.helper';
import services from '../services';
const  { CREDENTIAL_STATUS, CREDENTIAL_REQUEST_STATUS, PRESENTATION_STATUS, PRESENTATION_REQUEST_STATUS, PRESENTATION_TYPES } = config.constants;

export const decodeAllJwts = (storedJwts) => {
  return storedJwts
    .map((storedJwt) => {
      try {
        const jwt = storedJwt.signedToken;
        const decoded = decodeJwt(jwt);
        return { storedJwt, decoded };
      } catch (exception) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(exception);
        }
      }

      return undefined;
    })
    .filter((jwt) => jwt !== undefined);
};

export const mapCredentials = async (jwts, personal) => {
  return (
    await Promise.all(
      jwts
        .map((credential) => {
          console.log(credential)
          const decoded = credential.decoded;
          const vc = decoded.vc;
          console.log(vc);
          vc.credentialSubject = JSON.parse(vc.credentialSubject);

          return credential;
        })
        // First stage: Get related catalog entry for the credential.
        .map(async (credential) => {
          credential.decoded.vc = await mapCredentialToCatalogEntry(credential.decoded.vc);
          return credential;
        })
        // Second stage: find the user this was emitted for.
        .map(async (credentialRequest) => {
          const credential = await credentialRequest;

          if (personal) {
            credential.decoded.vc.credentialSubject.id = credential.decoded.iss;
          }

          credential.decoded.vc = await mapCredentialToUser(credential.decoded.vc);
          return credential;
        })
        // Third stage: find the status of the credential.
        .map(async (credentialRequest) => {
          const credential = await credentialRequest;
          credential.decoded.vc = await validateObject(
            credential.decoded.vc,
            credential.storedJwt.signedToken,
            CREDENTIAL_STATUS
          );
          return credential;
        })
    )
  ).filter((credential) => credential !== undefined);
};

export const mapCredentialRequests = async (jwts, personal) => {
  return (
    await Promise.all(
      // First stage: Get related catalog entry for the credential.
      jwts
        .map((credential) => {
          try {
            const decoded = credential.decoded;
            const vcr = decoded.vcr;

            if (!vcr.credentialSubject) {
              vcr.credentialSubject = { id: personal ? decoded.sub : decoded.iss };
            }
          } catch (exception) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(exception);
            }
          }

          return credential;
        })
        // First stage: Get related catalog entry for the credential.
        .map(async (credential) => {
          credential.decoded.vcr = await mapCredentialToCatalogEntry(credential.decoded.vcr);
          return credential;
        })
        // Second stage: find the issuer
        .map(async (credentialRequest) => {
          const credential = await credentialRequest;
          credential.decoded.vcr = await mapCredentialToUser(credential.decoded.vcr);
          return credential;
        })
        // Third stage: find the status of the credential.
        .map(async (credentialRequest) => {
          const credential = await credentialRequest;
          credential.decoded.vcr.status = credential.storedJwt.status;
          return credential;
        })
    )
  ).filter((credential) => credential !== undefined);
};

export const mapPresentationRequests = async (jwts, personal) => {
  let jwtMap = await Promise.all(
    jwts
      .map((presentationRequest) => {
        try {
          // Make sure customers is included
          if (!presentationRequest.decoded.customers) {
            presentationRequest.decoded.customers = presentationRequest.storedJwt.customers || [];
          }
        } catch (exception) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(exception);
          }
        }

        return presentationRequest;
      })
      .map(async (presentationRequest) => {
        presentationRequest.decoded.pr.data = await Promise.all(
          presentationRequest.decoded.pr.data.map(async (credential) => {
            if (!credential) {
              return credential;
            }

            const catalogEntry = await services.orchestrator.getCredentialFromCatalog(credential['@context'] || '');
            if (Object.keys(catalogEntry).length > 0) {
              const newCredential = { ...catalogEntry[0], ...credential };
              if (!newCredential.alias) {
                newCredential.alias = credential.field_name;
              }

              if (Array.isArray(newCredential.type)) {
                newCredential.type = newCredential.type[1];
              }

              console.log(newCredential);

              return newCredential;
            } else {
              return credential;
            }
          })
        );

        return presentationRequest;
      })
      .map(async (presentationRequestPromise) => {
        const presentationRequest = await presentationRequestPromise;
        presentationRequest.decoded.issuerName = await services.orchestrator.getCustomer({
          did: presentationRequest.decoded.iss
        });
        return presentationRequest;
      })
      .map(async (presentationRequestPromise) => {
        const presentationRequest = await presentationRequestPromise;

        if (presentationRequest.storedJwt.status === PRESENTATION_REQUEST_STATUS[0]) {
          const self = presentationRequest.decoded.customers[0];

          if (self) {
            presentationRequest.storedJwt.status = self.customerStatus;
          }
        }

        return presentationRequest;
      })
  );

  if (personal) {
    jwtMap = await Promise.all(
      jwtMap
        .map(async (presentationRequest) => {
          presentationRequest.decoded.pr.data = await Promise.all(
            presentationRequest.decoded.pr.data
              .map(async (credential) => {
                if (!credential) {
                  return credential;
                }

                const storedCredentials = await services.orchestrator.getCorporateNonExpiredCredentials({
                  credentialContext: credential['@context'],
                  levelOfAssurance: credential.levelOfAssurance,
                  status: CREDENTIAL_STATUS[0]
                });
                const credentials = await mapCredentials(decodeAllJwts(storedCredentials));
                credential.emitted = credentials;
                return credential;
              })
              .map(async (credentialRequest) => {
                const credential = await credentialRequest;

                if (!credential) {
                  return credential;
                }

                const credentials = await services.orchestrator.getCorporateCredentialRequests({
                  credentialContext: credential['@context'],
                  status: CREDENTIAL_REQUEST_STATUS[0]
                });
                credential.requests = await mapCredentialRequests(decodeAllJwts(credentials), true);
                return credential;
              })
          );

          return presentationRequest;
        })
        .map(async (presentationRequestPromise) => {
          const presentationRequest = await presentationRequestPromise;

          /*if (presentationRequest.decoded.pr.type.includes(PRESENTATION_TYPES.DELEGATED_TYPE)) {
            const whoAmIResponse = await get('/corporate');
            let presentations = [];

            if (isOk(whoAmIResponse.status)) {
              const whoAmI = getResponseJson(whoAmIResponse);
              const form = new URLSearchParams();
              form.append('issuer', whoAmI.did);
              form.append('procHash', presentationRequest.decoded.pr.procHash);
              const prResponse = await get('/dit/presentationRequest', form);

              if (isOk(prResponse.status)) {
                const linkedPresentationRequest = (
                  await mapPresentationRequests(decodeAllJwts(getResponseJson(prResponse)), personal)
                )[0];

                presentationRequest.decoded.pr.hasCreatedPr = linkedPresentationRequest;

                if (linkedPresentationRequest) {
                  const form = new URLSearchParams();
                  form.append('subject', whoAmI.did);
                  form.append('jtipr', linkedPresentationRequest.storedJwt.jti);

                  const vpResponse = await get('/dit/presentation', form);
                  presentations = isOk(vpResponse.status) ? getResponseJson(vpResponse) : [];
                }
              }
            }

            const decodedPrs = decodeAllJwts(presentations);
            presentationRequest.decoded.pr.presentations = await Promise.all(
              decodedPrs.map(async (presentation) => {
                const form = new URLSearchParams();
                form.append('did', presentation.decoded.iss);
                const entityResult = await get(`/customer`, form);

                if (isOk(entityResult.status)) {
                  presentation.decoded.issuer = getResponseJson(entityResult)[0];
                }

                return presentation;
              })
            );
          }*/

          return presentationRequest;
        })
    );
  }

  return jwtMap.filter((presentationRequest) => presentationRequest !== undefined);
};

export const mapPresentations = async (jwts) => {
  return (
    await Promise.all(
      jwts
       /*.map(async (presentation) => {
          presentation.decoded.vp = await validateObject(
            presentation.decoded.vp,
            presentation.storedJwt.signedToken,
            PRESENTATION_STATUS
          );
          return presentation;
        })*/
        .map(async (presentationPromise) => {
          const presentation = await presentationPromise;

          try {
            console.log(presentation.decoded.vp.verifiableCredential);
            const decodedCredentials = await mapCredentials(
              decodeAllJwts(
                presentation.decoded.vp.verifiableCredential.map((jwt) => {
                  return { signedToken: jwt };
                })
              ),
              true
            );
            console.log(decodedCredentials);
            presentation.decoded.vp.verifiableCredential = decodedCredentials;
          } catch (exception) {
            try {
              console.log(exception)
              const decodedPresentations = await mapPresentations(
                decodeAllJwts(
                  presentation.decoded.vp.verifiableCredential.map((jwt) => {
                    return { signedToken: jwt };
                  })
                ),
                true
              );
              presentation.decoded.vp.verifiableCredential = decodedPresentations;
            } catch (exception) {
            presentation.decoded.vp.verifiableCredential = [];
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(exception);
            }
          }
          }

          return presentation;
        })
        .map(async (presentationPromise) => {
          const presentation = await presentationPromise;

          try {
            const presentationRequestJti = presentation.decoded.jtipr;

            const result = await get(`/dit/presentationRequest/${presentationRequestJti}`);

            if (isOk(result.status)) {
              const presentationRequest = decodeAllJwts([getResponseJson(result)])[0];

              presentation.decoded.vp.procId = presentationRequest.decoded.pr.procId;
              presentation.decoded.vp.procDesc = presentationRequest.decoded.pr.procDesc;
              presentation.decoded.vp.procUrl = presentationRequest.decoded.pr.procUrl;
              presentation.decoded.vp.procHash = presentationRequest.decoded.pr.procHash;
              presentation.decoded.vp.type = [...presentation.decoded.vp.type, ...presentationRequest.decoded.pr.type];

              if (presentation.decoded.vp.verifiableCredential.length === 0) {
                presentation.decoded.vp.verifiableCredentialIsCatalog = true;
                presentation.decoded.vp.verifiableCredential = await Promise.all(
                  presentationRequest.decoded.pr.data.map(async (credential) => {
                    const catalogResponse = await get(credential['@context'], undefined, true);

                    let catalogEntry = { catalog: { alias: credential.field_name } };
                    if (isOk(catalogResponse.status)) {
                      catalogEntry = getResponseJson(catalogResponse)[0];
                    }

                    const newCredential = { ...catalogEntry, ...credential };
                    if (!newCredential.alias) {
                      newCredential.alias = credential.field_name;
                    }

                    if (Array.isArray(newCredential.type)) {
                      newCredential.type = newCredential.type[1];
                    }

                    return newCredential;
                  })
                );
              }

              const form = new URLSearchParams();
              form.append('did', presentationRequest.decoded.iss);
              const entityResult = await get(`/customer`, form);

              if (isOk(entityResult.status)) {
                presentation.decoded.issuerName = getResponseJson(entityResult)[0];
              }
            } else {
              presentation.decoded.vp.procId = 'N/A';
              presentation.decoded.vp.procDesc = 'N/A';
            }
          } catch (exception) {
            presentation.decoded.vp.procId = 'N/A';
            presentation.decoded.vp.procDesc = 'N/A';
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              console.log(exception);
            }
          }

          return presentation;
        })
    )
  ).filter((presentation) => presentation !== undefined);
};

/// ----- PRIVATE FUNCTIONS ----- ///

async function mapCredentialToCatalogEntry(credential) {
  let catalog = config.mockedData.catalog;
  let possibleValidCatalogEntries = catalog;
  try {
    if (!config.mocked) {
      catalog = [];
      possibleValidCatalogEntries = [];
      let credentialContext = credential['@context'];
      if (Array.isArray(credentialContext)) {
        credentialContext = credentialContext[1] || '';
      }
      const response = await get(credentialContext, undefined, true);

      if (isOk(response.status)) {
        possibleValidCatalogEntries = getResponseJson(response);
      }
    } else {
      possibleValidCatalogEntries = catalog.filter((catalogEntry) => catalogEntry.catalogId === credential.type[1]);
    }

    if (possibleValidCatalogEntries.length > 0) {
      credential.catalog = possibleValidCatalogEntries[0];
    } else {
      credential.catalog = { alias: credential.type[1] };
    }
  } catch (exception) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(exception);
    }
  }

  return credential;
}

async function mapCredentialToUser(credential) {
  try {
    let users = config.mockedData.customers;
    if (!config.mocked) {
      users = [];
      var urlForm = new URLSearchParams();
      urlForm.append('did', credential.credentialSubject.id);
      const response = await get('/customer', urlForm);

      if (isOk(response.status)) {
        users = getResponseJson(response);
      }
    }

    if (users.length > 0) {
      credential.credentialSubject.name = users[0].businessName;
    }
  } catch (exception) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(exception);
    }
  }

  return credential;
}

async function validateObject(object, jwt, statusArray) {
  const response = await post('/dit/validate', { jwt });

  if (isOk(response.status)) {
    let status = getResponseJson(response);
    const blockchainIssuerStatus = statusArray.indexOf(status.blockchainStatus.blockchainIssuerStatus[1]);
    const blockchainSubjectStatus = statusArray.indexOf(status.blockchainStatus.blockchainSubjectStatus[1]);
    const databaseStatus = statusArray.indexOf(status.databaseStatus);
    const iomStatus = statusArray.indexOf(status.iomStatus);

    object.status =
      statusArray[
      Math.max(Math.max(Math.max(blockchainIssuerStatus, blockchainSubjectStatus), iomStatus), databaseStatus)
      ];
  }

  return object;
}

// This is only used during MOCK tests. It is not used in live.
const rsaPrivateKey = process.env.REACT_APP_MOCK_KEY;
const header = {
  alg: 'RS256',
  jwk: {
    kty: 'RSA',
    n: 'p-op3uYWxSCAyn5Swr3PbDPwQN0aX1P5URSOyslXTl9KW4ZgRXGVCgnQic7rO2D8td5mJY0oBnxEYTlztqKt7mw4yCYKPlEuXImMLQ1wbN1H5zj8Z8k_AsJKW9Ay2KA4iYMgs2bqYg7k-PwzY-1K5efjllahSjHy5IqZIFmFM9UKUTWKPM43r8ZicQlyOW9zcPyk-QUp4-0KmL1MKVvU1h6CPO_5r4pLgbYYeBaBd9-FTK8FaG4g9q5YFbDWGHZkdDMWVJ2aqNGkOGTVwppa92nKyZAvrQXm5gbWhrhg0tzudeuy_2eaErHDQMchTDeFFudBUdF9v5k5ZSpam6wYPJLyAa8QjH5F8S4ihMR3glV7CiY3a1G4mile390udGa6jA2e1Pti6DOFbiWRakcpt6hwVBIv_3ouiZAB1PDW11rGaie_A9qrbkNdu9zg6YSEnYQ21UW3W-zIq9NdSX0gF5yWKy1xQQCXc_HNnh_-d_S9elIC6fTW5wKj3emWy7_v-C3WMi85qlsXzHtu1GW6QMGCvF-n8ek03c7hNWGYzU-E66GnsTH_UScf439jBP_Igu7mAesa3HIB3Eve-qQMGyMRInPfk2gQG3vA485tUWn3zchdpTO44bmTLa7cZrT7sro1by1sg_Sc4LHEm3IGPa0tJCC2E4KU_CDP-mgIG5E',
    e: 'AQAB'
  },
  kid: undefined
};

export const sign = async (_payload) => {
  const payload = {
    ..._payload,
    iat: Date.now(),
    status: CREDENTIAL_STATUS[0]
  };

  const privateKey = await importPKCS8(rsaPrivateKey, 'RS256');
  header.kid = payload.kid;
  return new SignJWT(payload).setProtectedHeader(header).sign(privateKey);
};
