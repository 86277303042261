import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid2';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[2]
    }
  })
);

export default function LOATooltip(props) {
  const translate = props.translate;

  return (
    <LightTooltip
      arrow
      title={
        <Grid container direction='column' sx={{ backgroundColor: 'white' }}>
          <Grid item='true'>
            <Typography color='textPrimary' fontWeight='bold' fontSize='0.938rem'>
              {translate('loa-tooltip-title')}
            </Typography>
          </Grid>
          <Grid item='true'>
            <Typography color='textSecondary' fontSize='0.938rem'>
              {translate('loa-tooltip-description')}
            </Typography>
          </Grid>
        </Grid>
      }>
      <InfoOutlined sx={{ width: '18px', height: '18px' }} />
    </LightTooltip>
  );
}
