import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CalendarIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 30, height: 30 }}>
      <path
        d='M18.8401 22.7884C18.1198 22.7884 17.508 22.5339 17.0048 22.0251C16.5016 21.5163 16.25 20.9017 16.25 20.1814C16.25 19.461 16.5044 18.8493 17.0133 18.3461C17.5221 17.8428 18.1366 17.5912 18.857 17.5912C19.5773 17.5912 20.1891 17.8457 20.6923 18.3545C21.1955 18.8633 21.4471 19.4779 21.4471 20.1982C21.4471 20.9186 21.1927 21.5303 20.6839 22.0336C20.175 22.5368 19.5605 22.7884 18.8401 22.7884ZM6.73075 26.2499C6.2504 26.2499 5.84184 26.0815 5.50509 25.7448C5.16836 25.4081 5 24.9995 5 24.5191V7.69221C5 7.21183 5.16836 6.80327 5.50509 6.46652C5.84184 6.12979 6.2504 5.96143 6.73075 5.96143H9.31491V3.46143H10.4327V5.96143H19.6635V3.46143H20.7092V5.96143H23.2693C23.7496 5.96143 24.1582 6.12979 24.4949 6.46652C24.8316 6.80327 25 7.21183 25 7.69221V24.5191C25 24.9995 24.8316 25.4081 24.4949 25.7448C24.1582 26.0815 23.7496 26.2499 23.2693 26.2499H6.73075ZM6.73075 25.2884H23.2693C23.4615 25.2884 23.6378 25.2082 23.7981 25.048C23.9583 24.8877 24.0385 24.7114 24.0385 24.5191V13.1249H5.96153V24.5191C5.96153 24.7114 6.04166 24.8877 6.20191 25.048C6.36218 25.2082 6.53846 25.2884 6.73075 25.2884ZM5.96153 12.1633H24.0385V7.69221C24.0385 7.49989 23.9583 7.32361 23.7981 7.16336C23.6378 7.00309 23.4615 6.92296 23.2693 6.92296H6.73075C6.53846 6.92296 6.36218 7.00309 6.20191 7.16336C6.04166 7.32361 5.96153 7.49989 5.96153 7.69221V12.1633Z'
        fill='#1E1E56'
      />
    </SvgIcon>
  );
}
