// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import ContactRow from '../Rows/ContactRow.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './ContactsTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_CONTACTS = [];
const DEFAULT_CONTACT_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_TELEPHONE = false;
const DEFAULT_HIDE_CIF = false;
const DEFAULT_TRANSLATE_PREFIX = 'contacts-unknown-table';

const telepohneText = 'telephone-number';
const cifText = 'cif';

function getHeaders(hideTelephone, hideCif, translate, translatePrefix) {
  return [
    { text: 'social-denomination' },
    { text: 'alias' },
    { text: 'email' },
    { text: telepohneText },
    { text: cifText },
    { text: 'did' },
    { text: 'status' }
  ]
    .filter((header) => {
      if (hideTelephone && header.text === telepohneText) {
        return false;
      }
      if (hideCif && header.text === cifText) {
        return false;
      }
      return true;
    })
    .map((header) => {
      header.text = translate(`${translatePrefix}-${header.text}`);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function ContactsTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const contacts = props.contacts || DEFAULT_CONTACTS;
  const contactCount = props.contactCount || DEFAULT_CONTACT_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideTelephone = props.hideTelephone || DEFAULT_HIDE_TELEPHONE;
  const hideCif = props.hideCif || DEFAULT_HIDE_CIF;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;

  const headers = getHeaders(hideTelephone, hideCif, translate, translatePrefix);

  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  return (
    <Grid item='true' paddingBottom={4}>
      <TableContainer className='contacts-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate(`${translatePrefix}-loading`)}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {contacts.map((contact) => (
                <ContactRow
                  key={contact.customerId}
                  translate={translate}
                  contact={contact}
                  hideTelephone={hideTelephone}
                  hideCif={hideCif}
                  hideCollapsible={true}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    labelRowsPerPage={translate(`rows-per-page`)}
                    component='div'
                    count={contactCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
