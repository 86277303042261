// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

// ------ FILE MODULES ---------------------------------------------------------
import { clearPresentationsCache, fetchPresentationsAsync, selectPresentations, selectPresentationsCount, selectPresentationsPage, selectPresentationsRowsPerPage, selectPresentationsState } from '#reducers/adminPresentations';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';

import PresentationRequestTemplateTable from '#privateComponents/Tables/PresentationRequestTemplateTable.component';
import SendPresentationRequestPopup from '#privateComponents/Popups/SendPresentationRequestPopup.component';
import PresentationRequestTable from '#privateComponents/Tables/PresentationRequestTable.component';
import PRTCredentialTable from '#privateComponents/Tables/PRTCredentialTable.component';

import VisualizerLayout from '#layouts/Visualizer/Visualizer.layout';

import services from '../../../../../services';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function getPrtData(prtId, setPrt, setIsPrtLoading) {
  setIsPrtLoading(true);
  const prt = await services.orchestrator.getPresentationRequestTemplate(prtId);

  prt.data = JSON.parse(prt.data);

  if (prt.data.credentials) {
    prt.data.credentials = await Promise.all(
      prt.data.credentials.map(async (credential) => {
        const catalogEntry = await services.orchestrator.getCredentialFromCatalog(credential.credentialContext);
        return { ...credential, ...(catalogEntry[0]) };
      })
    );
  }

  prt.data = JSON.stringify(prt.data);

  setPrt(prt);
  setIsPrtLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PRTVisualizer(props) {
  const translate = props.translator;

  const dispatch = useDispatch();

  const pathParams = useParams();
  const prtId = pathParams.prtId;

  const [isOpen, setIsOpen] = useState(false);
  const [prt, setPrt] = useState({ data: '{}' });
  const [isPrtLoading, setIsPrtLoading] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_COUNT[0]);
  const [presentationRowsPerPage, setPresentationRowsPerPage] = useState(useSelector(selectPresentationsRowsPerPage));
  const [presentationPage, setPresentationPage] = useState(useSelector(selectPresentationsPage));

  const presentationRequests = useSelector(selectPresentations);
  const presentationCount = useSelector(selectPresentationsCount);
  const presentationState = useSelector(selectPresentationsState);

  const presentationLoading = presentationState === REDUCER_STATUS.LOADING;
  const presentationNeedsReload = presentationState === REDUCER_STATUS.NEEDS_REFRESH;

  if (presentationNeedsReload) {
    dispatch(fetchPresentationsAsync({page, rowsPerPage, prtId}));
  }

  if (isPrtLoading === undefined) {
    getPrtData(prtId, setPrt, setIsPrtLoading);
  }

  const credentials = JSON.parse(prt.data).credentials;
  let credentialsPaginated = [];
  let credentialCount = 0;

  if (credentials) {
    credentialCount = credentials.length;
    credentialsPaginated = [...credentials].splice(page * rowsPerPage, rowsPerPage);
  }

  const handleSetPage = (newPage) => {
    setPresentationPage(newPage);
    dispatch(clearPresentationsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setPresentationRowsPerPage(newRowsPerPage);
    dispatch(clearPresentationsCache());
  };

  return (
    <VisualizerLayout
      pageTitle={translate('prt-details-page-title')}
      primaryTableComponent={PresentationRequestTemplateTable}
      primaryTableProps={{
        translate: translate,
        isLoading: isPrtLoading,
        hidePagination: true,
        presentationRequestTemplates: [prt],
        openPopupInstead: true
      }}
      secondaryTableTitle={translate('prt-details-page-credential-set-table-title')}
      secondaryTableComponent={PRTCredentialTable}
      secondaryTableProps={{
        translate: translate,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        catalog: credentialsPaginated,
        catalogCount: credentialCount
      }}
      tertiaryTableTitle={translate('prt-details-page-presentation-request-table-title')}
      tertiaryTableComponent={PresentationRequestTable}
      tertiaryTableProps={{
        translate: translate,
        isLoading: presentationLoading,
        hideIssuer: true,
        hideName: true,
        hideSignButton: true,
        disableOnClick: true,
        presentationRequests: presentationRequests,
        presentationCount: presentationCount,
        rowsPerPage: presentationRowsPerPage,
        page: presentationPage,
        setPage: handleSetPage,
        setRowsPerPage: handleSetRowsPerPage
      }}
      buttons={[
        {
          text: translate('prt-details-page-send-request-button'),
          onClick: () => {
            setIsOpen(true);
          }
        }
      ]}>
      <SendPresentationRequestPopup
        translate={translate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formSent={() => {
          dispatch(clearPresentationsCache());
        }}
        presentationRequestTemplate={prt}
      />
    </VisualizerLayout>
  );
}
