// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import CatalogCredentialRow from '../Rows/CatalogCredentialRow.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './CatalogCredentialTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CATALOG = [];
const DEFAULT_IS_LOADING = false;
const DEFAULT_CATALOG_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[1];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_ON_CREDENTIAL_SELECTION_CHANGED = () => {};
const DEFAULT_SELECTED_CREDENTIALS = {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_CHECKBOXES = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_DISABLE_CHECKBOXES = false;
const DEFAULT_MAX_NAME_LENGTH = 100;
const DEFAULT_MAX_USE_CASE_LENGTH = 100;

const checkboxText = 'catalog-credential-table-checkboxes';
const loaText = 'catalog-credential-table-loa';
const actionsText = 'catalog-credential-table-actions';

function getHeaders(hideActions, hideCheckboxes, translate) {
  return [
    { text: checkboxText },
    { text: loaText },
    { text: 'catalog-credential-table-credential-name' },
    { text: 'catalog-credential-table-official-issuer' },
    { text: 'catalog-credential-table-holder' },
    { text: 'catalog-credential-table-format' },
    { text: 'catalog-credential-table-use-case' },
    { text: 'catalog-credential-table-use-case-sub' },
    { text: actionsText }
  ]
    .filter((header) => {
      if (hideActions && header.text === actionsText) {
        return false;
      }
      if (hideCheckboxes && (header.text === checkboxText || header.text === loaText)) {
        return false;
      }
      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogCredentialTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const catalog = props.catalog || DEFAULT_CATALOG;
  const catalogCount = props.catalogCount || DEFAULT_CATALOG_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const onCredentialSelectionChanged = props.onCredentialSelectionChanged || DEFAULT_ON_CREDENTIAL_SELECTION_CHANGED;
  const selectedCredentials = props.selectedCredentials || DEFAULT_SELECTED_CREDENTIALS;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideCheckboxes = props.hideCheckboxes || DEFAULT_HIDE_CHECKBOXES;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const disableCheckboxes = props.disableCheckboxes || DEFAULT_DISABLE_CHECKBOXES;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxUseCaseLength = props.maxUseCaseLength || DEFAULT_MAX_USE_CASE_LENGTH;

  const headers = getHeaders(hideActions, hideCheckboxes, translate);

  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  return (
    <Grid item='true' paddingBottom={4}>
      <TableContainer className='catalog-credential-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('catalog-credential-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {catalog.map((catalogEntry) => {
                return (
                  <CatalogCredentialRow
                    key={catalogEntry.name}
                    translate={translate}
                    catalogEntry={catalogEntry}
                    onCredentialSelectionChanged={onCredentialSelectionChanged}
                    selectedCredentials={selectedCredentials}
                    disableCheckboxes={disableCheckboxes}
                    hideActions={hideActions}
                    maxNameLength={maxNameLength}
                    hideCheckboxes={hideCheckboxes}
                    maxUseCaseLength={maxUseCaseLength}
                  />
                );
              })}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    labelRowsPerPage={translate(`rows-per-page`)}
                    component='div'
                    count={catalogCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
