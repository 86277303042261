import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MyCredentialsIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M16 19.8128L12.5577 16.3705L13.1134 15.8147L16 18.7012L21.8404 12.8608L22.3961 13.4166L16 19.8128ZM20.5 10.4051H19.7308V5.94159C19.7308 5.78776 19.6667 5.64673 19.5385 5.51851C19.4103 5.39031 19.2692 5.32621 19.1154 5.32621H16.9615V7.21084H8.03845V5.32621H5.8846C5.73077 5.32621 5.58974 5.39031 5.46152 5.51851C5.33332 5.64673 5.26922 5.78776 5.26922 5.94159V19.1724C5.26922 19.3262 5.33332 19.4672 5.46152 19.5955C5.58974 19.7237 5.73077 19.7878 5.8846 19.7878H11.3904V20.557H5.8846C5.48973 20.557 5.16025 20.4249 4.89615 20.1608C4.63205 19.8967 4.5 19.5673 4.5 19.1724V5.94159C4.5 5.54672 4.63205 5.21724 4.89615 4.95314C5.16025 4.68904 5.48973 4.55699 5.8846 4.55699H10.9346C10.9872 4.16595 11.1593 3.83743 11.4509 3.57141C11.7426 3.30538 12.0923 3.17236 12.5 3.17236C12.914 3.17236 13.2653 3.30538 13.5538 3.57141C13.8423 3.83743 14.0128 4.16595 14.0654 4.55699H19.1154C19.5103 4.55699 19.8397 4.68904 20.1038 4.95314C20.3679 5.21724 20.5 5.54672 20.5 5.94159V10.4051ZM12.5 5.55699C12.732 5.55699 12.9247 5.48039 13.0779 5.32719C13.2311 5.17397 13.3077 4.98134 13.3077 4.74929C13.3077 4.51724 13.2311 4.32461 13.0779 4.17141C12.9247 4.0182 12.732 3.94159 12.5 3.94159C12.2679 3.94159 12.0753 4.0182 11.9221 4.17141C11.7689 4.32461 11.6923 4.51724 11.6923 4.74929C11.6923 4.98134 11.7689 5.17397 11.9221 5.32719C12.0753 5.48039 12.2679 5.55699 12.5 5.55699Z'
        fill='#F0F3FF'
      />
    </SvgIcon>
  );
}
