import { useLinkClickHandler } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import Menu from '@mui/material/Menu';

import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import Fingerprint from '@mui/icons-material/Fingerprint';

import config from '#config';
import Hider from '../../shared/Hider/Hider.component';
import { clearPresentationRequestTemplatesCache } from '../../../store/reducers/presentationRequestTemplates';
import { clearPresentationsCache } from '../../../store/reducers/adminPresentations';
import { setError } from '../../../store/reducers/error';

import services from '../../../services';

const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ANCHOR_ELEMENT = undefined;
const DEFAULT_SET_ANCHOR_ELEMENT = () => {};
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_PRTID= '';
const DEFAULT_PRESENTATION_REQUEST_TEMPLATE = {};
export default function PRTButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const anchorElement = props.anchorElement || DEFAULT_ANCHOR_ELEMENT;
  const setAnchorElement = props.setAnchorElement || DEFAULT_SET_ANCHOR_ELEMENT;
  const openPopupInstead = props.openPopupInstead || DEFAULT_OPEN_POPUP_INSTEAD;
  const prtId = props.jti || DEFAULT_PRTID;
  const presentationRequestTemplate = props.presentationRequestTemplate || DEFAULT_PRESENTATION_REQUEST_TEMPLATE;
  const [actionInProgress, setActionInProgress] = useState(false);

  const dispatch = useDispatch();

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (actionInProgress) return;
    setAnchorElement(null);
  };

  const openPRTDetails = useLinkClickHandler(`${config.constants.PAGE_ROUTES.AdminPresentationRequestTemplates}/${prtId}`);
  const deletePRTHandler = async () => {
    setActionInProgress(true);
    const success = await services.orchestrator.deletePresentationRequestTemplate(
      presentationRequestTemplate.prtId,
      { ...presentationRequestTemplate },
      setErrorPopup
    );
    setActionInProgress(false);

    if (success) dispatch(clearPresentationRequestTemplatesCache());
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          minWidth: '15%'
        }
      }}>
      <Hider isHidden={/*openPopupInstead*/true}>
        <MenuItem onClick={(e) => {
            dispatch(clearPresentationsCache());
            return openPRTDetails(e);
          }}>
          <Grid container spacing={1}>
            <Grid item='true'>
              <Fingerprint sx={{ color: '#662D91' }} />
            </Grid>
            <Grid item='true'>
              <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
                {translate('prt-action-send')}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Hider>
      <MenuItem onClick={deletePRTHandler}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <VerifiedUserOutlined sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('prt-action-end')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
    </Menu>
  );
}
