import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import config from '#config';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';
import mockedData from '../../config/mockedData.config';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCustomersAsync = createAsyncThunk(
  'customer/fetchCustomers',
  async ({ comms, status, name, did, mail, search, page, limit }) => {
    const finalLimit = limit === undefined ? initialState.rowsPerPage : limit;
    let users = [];
    let count = users.length;

    if (config.INTERNAL.MOCKED) {
      users = mockedData.customers.filter(
        (user) => (!comms || user.comms === comms) && (!status || user.status === status)
      );
    } else {
      users = [];
      count = 0;

      var urlForm = new URLSearchParams();

      if (finalLimit) {
        if (page) {
          urlForm.append('offset', finalLimit * page);
        }
        urlForm.append('limit', finalLimit);
      }

      if (comms) {
        urlForm.append('comms', comms);
      }

      if (status) {
        urlForm.append('status', status);
      }

      if (name) {
        urlForm.append('businessName', name);
      }

      if (did) {
        urlForm.append('did', did);
      }

      if (mail) {
        urlForm.append('email', mail);
      }

      if (search) {
        urlForm.append('search', search);
      }

      const response = await get('/customer', urlForm);

      if (isOk(response.status)) {
        users = getResponseJson(response);
        count = Number(response.headers['x-app-total-count']) || 0;
      }
    }

    return { value: users, page, rowsPerPage: limit, count };
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearCustomerCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchCustomersAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearCustomerCache } = customerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectCustomers = (state) => state.customer.value;
export const selectCustomersState = (state) => state.customer.status;
export const selectCustomersPage = (state) => state.customer.page;
export const selectCustomersRowsPerPage = (state) => state.customer.rowsPerPage;
export const selectCustomersCount = (state) => state.customer.count;

export default customerSlice.reducer;
