import './Slide.component.css';

// PRIVATE

const DEFAULT = {
  name: 'unknown'
}

// PUBLIC

export default function Slide(props) {
  let title = (<div />)
  let titleData = null
  let content = (<div />)
  let contentData = null

  const translator = props.translator;
  const changeLanguage = props.changeLanguage;
  const name = props.name || DEFAULT.name;
  const layoutSize = props.layoutSize;

  if (props.title) {
    titleData = props.title(translator)
    title = (
      <div className='slide-title'>
        {titleData}
      </div>
    )
  }

  if (props.content) {
    contentData = props.content(translator, changeLanguage)
    content = (
      <div className='slide-infotainer'>
        {contentData}
      </div>
    )
  }

  let innerContainer = (
    <div className='slide-inner-container'>
      {title}
      {content}
    </div>
  )

  if (props.customBackground) {
    innerContainer = (
      <div className={`fit-${layoutSize==='lOrXl' ? 'l' : layoutSize==='m' ? 'm' : 's'}`}>
        {props.customBackground()}
        {titleData}
        {contentData}
      </div>
    )
  }

  return (
    <div className={`slide-${name}-container slide-custom-background`} key={name}>
      {innerContainer}
    </div>
  )
}