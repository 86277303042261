import React from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2';
import Slide from '../../../../components/shared/Slides/Slide.component'
import participantConfig from './participants.config'
import './Participants.slide.css'

function title(translate) {
  return (
    <Typography variant="h3">
      {translate('participants-title')}
    </Typography>
  )
}

function content(translate) {
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item='true'>
        <Grid container direction="column" spacing={3}>
          <Grid item='true'>
            <Typography variant="h4">
              {translate(participantConfig.partnersContainer.title)}
            </Typography>
          </Grid>
          {participantConfig.partnersContainer.participantSvgs.map((participant, index) => (
            <Grid item='true' key={index}>
              {participant.svg}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item='true'>
        <Grid container direction="column" spacing={6}>
          {participantConfig.integObserverAndEmisorContainers.map((container, index) => (
            <Grid item='true' key={index}>
              <Grid container direction="column" spacing={3}>
                <Grid item='true'>
                  <Typography variant="h4">
                    {translate(container.title)}
                  </Typography>
                </Grid>
                {container.participantSvgs.map((participant, index) => (
                  <Grid item='true' key={index}>
                    {participant.svg}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item='true'>
        <Grid container direction="column" spacing={3}>
          <Grid item='true'>
            <Typography variant="h4">
              {translate(participantConfig.strategicPartnerContainer.title)}
            </Typography>
          </Grid>
          {participantConfig.strategicPartnerContainer.participantSvgs.map((participant, index) => (
            <Grid item='true' key={index}>
              {participant.svg}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

const participants = { name: "participants", title, content }
export default function Participants(props) {
  participants.translator = props.translator;

  return (
    <Slide {...participants} />
  );
}