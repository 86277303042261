// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../store/reducers/customers';
import { fetchCorporateAsync, selectWhoAmI, selectWhoAmIStatus } from '../../../store/reducers/corporate';

import { setError } from '../../../store/reducers/error';

import {
  CUSTOMER_STATUS,
  NETWORK_IDS,
  PRESENTATION_REQUEST_CUSTOMER_STATUS,
  PRESENTATION_TYPES,
  REDUCER_STATUS,
  TECHNOLOGIES,
  TRUST_FRAMEWORKS
} from '#constants';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import DatePicker from '../../shared/DatePicker/DatePicker.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

import services from '../../../services';
import { v4 as uuidv4 } from 'uuid';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DelegatePresentationRequestPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const formSent = props.formSent;
  const presentationRequest = props.presentationRequest;

  const whoAmI = useSelector(selectWhoAmI);
  const whoAmIStatus = useSelector(selectWhoAmIStatus);
  const users = useSelector(selectCustomers);
  const usersState = useSelector(selectCustomersState);

  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(undefined);

  const whoAmINeedsRefresh = whoAmIStatus === REDUCER_STATUS.NEEDS_REFRESH;
  const whoAmIIsReady = whoAmIStatus === REDUCER_STATUS.READY;
  const isLoadingUsers = usersState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = usersState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setSelectedUsers(undefined);
    setIsOpen(false);
  };

  const isFormValid = () => {
    return selectedUsers;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    setFormSendState(true);
    const trustFramework = TRUST_FRAMEWORKS[0];
    const networkTechnology = sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1];
    const networkId = sessionStorage.getItem('networkId') || NETWORK_IDS[2];

    console.log(presentationRequest);

    const prForm = [{
        trustFramework: trustFramework,
        networkTechnology: networkTechnology,
        networkId: networkId,
        kid: 'default',
        cdi: presentationRequest.decoded.cdi,
        cdiName: presentationRequest.decoded.cdiName,
        validFrom: presentationRequest.decoded.nbf,
        validUntil: presentationRequest.decoded.exp,
        prtId: presentationRequest.decoded.prtId,
        presentationRequestContext: 'https://www.w3.org/2018/credentials/examples/v1',
        presentationRequestType: PRESENTATION_TYPES.DELEGATED_TYPE,
        procHash: presentationRequest.decoded.pr.procHash,
        procId: presentationRequest.decoded.pr.procId,
        procDesc: presentationRequest.decoded.pr.procDesc,
        procUrl: presentationRequest.decoded.pr.procUrl,
        credentials: presentationRequest.decoded.pr.data.map((credential) => {
          return {
            trustFramework: trustFramework,
            networkTechnology: networkTechnology,
            networkId: networkId,
            kid: 'default',
            validFrom: presentationRequest.decoded.validFrom,
            validUntil: presentationRequest.decoded.validUntil,
            credentialContext: credential.context,
            credentialType: credential.type,
            levelOfAssurance: credential.levelOfAssurance,
            file: credential.file
          };
        })
      }];
    console.log(prForm);
    let prs = await services.orchestrator.createPresentationRequest(prForm, setErrorPopup);
    console.log(prs);

    if (!prs) {
      setFormSendState(false);
      handleClose();
      return;
    }

    await Promise.all(prs.map(async (pr) => {
      const pruForm = {
        ...pr,
        customers: selectedUsers.map((user) => {
          return { customer: user, customerStatus: PRESENTATION_REQUEST_CUSTOMER_STATUS[0] };
        })
      };
      console.log(pr);
      await services.orchestrator.assignPresentationRequest(pr.jti, pruForm, setErrorPopup);
    }));

    setFormSendState(false);
    formSent();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('presentation-request-send-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('presentation-request-send-popup-sending')} isLoading={formSendState}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('presentation-request-send-popup-contacts')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('presentation-request-send-popup-contacts-placeholder')}
                  value={selectedUsers}
                  required
                  multivalue
                  isLoading={isLoadingUsers}
                  options={users.filter((user) => !whoAmIIsReady || user.did !== whoAmI.did)}
                  getOptionLabel={(user) => user.businessName}
                  onChange={(user) => {
                    setSelectedUsers(user);
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-request-send-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-request-send-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
