import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import config from '#config';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';
import mockedData from '../../config/mockedData.config';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCatalogAsync = createAsyncThunk(
  'catalog/fetchCatalog',
  async ({ page, limit, search, holder, useCase, issuer, jurisdictions }) => {
    const finalLimit = limit === undefined ? initialState.rowsPerPage : limit;

    if (config.INTERNAL.MOCKED) {
      return { page, count: mockedData.catalog.length, value: mockedData.catalog };
    }

    const form = new URLSearchParams();

    if (finalLimit) {
      if (page) {
        form.append('offset', finalLimit * page);
      }
      form.append('limit', finalLimit);
    }

    if (search) {
      form.append('search', search);
    }

    if (holder) {
      form.append('holder', holder);
    }

    if (useCase) {
      form.append('useCase', useCase);
    }

    if (issuer) {
      form.append('issuer', issuer);
    }

    if (Array.isArray(jurisdictions) && jurisdictions.length > 0) {
      form.append('jurisdictions', JSON.stringify(jurisdictions));
    }

    const response = await get('/catalog', form);

    let catalog = [];
    let count = 0;

    if (isOk(response.status)) {
      catalog = getResponseJson(response);
      count = Number(response.headers['x-app-total-count']) || 0;
    }

    return { page, count, rowsPerPage: limit, value: catalog };
  }
);

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    clearCatalogCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchCatalogAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearCatalogCache } = catalogSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectCatalog = (state) => state.catalog.value;
export const selectCatalogState = (state) => state.catalog.status;
export const selectCatalogPage = (state) => state.catalog.page;
export const selectCatalogRowsPerPage = (state) => state.catalog.rowsPerPage;
export const selectCatalogCount = (state) => state.catalog.count;

export default catalogSlice.reducer;
