// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import DigitalSignatureFormRow from '../Rows/PresentationRequestTemplateRow.component';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestTemplateTable.component';
import { DigitalSignatureForm } from '../../../config/routes.config';
import presentationRequestTemplatesSlice from './../../../store/reducers/presentationRequestTemplates';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_OPEN_POPUP_INSTEAD = false;

const documentText = 'digital-signature-forms-table-document';
function getHeaders(translate) {
  return [
    {
      text: 'digital-signature-forms-table-name'
    },
    {
      text: 'digital-signature-forms-table-purpose'
    },
    {
      text: 'digital-signature-forms-table-creation-date'
    },
    {
      text: 'digital-signature-forms-table-actions'
    }
  ]
    .filter((header) => {
    
      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DigitalSignatureFormTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  //const presentationCount = props.presentationCount || DEFAULT_PRESENTATION_COUNT;
  //const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  //const page = props.page || DEFAULT_PAGE;
  //const setPage = props.setPage || DEFAULT_SET_PAGE;
  //const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const forms = [{
    data: JSON.stringify({
      title: 'Acceso Instalaciones',
      description: 'Aceptación de los...'
    }),
    createdAt: 1764028800000
  }];
  console.log(forms)
  const openPopupInstead = props.openPopupInstead || DEFAULT_OPEN_POPUP_INSTEAD;

  const headers = getHeaders(translate);

  return (
    <Grid item='true' paddingBottom={paddingBottom}>
      <TableContainer >
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('digital-signature-forms-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
              {forms.map((form, index) => (
                <DigitalSignatureFormRow
                  key={index}
                  translate={translate}
                  presentationRequestTemplate={form}
                  openPopupInstead={openPopupInstead}
                  hideCollapsible={true}
                  hideDelete={true}
                  hideFile={true}
                />
              ))}
            </SpinnerLoader>
      
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
