import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChevronDownIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 14, height: 8 }}>
      <path
        d='M6.99962 7.30419L0.878784 1.15628L1.74545 0.289612L6.99962 5.54378L12.2538 0.289612L13.1204 1.18336L6.99962 7.30419Z'
        fill='#662D91'
      />
    </SvgIcon>
  );
}
