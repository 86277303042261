// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { fetchCatalogAsync, selectCatalogState, selectCatalog } from '../../../store/reducers/catalog';
import { clearEntityCredentialRequestsCache } from '../../../store/reducers/entityCredentialRequests';
import { setError } from '../../../store/reducers/error';

import { NETWORK_IDS, REDUCER_STATUS, TECHNOLOGIES, TRUST_FRAMEWORKS } from '#constants';

import { createHashForObject } from '../../../helpers/hash.helper';

import DynamicForm from '../../../components/private/DynamicForm/DynamicForm.component';
import SpinnerLoader from '../../../components/shared/Loader/SpinnerLoader.component';
import Dropdown from '../../../components/shared/Dropdowns/Dropdown.component';

import services from '../../../services';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function RequestCredentialPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const users = props.users;
  const credentialPreSelection = props.credentialPreSelection;
  const setIsPrLoading = props.setIsPrLoading;

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);

  const [formSendState, setFormSendState] = useState(false);
  const [credential, setCredential] = useState(undefined);
  const [dynamicFormValue, setDynamicFormValue] = useState({});
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  if (catalogState === REDUCER_STATUS.NEEDS_REFRESH) {
    dispatch(fetchCatalogAsync({ limit: null }));
  }

  if (catalogState === REDUCER_STATUS.READY && credential === undefined && credentialPreSelection !== undefined) {
    setCredential(catalog.find((catalogEntry) => catalogEntry.name === credentialPreSelection) || null);
  }

  const kid = 'default';

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setCredential(undefined);
    setDynamicFormValue({});
    setUser(null);
    setIsOpen(false);
  };

  const isFormValid = () => {
    return kid && credential && user && Object.keys(dynamicFormValue).length > 0;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid,
      sub: user.did,
      credentialContext: credential.context,
      credentialType: credential.name,
      file: ''
    };

    const dynamicFormKeys = Object.keys(dynamicFormValue);

    if (dynamicFormKeys.length > 0) {
      form.file = dynamicFormValue[dynamicFormKeys[0]];
    }

    setFormSendState(true);

    if (typeof form.file !== 'string') {
      const procHash = createHashForObject(form);
      form.file = await services.files.uploadFileToFileManager(form.file, procHash, setErrorPopup);

      if (form.file === null) return;
    }

    await services.orchestrator.createCredentialRequest(form, setErrorPopup);

    setFormSendState(false);
    dispatch(clearEntityCredentialRequestsCache());

    if (setIsPrLoading) {
      setIsPrLoading(undefined);
    }

    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('entity-credentials-page-popup-request-new-credential')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('entity-credentials-page-popup-sending')} isLoading={formSendState}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('entity-credentials-page-popup-credential-name')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('entity-credentials-page-popup-credential-name-placeholder')}
                  value={credential}
                  required
                  options={catalog}
                  disabled={credentialPreSelection !== undefined}
                  getOptionLabel={(catalogItem) => catalogItem.alias}
                  onChange={(catalogItem) => {
                    setCredential(catalogItem);
                    setDynamicFormValue({});
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('entity-credentials-page-popup-issuer')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('entity-credentials-page-popup-issuer-placeholder')}
                  value={user}
                  required
                  options={users}
                  getOptionLabel={(user) => user.businessName}
                  onChange={(user) => {
                    setUser(user);
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <DynamicForm
                translate={translate}
                formJson={
                  credential
                    ? credential.template ||
                      `{"${credential.name || credential.alias}": "${credential.format || 'value'}"}`
                    : '{}'
                }
                format={credential ? credential.format || 'value' : ''}
                value={dynamicFormValue}
                onChange={setDynamicFormValue}
              />
            </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('entity-credentials-page-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('entity-credentials-page-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
