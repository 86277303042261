import {
  inviteCustomer,
  getCustomer,
  addCustomer,
  getCredentialFromCatalog,
  createCredentialRequest,
  rejectCredentialRequest,
  deleteCredentialRequest,
  cancelCredentialRequest,
  createCredential,
  revokeCredential,
  deleteCredential,
  revokeCredentialBySubject,
  getCorporateCredentialRequests,
  getCorporateNonExpiredCredentials,
  getCorporateCredentials,
  createPresentationRequestTemplate,
  getPresentationRequestTemplate,
  deletePresentationRequestTemplate,
  getPresentationRequest,
  getPresentationRequestEntity,
  getPresentationRequestAdmin,
  getPresentationByCdi,
  createPresentationRequest,
  assignPresentationRequest,
  deletePresentationRequest,
  getCatalog
} from './orchestrator';
import { uploadFileToFileManager } from './files';

const services = {
  orchestrator: {
    inviteCustomer,
    getCustomer,
    addCustomer,
    getCredentialFromCatalog,
    createCredentialRequest,
    rejectCredentialRequest,
    deleteCredentialRequest,
    cancelCredentialRequest,
    createCredential,
    revokeCredential,
    deleteCredential,
    revokeCredentialBySubject,
    getCorporateCredentialRequests,
    getCorporateNonExpiredCredentials,
    getCorporateCredentials,
    createPresentationRequestTemplate,
    getPresentationRequestTemplate,
    deletePresentationRequestTemplate,
    getPresentationRequest,
    getPresentationRequestEntity,
    getPresentationRequestAdmin,
    getPresentationByCdi,
    createPresentationRequest,
    assignPresentationRequest,
    deletePresentationRequest,
    getCatalog
  },
  files: {
    uploadFileToFileManager
  }
};

export default services;
