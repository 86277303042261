import axios from 'axios';
import config from '#config';

export async function get(endpoint, urlSearchParams, noAuth) {
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  
  let uri = endpoint.startsWith('http://') || endpoint.startsWith('https://') ? endpoint : config.API.URL + endpoint;

  if (urlSearchParams) {
    uri += '?' + urlSearchParams;
  }

  if (!headers.Authorization) {
    headers.Authorization = sessionStorage.getItem('accessToken') || '';
  }

  if (!headers['X-Id-Token']) {
    headers['X-Id-Token'] = sessionStorage.getItem('idToken') || '';
  }

  let withCredentials = (noAuth) ? false : true;

  //console.log('GET Headers: ', headers);

  let response = { status: 500, data: [] };
  try {
    response = await axios.get(uri, { timeout: config.INTERNAL.AXIOS_TIMEOUT, headers, withCredentials: withCredentials});
  } catch (exception) {
    response = exception.response || exception;
  }

  if (response.headers && response.headers['x-app-total-count'] === 'undefined') {
    response.headers['x-app-total-count'] = undefined;
  }

  if (response.status === 401 || response.status === 403) {
    window.location.href = "/login";
  }

  return response;
}

export async function post(endpoint, form, optionOverrrides, headerOverrides, noAuth) {
  const defaultHeaders = { ...axios.defaults.headers.common, 'Content-Type': 'application/json' };
  const headers = { ...defaultHeaders, ...headerOverrides }
  const defaultOptions = { useApiUrl: true, timeout: config.INTERNAL.AXIOS_TIMEOUT, withCredentials: true }
  const options = { ...defaultOptions, ...optionOverrrides }

  const apiUrl = options.useApiUrl ? config.API.URL : "";
  delete options.useApiUrl;

  if (form instanceof URLSearchParams) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  if (!headers.Authorization) {
    headers.Authorization = sessionStorage.getItem('accessToken') || '';
  }

  if (!headers['X-Id-Token']) {
    headers['X-Id-Token'] = sessionStorage.getItem('idToken') || '';
  }

  let withCredentials = (noAuth) ? false : true;


  let response = { status: 500, data: [] };
  try {
    response = await axios.post(apiUrl + endpoint, form, { ...options, timeout: config.INTERNAL.AXIOS_TIMEOUT, headers, withCredentials: withCredentials });
  } catch (exception) {
    response = exception.response || exception;
  }

  if (response.status === 401 || response.status === 403) {
    window.location.href = "/login";
  }

  return response;
}

export function isOk(statusCode) {
  return String(statusCode)[0] === '2';
}

export function getResponseJson(response) {
  switch (config.API.VERSION) {
    case 1:
    case 2:
    default:
      return response.data.content;
    case 4:
      return response.data;
  }
}
