// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';

import './NotificationTable.component.css';
import NotificationRow from '../Rows/NotificationRow.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_NOTIFICATIONS = [];
const DEFAULT_NOTIFICATION_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => { };
const DEFAULT_SET_ROWS_PER_PAGE = () => { };
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_TRANSLATE_PREFIX = 'notification-table';

function getHeaders(translate, translatePrefix) {
  return [
    { text: 'notification', align: 'left' },
    { text: 'sort', align: 'flex-end' }
  ].map((header) => {
    header.text = translate(`${translatePrefix}-${header.text}`);
    return header;
  });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function NotificationTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const notifications = props.notifications || DEFAULT_NOTIFICATIONS;
  const notificationCount = props.notificationCount || DEFAULT_NOTIFICATION_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;
  const headers = getHeaders(translate, translatePrefix);
  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  // const filteredData = notifications.reduce((acc, obj) => {

  //   const type = JSON.parse(obj.data).type;
  //   if (type === 'createPresentationRequest') {
  //     return (acc.some(item => (item.objectId === obj.objectId) && JSON.parse(item.data).type === 'createPresentationRequest')) ? acc : [...acc, obj]
  //   } else {
  //     return [...acc, obj]
  //   }
  // }, []);

  // const showDataNotifications = filteredData.map((notification) => (
  //   <NotificationRow key={notification.notificationId} translate={translate} notification={notification} />
  // ))

  return (
    <Grid item='true' paddingBottom={4}>
      <TableContainer className='credential-table-container'>
        <Table>
          <TableHeader headers={headers} fontWeight='bold' />
          <TableBody>
            {
              notifications.map((notification) => (
                <NotificationRow key={notification.notificationId} translate={translate} notification={notification} />
              ))
            }
            <SpinnerLoader
              isLoading={isLoading}
              text={translate(`${translatePrefix}-loading`)}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}
            />
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    labelRowsPerPage={translate(`rows-per-page`)}
                    component='div'
                    count={notificationCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
