// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestCreationTable.component.css';
import CollapsiblePRRow from '../Rows/CollapsiblePRRow.component';
import Button from '@mui/material/Button';

import PresentationDetailsPopup from '../Popups/PresentationDetailsPopup.component';
import AttachPresentationPopup from '../Popups/AttachPresentationPopup.component';
import DelegatePresentationRequestPopup from '../Popups/DelegatePresentationRequestPopup.component';

import { useState } from 'react';

import { useDispatch } from 'react-redux';


// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_SEND_BUTTON = false;
const DEFAULT_HIDE_REQUEST_DETAILS = false;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUESTS = [];
const DEFAULT_PRESENTATIONS = [];

const requestDetailsText = 'presentation-request-template-table-details';

const documentText = 'prt-table-document';

function getHeaders(translate, hideDocument) {
  return [
    {
    },
    {
      text: 'prt-table-request-name'
    },
    {
      text: 'prt-table-purpose'
    },
    {
      text: 'prt-table-creation-date'
    },
    {

    }
  ]
    .filter((header) => {
      if (hideDocument && header.text === documentText) {
        return false;
      }

      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestVisualizeTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideSendButton = props.hideSendButton || DEFAULT_HIDE_SEND_BUTTON;
  const hideRequestDetails = props.hideRequestDetails || DEFAULT_HIDE_REQUEST_DETAILS;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;

  const presentationRequests = props.PresentationRequests || DEFAULT_PRESENTATION_REQUESTS;
  const presentationCount = presentationRequests.length || 0;
  let presentations = props.presentations || DEFAULT_PRESENTATIONS;

  const isLoading = props.isLoading || false;
  const setIsPrLoading = props.setIsPrLoading || (() => {});

  const dispatch = useDispatch();

  const [isPresentationDetailsOpen, setIsPresentationDetailsOpen] = useState(false);
  const [isPresentationAttachOpen, setIsPresentationAttachOpen] = useState(false);
  const [isPresentationSendOpen, setIsPresentationSendOpen] = useState(false);

  const [selectedPresentationRequest, setSelectedPresentationRequest] = useState({});

  const hideEntityActions = props.hideEntityActions || false;
  const hideAdminActions = props.hideAdminActions || false;

  const headers = getHeaders(translate, hideRequestDetails);
  
  const onCancelOrExit = () => {
    setIsPresentationDetailsOpen(null);
    setIsPresentationAttachOpen(null);
    setIsPresentationSendOpen(null);
  };

  console.log(presentationRequests)
  console.log(presentations)
  presentations = presentations.filter((pres) => pres.decoded.vp.procHash !== undefined);
  console.log(presentations)
  return (
    <Grid item='true' paddingBottom={paddingBottom}>
      <TableContainer className='PR Visualize'>
        <Table>
          <TableHeader headers={headers}></TableHeader>
          <TableBody>
            <SpinnerLoader
              text={translate('PR Table Loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}
              isLoading={isLoading}>
              {presentationRequests.map((presentationRequest, index) => (
                <CollapsiblePRRow
                  key={index}
                  translate={translate}
                  setErrorPopup={setErrorPopup}
                  PresentationRequest={presentationRequest}
                  hideRequestDetails={hideRequestDetails}
                  hideSendButton={hideSendButton}
                  hideEntityActions={hideEntityActions}
                  hideAdminActions={hideAdminActions}
                  setIsPresentationDetailsOpen={setIsPresentationDetailsOpen}
                  setIsPresentationAttachOpen={setIsPresentationAttachOpen}
                  setIsPresentationSendOpen={setIsPresentationSendOpen}
                  setSelectedPresentationRequest={setSelectedPresentationRequest}
                  presentations={presentations.filter((presentation) => 
                    (presentation.decoded.jtipr === presentationRequest.decoded.jti) || (presentation.decoded.vp.procHash === presentationRequest.decoded.pr.procHash)
                  )}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    labelRowsPerPage={translate(`rows-per-page`)}
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
      <PresentationDetailsPopup
        translate={translate}
        isOpen={isPresentationDetailsOpen}
        setIsOpen={setIsPresentationDetailsOpen}
        presentations={presentations.filter((presentation) => (selectedPresentationRequest.decoded) ?
          ((presentation.decoded.jtipr === selectedPresentationRequest.decoded.jti) || (presentation.decoded.vp.procHash === selectedPresentationRequest.decoded.pr.procHash)): undefined /*|| presentation.decoded.procHash === selectedPresentationRequest.decoded.procHash*/
        )}
        presentationRequest={selectedPresentationRequest}
        onCancelOrExit={onCancelOrExit}
      />
      <AttachPresentationPopup
        translate={translate}
        isOpen={isPresentationAttachOpen}
        setIsOpen={setIsPresentationAttachOpen}
        formSent={() => {
          setIsPrLoading(undefined);
        }}
        presentationRequest={selectedPresentationRequest}
      />
      <DelegatePresentationRequestPopup
        translate={translate}
        isOpen={isPresentationSendOpen}
        setIsOpen={setIsPresentationSendOpen}
        formSent={() => {
          setIsPrLoading(undefined);
        }}
        presentationRequest={selectedPresentationRequest}
      />
    </Grid>
  );
}

