// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { PLATFORM_TYPE, REDUCER_STATUS } from '#constants';

import {
  clearCustomerCache,
  fetchCustomersAsync,
  selectCustomers,
  selectCustomersCount,
  selectCustomersPage,
  selectCustomersState,
  selectCustomersRowsPerPage
} from '#reducers/customers';

import ContactFilters from '#privateComponents/Filters/ContactFilters.component';
import ContactsTable from '#privateComponents/Tables/ContactsTable.component';
import NewCorporateContactPopup from '#privateComponents/Popups/NewCorporateContactPopup.component';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CorporateContacts(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectCustomersPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectCustomersRowsPerPage));

  const contacts = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);
  const contactCount = useSelector(selectCustomersCount);

  const isLoading = customerState === REDUCER_STATUS.LOADING;
  const needsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCustomerCache());
  };

  if (needsReload) {
    dispatch(
      fetchCustomersAsync({
        //comms: PLATFORM_TYPE[0],
         comms: undefined,
        page,
        limit: rowsPerPage,
        search,
        status: status ? status.status : undefined
      })
    );
  }

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearCustomerCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCustomerCache());
  };

  return (
    <ViewsLayout
      pageTitle={translate('contacts-corporate-page-title')}
      buttons={[
        {
          onClick: () => setIsOpen(true),
          text: translate('contacts-corporate-page-new-contact')
        }
      ]}
      layoutSize={layoutSize}>
      <NewCorporateContactPopup
        translate={translate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <Grid item='true'>
        <ContactFilters
          translate={translate}
          search={search}
          setSearch={setSearch}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <ContactsTable
        translate={translate}
        isLoading={isLoading}
        contacts={contacts}
        contactCount={contactCount}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideAlias={false}
        hideTelephone={true}
        hideCif={false}
        translatePrefix={`contacts-corporate-table`}
      />
    </ViewsLayout>
  );
}
