// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { forwardRef } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import AttachFile from '@mui/icons-material/AttachFile';

// ------FILE MODULES---------------------------------------------------------
import './FileInput.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_DISABLED = false;
const DEFAULT_IS_MULTIPLE = false;
const DEFAULT_ON_CHANGE = () => {};
const DEFAULT_PLACEHOLDER = '';
const DEFAULT_VALUE = null;
const DEFAULT_REQUIRED = false;
const DEFAULT_INNER_INPUT_PROPS = {
  sx: {
    padding: '0.5rem 1.188rem'
  }
};
const DEFAULT_INPUT_PROPS = {
  sx: {
    height: '2.5rem',
    width: '22.125rem',
    color: '#666666',
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    borderRadius: '0.313rem',
    fontSize: '0.938rem',
    '&:hover': {
      border: '2px solid #E5E5E5'
    },
    paddingRight: '0px'
  },
  startAdornment: (
    <InputAdornment position='start'>
      <AttachFile />
    </InputAdornment>
  )
};
const DEFAULT_TEXTFIELD_PROPS = {};

function Input(props, ref) {
  const text = props.text;
  const className = props.className + ' input-field';

  return (
    <div className='input-div'>
      <input {...props} className={className} />
      <span className='input-span'>{text}</span>
    </div>
  );
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function FileInput(props) {
  const disabled = props.disabled || DEFAULT_DISABLED;
  const isMultiple = props.isMultiple || DEFAULT_IS_MULTIPLE;
  const onChange = props.onChange || DEFAULT_ON_CHANGE;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;
  const value = props.value || DEFAULT_VALUE;
  const required = props.required || DEFAULT_REQUIRED;

  const getTheInputText = () => {
    if (value === null || (Array.isArray(value) && value.length === 0)) {
      return placeholder || '';
    }

    if (value && hasAtLeastOneFile) {
      if (Array.isArray(value) && value.length > 1) {
        return `${value.length} files`;
      }

      return value instanceof File ? value.name : value[0]?.name || '';
    }
    return '';
  };

  const hasAtLeastOneFile = Array.isArray(value) ? value.length > 0 : value instanceof File;

  const handleChange = (event) => {
    const fileList = event.target.files;
    const files = fileList ? Array.from(fileList) : [];

    if (isMultiple) {
      onChange(files);
    } else {
      onChange(files[0]);
    }
  };

  const innerInputProps = {
    ...DEFAULT_INNER_INPUT_PROPS,
    ...props.innerInputProps,
    text: getTheInputText(),
    multiple: isMultiple,
    isPlaceholder: value === null || (Array.isArray(value) && value.length === 0),
    placeholder,
    required,
    isMultiple,
    selection: value,
    sx: {
      paddingRight: 0
    }
  };

  const inputProps = {
    ...DEFAULT_INPUT_PROPS,
    ...props.inputProps,
    inputProps: innerInputProps,
    inputComponent: forwardRef(Input)
  };

  const textFieldProps = {
    ...DEFAULT_TEXTFIELD_PROPS,
    ...props.textFieldProps,
    type: 'file',
    disabled,
    onChange: handleChange,
    InputProps: inputProps
  };

  return <TextField {...textFieldProps} />;
}
