import { Fragment } from 'react';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import ViewsLayout from '../../views/Main/MainView.layout';


const DEFAULT_PAGE_TITLE = '';
const DEFAULT_BUTTONS = [];
const DEFAULT_SECONDARY_TABLE_TITLE = '';
const DEFAULT_SECONDARY_TABLE_COMPONENT = Fragment;
const DEFAULT_SECONDARY_TABLE_PROPS = {};

export default function PRCreationLayout(props) {
  const pageTitle = props.pageTitle || DEFAULT_PAGE_TITLE;
  const buttons = props.buttons || DEFAULT_BUTTONS;
  const secondaryTableTitle = props.secondaryTableTitle || DEFAULT_SECONDARY_TABLE_TITLE;
  const SecondaryTableComponent = props.secondaryTableComponent || DEFAULT_SECONDARY_TABLE_COMPONENT;
  const secondaryTableProps = { ...DEFAULT_SECONDARY_TABLE_PROPS, ...props.secondaryTableProps };
  const layoutSize = props.layoutSize;

  const secondaryTableRendered = (
    <Grid container direction='column' alignItems='stretch' spacing={3}>
      <Grid item='true'>
        <Typography color='textPrimary' variant='h5' fontWeight='bold' fontSize='1.25rem'>
          {secondaryTableTitle}
        </Typography>
      </Grid>
      <SecondaryTableComponent {...secondaryTableProps} />
    </Grid>
  );

  return (
    <ViewsLayout pageTitle={pageTitle} buttons={buttons} layoutSize={layoutSize}>
      <Grid item='true'>
            {secondaryTableRendered}
      </Grid>
      {props.children}
    </ViewsLayout>
  );
}
