// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestCreationTable.component.css';
import CollapsiblePRTRow from '../Rows/CollapsiblePRTRow.component';
import Button from '@mui/material/Button';

import SendPresentationRequestPopup from '../Popups/SendPresentationRequestPopup.component';
import CreatePRTemplateSelection from '../Popups/CreatePRTemplateSelection.component';
import { useState } from 'react';
import services from '../../../services';

import { useDispatch, useSelector } from 'react-redux';

import { REDUCER_STATUS } from '#constants';

import {
  fetchPRTAsync,
  selectPresentationRequestTemplates,
  selectPresentationRequestTemplatesCount,
  selectPresentationRequestTemplatesState
} from '../../../store/reducers/presentationRequestTemplates';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_SEND_BUTTON = false;
const DEFAULT_HIDE_REQUEST_DETAILS = false;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUEST_TEMPLATES = [];

const requestDetailsText = 'presentation-request-template-table-details';

const documentText = 'prt-table-document';

function getHeaders(translate, hideDocument, setIsCreatePRTemplateSelectionOpen) {
  return [
    {
    },
    {
      text: 'prt-table-request-name'
    },
    {
      text: 'prt-table-purpose'
    },
    {
      text: 'prt-table-creation-date'
    },
    {
      text: documentText
    },
    {
      text: 'prt-table-actions',
      align: 'right',
      component:     <Button
      color='primary'
      variant='contained'
      fullWidth
      size='small'
      sx={{
        height: '2.5rem',
        textTransform: 'none'
      }}
      onClick={ () => {
        setIsCreatePRTemplateSelectionOpen(true)
      }
        }>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{translate('prt-button-add-form')}</Typography>
    </Button>
    }
  ]
    .filter((header) => {
      if (hideDocument && header.text === documentText) {
        return false;
      }

      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestCreationTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const hideFile = props.hideFile || false;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideSendButton = props.hideSendButton || DEFAULT_HIDE_SEND_BUTTON;
  const hideRequestDetails = props.hideRequestDetails || DEFAULT_HIDE_REQUEST_DETAILS;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const selectedPresentationRequestTemplates = props.selectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const setSelectedPresentationRequestTemplates = props.setSelectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatePRTemplateSelectionOpen, setIsCreatePRTemplateSelectionOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const dispatch = useDispatch();

  const presentationTemplates = useSelector(selectPresentationRequestTemplates);
  const presentationsState = useSelector(selectPresentationRequestTemplatesState);
  const presentationCount = useSelector(selectPresentationRequestTemplatesCount);

  const isLoading = presentationsState === REDUCER_STATUS.LOADING;
  const needsReload = presentationsState === REDUCER_STATUS.NEEDS_REFRESH;

  const headers = getHeaders(translate, hideRequestDetails, setIsCreatePRTemplateSelectionOpen);

  if (needsReload) {
    dispatch(fetchPRTAsync({page: 0}));
  }

  const onCancelOrExit = () => {
    setTitle(null);
    setDescription(null);
    setIsOpen(false);
  };
  
  return (
    <Grid item='true' paddingBottom={paddingBottom}>
      <TableContainer className='PR Creation'>
        <Table>
          <TableHeader headers={headers}></TableHeader>
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('PR Table Loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
              {selectedPresentationRequestTemplates.map((presentationRequestTemplate, index) => (
                <CollapsiblePRTRow
                  key={index}
                  translate={translate}
                  setErrorPopup={setErrorPopup}
                  presentationRequestTemplate={presentationRequestTemplate}
                  hideFile={hideFile}
                  hideRequestDetails={hideRequestDetails}
                  hideSendButton={hideSendButton}
                  selectedPresentationRequestTemplates={selectedPresentationRequestTemplates}
                  setSelectedPresentationRequestTemplates={setSelectedPresentationRequestTemplates}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    labelRowsPerPage={translate(`rows-per-page`)}
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
      <SendPresentationRequestPopup
        translate={translate}
        setErrorPopup={setErrorPopup}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        presentationRequestTemplate={selectedPresentationRequestTemplates[0]}
      />
      <CreatePRTemplateSelection
        translate={translate}
        isOpen={isCreatePRTemplateSelectionOpen}
        setIsOpen={setIsCreatePRTemplateSelectionOpen}
        onCancelOrExit={onCancelOrExit}
        title={title}
        description={description}
        selectedPresentationRequestTemplates={selectedPresentationRequestTemplates}
        setSelectedPresentationRequestTemplates={setSelectedPresentationRequestTemplates}
      />
    </Grid>
  );
}

