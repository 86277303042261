import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AdminCredentialRequestsIcon() {
  return (
    <SvgIcon viewBox='0 0 50 50'>
      <path
        fill='#fefffe'
        d='M 26.5,8.5 C 28.8421,8.50414 30.8421,9.33747 32.5,11C 31.7471,12.487 30.9138,12.6537 30,11.5C 29,27.5 28,27.5 27,11.5C 26.1072,12.7112 25.2739,12.7112 24.5,11.5C 25.1925,10.4822 25.8592,9.48224 26.5,8.5 Z'
      />
      <path
        fill='#fefffe'
        d='M 41.5,21.5 C 45.0714,21.4077 46.2381,23.0743 45,26.5C 40.5639,29.3026 36.3972,32.4693 32.5,36C 27.5,36.3333 22.5,36.6667 17.5,37C 14.9217,38.6647 12.2551,39.6647 9.5,40C 11.5267,37.3195 14.1934,35.6528 17.5,35C 22.1667,34.6667 26.8333,34.3333 31.5,34C 34.9895,31.0887 38.6562,28.422 42.5,26C 43.4506,25.2825 43.6172,24.4491 43,23.5C 39.2486,25.2908 35.7486,27.4574 32.5,30C 28.8333,30.6667 25.1667,30.6667 21.5,30C 25.0284,29.911 28.3618,29.2444 31.5,28C 26.8333,27.3333 22.1667,27.3333 17.5,28C 13.0455,30.3109 8.54551,32.4776 4,34.5C 3.83333,34.1667 3.66667,33.8333 3.5,33.5C 12.162,25.7401 22.162,23.4068 33.5,26.5C 36.2308,24.8084 38.8975,23.1417 41.5,21.5 Z'
      />
    </SvgIcon>
  );
}
