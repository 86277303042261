import { useLinkClickHandler } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import Menu from '@mui/material/Menu';

import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { Divider } from '@mui/material';
import { setError } from '../../../store/reducers/error';
import { clearEntityPresentationRequestsCache } from '../../../store/reducers/entityPresentationRequests';
import { clearPresentationsCache } from '../../../store/reducers/adminPresentations';
import { PAGE_ROUTES } from '#constants';
import services from '../../../services';

const { EntityPresentationRequests } = PAGE_ROUTES

const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ANCHOR_ELEMENT = undefined;
const DEFAULT_SET_ANCHOR_ELEMENT = () => {};
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_JTI = '';
const DEFAULT_SIGNED_TOKEN = '';
const DEFAULT_PRESENTATION_REQUEST = {};

export default function PresentationRequestButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const anchorElement = props.anchorElement || DEFAULT_ANCHOR_ELEMENT;
  const setAnchorElement = props.setAnchorElement || DEFAULT_SET_ANCHOR_ELEMENT;
  const hideSignButton = props.hideSignButton || DEFAULT_OPEN_POPUP_INSTEAD;
  const jti = props.jti || DEFAULT_JTI;
  const signedToken = props.signedToken || DEFAULT_SIGNED_TOKEN;
  const presentationRequest = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;

  const [actionInProgress, setActionInProgress] = useState(false);

  const dispatch = useDispatch();

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = (event) => {
    if (actionInProgress) return;
    event.stopPropagation();
    setAnchorElement(null);
  };

  const openPRDetails = useLinkClickHandler(`${EntityPresentationRequests}/${jti}`);

  const copyTokenHandler = (event) => {
    navigator.clipboard.writeText(signedToken);
    handleClose(event);
  };

  const onDeleteClicked = async (event) => {
    setActionInProgress(true);
    console.log('BORRADO')
    event.stopPropagation();
    const success = await services.orchestrator.deletePresentationRequest(
      presentationRequest.storedJwt.jti,
      { ...presentationRequest.storedJwt, status: 'Deleted' },
      setErrorPopup
    );
    setActionInProgress(false);

    if (success) {
      dispatch(clearEntityPresentationRequestsCache()); 
      dispatch(clearPresentationsCache());
    }
  };
  
  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          minWidth: '15%'
        }
      }}>
      <Typography sx={{ paddingLeft: '1.25rem' }} fontSize='0.938rem' fontWeight='bold'>
        {translate('presentation-request-action-proofs')}
      </Typography>
      <MenuItem onClick={copyTokenHandler}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <VerifiedUserOutlined sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('presentation-request-action-copy-token')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
      <Divider />
      <MenuItem onClick={onDeleteClicked}>
        <Grid container spacing={1}>
        <Grid item='true'>
            <CancelOutlined sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('presentation-request-action-delete')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
      {/* <Hider isHidden={hideSignButton}>
        <Divider />

        <MenuItem onClick={openPRDetails}>
          <Grid container spacing={1}>
            <Grid item='true'>
              <Fingerprint sx={{ color: '#662D91' }} />
            </Grid>
            <Grid item='true'>
              <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
                {translate('presentation-request-action-view-details')}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Hider> */}
    </Menu>
  );
}
