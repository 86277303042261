import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function HelpdeskIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 24, height: 24 }}>
      <path
        d='M12 8H12.01M12 11V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z'
        stroke='#1E1E56'
        fill='none'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
