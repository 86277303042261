import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AdminCredentialsIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M13.3672 17.2769H20.8849M20.8849 17.2769L18.0657 14.0845M20.8849 17.2769L18.0657 20.4694'
        stroke='#F0F3FF'
        strokeWidth='0.75'
        strokeLinecap='square'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M18.5353 12.1772H17.8125V6.50402C17.8125 6.34032 17.7523 6.19025 17.6318 6.0538C17.5113 5.91738 17.3788 5.84917 17.2342 5.84917H15.2102V7.85471H6.82511V5.84917H4.80112C4.65656 5.84917 4.52404 5.91738 4.40355 6.0538C4.28308 6.19025 4.22285 6.34032 4.22285 6.50402V20.5837C4.22285 20.7474 4.28308 20.8975 4.40355 21.0339C4.52404 21.1703 4.65656 21.2386 4.80112 21.2386H16.1861V22.0571H4.80112C4.43006 22.0571 4.12044 21.9166 3.87227 21.6356C3.62409 21.3545 3.5 21.0039 3.5 20.5837V6.50402C3.5 6.08382 3.62409 5.7332 3.87227 5.45216C4.12044 5.17111 4.43006 5.03059 4.80112 5.03059H9.54665C9.59605 4.61447 9.75779 4.26487 10.0319 3.98178C10.306 3.69868 10.6346 3.55713 11.0177 3.55713C11.4067 3.55713 11.7368 3.69868 12.0079 3.98178C12.279 4.26487 12.4393 4.61447 12.4887 5.03059H17.2342C17.6053 5.03059 17.9149 5.17111 18.1631 5.45216C18.4113 5.7332 18.5353 6.08382 18.5353 6.50402V12.1772ZM11.0177 6.09475C11.2357 6.09475 11.4167 6.01323 11.5607 5.85021C11.7047 5.68716 11.7767 5.48216 11.7767 5.23523C11.7767 4.98829 11.7047 4.78331 11.5607 4.62028C11.4167 4.45723 11.2357 4.37571 11.0177 4.37571C10.7996 4.37571 10.6186 4.45723 10.4746 4.62028C10.3307 4.78331 10.2587 4.98829 10.2587 5.23523C10.2587 5.48216 10.3307 5.68716 10.4746 5.85021C10.6186 6.01323 10.7996 6.09475 11.0177 6.09475Z'
        fill='#F0F3FF'
      />
    </SvgIcon>
  );
}
