import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Hider from '../../components/shared/Hider/Hider.component';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import DehazeIcon from '@mui/icons-material/Dehaze';

import './Main.layout.css';

import SidePanel from '../../components/private/SidePanel/SidePanel.component';

export default function MainLayout(props) {
  const header = props.header;
  const sidePanel = props.sidePanel;
  const noSidePanel = props.noSidePanel;
  const sidebarBackgroundColor = props.sidebarBackgroundColor;
  const layoutSize = props.layoutSize;
  const [sideMenuHide, setSideMenuHide] = useState(layoutSize === 'xsOrS' ? true : false)
  const [popoverMenu, setPopoverMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverMenu(false);
  };

  useEffect(() => {
    setSideMenuHide(layoutSize === 'xsOrS' ? true : false)
    if(layoutSize !== 'xsOrS') {
      setPopoverMenu(false)
    }
  },[layoutSize])

  return (
    <Container id={'main-layout-container-l0'} disableGutters={true} maxWidth={'100vw'} >
      <Popover 
        id={'main-layout-popover'}
        open={popoverMenu} 
        anchorOrigin={{vertical: 'top', horizontal: 2}}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
          <div style={{backgroundColor: sidebarBackgroundColor, paddingBottom: '40px'}}>
              <SidePanel id={'side-0'} floatingMenu={true} translator={props.translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize} onNavigation={() => {setPopoverMenu(false)}}/>
          </div>
      </Popover>
      <div style={{display: 'flex'}}>
        <Hider isHidden={sideMenuHide}>
          <div id={'main-layout-flex-0'} style={{width: '300px', height: '100vh', backgroundColor: sidebarBackgroundColor}}>
            <div className='admin-layout-side-panel' style={{backgroundColor: sidebarBackgroundColor}}>
              <div>{sidePanel}</div>
            </div>
          </div>
        </Hider>
        <Grid id={'main-layout-grid-l0'} height={'100vh'} width={layoutSize === 'xsOrS' ? '100vw' : 'calc(100vw - 300px)'}>
          <Grid size={{ s: 12, m: 9, l: 10}} >
            {layoutSize === 'xsOrS' && 
            <div style={{display: 'flex', justifyContent: 'space-between' }}>
              {/* <IconButton sx={{paddingRight: 'calc(100vw - 330px)'}} onClick={handlePopoverClick}><DehazeIcon/></IconButton> */}
              <IconButton onClick={handlePopoverClick}><DehazeIcon/></IconButton>
              <div id={'main-layout-xs-header-container'} >{header}</div>
            </div>}
            {layoutSize !== 'xsOrS' && <div style={{ paddingLeft: '2em', paddingRight: '2em'}}>{header}</div>}
            <Divider sx={{ borderColor: '#00000033' }} />
            <div id={'main-layout-outlet-container'}>
              <Outlet />
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
