// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

// ------FILE MODULES---------------------------------------------------------

import TableCell from '../../shared/Tables/TableCell.component';
import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import { MAX_LENGTH } from '#constants';
import Hider from '../../shared/Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};

const DEFAULT_CREDENTIAL = {};
const DEFAULT_CATALOG_ENTRY = {};

const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[0];
const DEFAULT_TRANSLATE_PREFIX = 'credential-row';


// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRow(props) {
  const i18nTranslate = props.translate || DEFAULT_TRANSLATE;
  const credential = props.credential || DEFAULT_CREDENTIAL;
  const catalogEntry = props.catalogEntry || DEFAULT_CATALOG_ENTRY;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  let valueIsFile = false;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;

  if (credential.decoded) {
    valueIsFile = credential.decoded.vc.credentialSubject[catalogEntry.name].startsWith('http')
  }
  const translate = (string) => {
    return i18nTranslate(`${translatePrefix}-${string}`);
  };

  console.log(credential);
  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.alias} maxLength={maxNameLength} />
      </TableCell>
      <TableCell>{catalogEntry.levelOfAssurance}</TableCell>
      <TableCell>
        <MaxLengthText text={(credential.decoded) ? credential.decoded.sub : undefined} maxLength={maxNameLength}/>
      </TableCell>
      <Hider isHidden={!valueIsFile}>
        <TableCell useTypography={false}>
          <Link
            sx={{
              fontSize: '0.938rem',
              fontWeight: '400',
              textDecoration: 'none',
              color: '#666666',
              'a:visited': {
                textDecoration: 'none',
                color: '#666666'
              }
            }}
            target='_blank'
            href={(credential.decoded) ? credential.decoded.vc.credentialSubject[catalogEntry.name] : undefined}>
            {translate('download-document')}
          </Link>
        </TableCell>
      </Hider>
      <Hider isHidden={valueIsFile}>
        <TableCell>
          <MaxLengthText text={(credential.decoded) ? credential.decoded.vc.credentialSubject[catalogEntry.name] : undefined} maxLength={maxNameLength}/>
        </TableCell>
      </Hider>
    </TableRow>
  );
}
