// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------
// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------

import SearchBar from '../../shared/SearchBars/SearchBar.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';
import DatePicker from '../../shared/DatePicker/DatePicker.component';
// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_USERS = [];
const DEFAULT_IS_LOADING = false;
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_CUSTOMER = '';
const DEFAULT_SET_CUSTOMER = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};
const DEFAULT_START_DATE = 0;
const DEFAULT_SET_START_DATE = () => {};
// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function NotificationFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const users = props.users || DEFAULT_USERS;
  const createDate = props.createDate || DEFAULT_START_DATE;
  const setCreateDate = props.setCreateDate || DEFAULT_SET_START_DATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const customer = props.customer || DEFAULT_CUSTOMER;
  const setCustomer = props.setCustomer || DEFAULT_SET_CUSTOMER;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;
  const layoutSize = props.layoutSize;

  const handleRefresh = () => {
    // Limpiar los filtros de búsqueda, cliente y fecha
    setSearch('');
    setCustomer('');
    setCreateDate(null);
    
    // Llamar a la función de refresh
    refresh();
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='credentials-filters-container'
      paddingTop='0.5em'
      paddingBottom='0.5em'>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('notification-filter-search')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
        />
      </Grid>
      <Grid item='true'>
        <Dropdown
          isLoading={isLoading}
          options={users}
          getOptionLabel={(customer) => customer.businessName}
          onChange={(users) => {
            setCustomer(users);
            onFilterSubmit();
          }}
          value={customer}
          label={translate('credentials-page-filter-issuer')}
        />
      </Grid>
      <Grid item='true' alignItems='center'>
        <DatePicker
          required
          placeholder={translate('prt-filter-create-date')}
          value={createDate}
          max={new Date().getTime() + 86400000}
          onChange={(unixTime) => {
            setCreateDate(unixTime);
            onFilterSubmit();
          }}
          format="dd/MM/yyyy"
        />
      </Grid>

      <Grid item='true'>
        <Button
          color='primary'
          variant='outlined'
          sx={{
            height: '2.5rem',
            textTransform: 'none'
          }}
          onClick={handleRefresh}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('credentials-page-refresh')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
