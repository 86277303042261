// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

// ------ FILE MODULES ---------------------------------------------------------
import { clearPresentationsCache } from '#reducers/adminPresentations';

import SendPresentationRequestPopup from '#privateComponents/Popups/SendPresentationRequestPopup.component';
import PresentationRequestCreationTable from '#privateComponents/Tables/PresentationRequestCreationTable.component';
import { clearPresentationRequestTemplatesCache, selectPresentationRequestTemplatesPage, selectPresentationRequestTemplatesRowsPerPage } from '#reducers/presentationRequestTemplates'

import PRCreationLayout from '#layouts/Visualizer/PRCreation.layout';

import { PAGE_ROUTES } from '#constants';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestCreation(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(useSelector(selectPresentationRequestTemplatesPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectPresentationRequestTemplatesRowsPerPage));

  const [selectedPresentationRequestTemplates, setSelectedPresentationRequestTemplates] = useState([]);

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearPresentationRequestTemplatesCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearPresentationRequestTemplatesCache());
  };

  return (
    <PRCreationLayout
      translate={translate}
      secondaryTableTitle={translate('admin-presentation-request-template-assign')}
      secondaryTableComponent={PresentationRequestCreationTable}
      secondaryTableProps={{
        translate: translate,
        setErrorPopup: setErrorPopup,
        hideFile: true,
        selectedPresentationRequestTemplates: selectedPresentationRequestTemplates,
        setSelectedPresentationRequestTemplates: setSelectedPresentationRequestTemplates,
        page,
        handleSetPage,
        rowsPerPage,
        handleSetRowsPerPage,
        hideRequestDetails: true
      }}
      buttons={[
        {
          text: translate('prt-details-page-send-request-button'),
          onClick: () => {
            setIsOpen(true);
          }
        }
      ]}>
      <SendPresentationRequestPopup
        translate={translate}
        setErrorPopup={setErrorPopup}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formSent={() => {
          dispatch(clearPresentationsCache());
          navigate(PAGE_ROUTES.AdminPresentationRequestHistory);
        }}
        presentationRequestTemplates={selectedPresentationRequestTemplates}
      />
    </PRCreationLayout>
  );
}
