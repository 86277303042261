// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

// ------FILE MODULES---------------------------------------------------------
import SingleTableRow from "../../shared/Tables/SingleTableRow.component";
import Hider from '../../shared/Hider/Hider.component';

import PresentationTable from '../Tables/PresentationTable.component';
import ContactRow from './ContactRow.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CONTACT = {};
const DEFAULT_PRESENTATIONS = {};
const DEFAULT_PRESENTATION_REQUEST = {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CollapsibleContactRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const contact = props.contact || DEFAULT_CONTACT;
  const presentations = props.presentations || DEFAULT_PRESENTATIONS;
  const presentationRequest = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;

  const [open, setOpen] = useState(false);

  function collapsedRows() {
    return (
      presentations.filter((presentation) => presentation.decoded.iss === contact.did).map((presentation) =>
      <SingleTableRow colSpan={6}>
        <PresentationTable
          translate={translate}
          presentation={presentation}
          presentationRequest={presentationRequest}
        />
      </SingleTableRow>
    )
  )
  }

  return (
    <>
      <ContactRow
        key={contact.did}
        translate={translate}
        contact={contact}
        hideTelephone={true}
        open={open}
        setOpen={setOpen}
        maxDidLength={75}
        hideCif={true}
        hideAlias={true}
        hideStatus={true}
        hideEmail={true}
      />
      <Hider isHidden={!open}>
        {collapsedRows()}
      </Hider>
    </>
  )
}
