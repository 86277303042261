// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';

import MoreVert from '@mui/icons-material/MoreVert';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH } from '#constants';

import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';

import Status from '../Status/Status.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ACTIONS_COMPONENT = Fragment;
const DEFAULT_NAME = '';
const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[1];
const DEFAULT_END_DATE = null;
const DEFAULT_SUBJECT_NAME = '';
const DEFAULT_HIDE_HASH_BUTTON = false;
const DEFAULT_TRANSACTION_HASH = '';
const DEFAULT_SIGNED_TOKEN = '';
const DEFAULT_STATUS = '';
const DEFAULT_HIDE_STATUS = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_LEVEL_OF_ASSURANCE = false;
const DEFAULT_LEVEL_OF_ASSURANCE = 0;
const DEFAULT_HIDE_DATES = false;
const DEFAULT_VALUE_IS_FILE = false;
const DEFAULT_VALUE = '';
const DEFAULT_TRANSLATE_PREFIX = 'credential-row';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CredentialRow(props) {
  const i18nTranslate = props.translate || DEFAULT_TRANSLATE;
  const ActionsComponent = props.actionsComponent || DEFAULT_ACTIONS_COMPONENT;
  const name = props.name || DEFAULT_NAME;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const endDate = props.endDate || DEFAULT_END_DATE;
  const subjectName = props.subjectName || DEFAULT_SUBJECT_NAME;
  const hideHashButton = props.hideHashButton || DEFAULT_HIDE_HASH_BUTTON;
  const transactionHash = props.transactionHash || DEFAULT_TRANSACTION_HASH;
  const signedToken = props.signedToken || DEFAULT_SIGNED_TOKEN;
  const status = props.status || DEFAULT_STATUS;
  const hideStatus = props.hideStatus || DEFAULT_HIDE_STATUS;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideLevelOfAssurance = props.hideLevelOfAssurance || DEFAULT_HIDE_LEVEL_OF_ASSURANCE;
  const levelOfAssurance = props.levelOfAssurance || DEFAULT_LEVEL_OF_ASSURANCE;
  const hideDates = props.hideDates || DEFAULT_HIDE_DATES;
  const valueIsFile = props.valueIsFile || DEFAULT_VALUE_IS_FILE;
  const value = props.value || DEFAULT_VALUE;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;
  const credential = props.credential;

  const [anchorElement, setAnchorElement] = useState(null);

  const parsedEndDate = endDate
    ? new Date(endDate * 1000).toLocaleDateString(undefined, { dateStyle: 'medium' })
    : 'N/A';

  const translate = (string) => {
    return i18nTranslate(`${translatePrefix}-${string}`);
  };

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <TableCell useTypography={false}>
        <MaxLengthText text={name} maxLength={maxNameLength} />
      </TableCell>
      <Hider isHidden={hideLevelOfAssurance}>
        <TableCell color='#666666'>{levelOfAssurance}</TableCell>
      </Hider>
      <TableCell>{subjectName}</TableCell>
      <Hider isHidden={!valueIsFile}>
        <TableCell useTypography={false}>
          <Link
            sx={{
              fontSize: '0.938rem',
              fontWeight: '400',
              // textDecoration: 'none',
              color: '#666666',
              'a:visited': {
                textDecoration: 'none',
                color: '#666666'
              }
            }}
            target='_blank'
            href={valueIsFile ? value : URL.createObjectURL(new Blob([value], { type: 'text/plain' }))}>
            {translate('download-document')}
          </Link>
        </TableCell>
      </Hider>
      <Hider isHidden={valueIsFile}>
        <TableCell>{value}</TableCell>
      </Hider>
      <Hider isHidden={hideDates}>
        <TableCell color='#666666'>{parsedEndDate}</TableCell>
      </Hider>
      <Hider isHidden={hideStatus}>
        <TableCell useTypography={false}>
          <Status translate={i18nTranslate} keyword={translatePrefix} status={status} />
        </TableCell>
      </Hider>
      <Hider isHidden={hideActions}>
        <TableCell useTypography={false} align='right'>
          <IconButton
            onClick={(e) => {
              setAnchorElement(e.target);
            }}>
            <MoreVert />
          </IconButton>

          <ActionsComponent
            translate={i18nTranslate}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            hideHashButton={hideHashButton}
            transactionHash={transactionHash}
            signedToken={signedToken}
            status={status}
            credential={credential}
          />
        </TableCell>
      </Hider>
    </TableRow>
  );
}
