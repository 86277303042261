// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import MoreHoriz from '@mui/icons-material/MoreHoriz';

// ------FILE MODULES---------------------------------------------------------
import TableCell from '../../shared/Tables/TableCell.component';
import Status from '../Status/Status.component';
import PresentationRequestButtons from '../Dropdowns/PresentationRequestButtons';
import Hider from '../../shared/Hider/Hider.component';
import { PAGE_ROUTES } from "#constants";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE_PREFIX = 'presentation-request-row';
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_PRESENTATION_REQUEST = { storedJwt: { status: '' }, decoded: { pr: { type: [] } } };
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_STATUS = false;
const DEFAULT_HIDE_NAME = false;
const DEFAULT_HIDE_ISSUER = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_TYPE = false;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestRow(props) {
  const i18nTranslate = props.translate || DEFAULT_TRANSLATE;
  const presentationRequest = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideName = props.hideName || DEFAULT_HIDE_NAME;
  const hideIssuer = props.hideIssuer || DEFAULT_HIDE_ISSUER;
  const hideStatus = props.hideStatus || DEFAULT_HIDE_STATUS;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideType = props.hideType || DEFAULT_HIDE_TYPE;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;
  const navigate = useNavigate();
  const admin = props.admin || false;
  const disableOnClick = props.disableOnClick || false;

  const [anchorElement, setAnchorElement] = useState(null);
  console.log(presentationRequest)
  const prOrVp = presentationRequest.decoded.pr || presentationRequest.decoded.vp;
  const name = presentationRequest.decoded.cdiName;
  const issuer = presentationRequest.decoded.issuerName
    ? presentationRequest.decoded.issuerName.businessName
    : presentationRequest.decoded.iss;
    console.log(name)
    console.log(issuer)

  let customer = '';
  if (Array.isArray(presentationRequest.decoded.customers) && presentationRequest.decoded.customers.length > 0) {
    customer = presentationRequest.decoded.customers[0].customer.businessName;
  }

  const endDate = presentationRequest.decoded.exp
    ? new Date(presentationRequest.decoded.exp * 1000).toLocaleDateString(undefined, { dateStyle: 'medium' })
    : 'N/A';
    
  const status = presentationRequest.storedJwt.status;
  const signedToken = presentationRequest.storedJwt.signedToken;
  let type = '';
  if (presentationRequest.decoded.pr) {
   type = (presentationRequest.decoded.pr.type.includes('Form') ) ? 'Form' : 'Delegated';
  } else if (presentationRequest.decoded.vp) {
    type = (presentationRequest.decoded.vp.type.includes('Form') ) ? 'Form' : 'Delegated';
  }

  let onClick = () => {};
  let cursor = 'unset';

  if (!disableOnClick) {
    onClick = () => (admin) ? navigate(`${PAGE_ROUTES.AdminPresentationRequestHistory}/${presentationRequest.decoded.cdi}`) : navigate(`${PAGE_ROUTES.EntityPresentationRequests}/${presentationRequest.decoded.cdi}`)
    cursor = 'pointer';
  }

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA', cursor: cursor }} onClick={onClick}>
      <Hider isHidden={hideName}>
        <TableCell>{name}</TableCell>
      </Hider>
      <Hider isHidden={hideIssuer}>
        <TableCell>{issuer}</TableCell>
      </Hider>
      <Hider isHidden={!hideIssuer}>
        <TableCell>{customer}</TableCell>
      </Hider>
      <Hider isHidden={hideType}>
        <TableCell>{i18nTranslate(type)}</TableCell>
      </Hider>
      <TableCell color='#666666'>{endDate}</TableCell>
      <Hider isHidden={hideStatus}>
        <TableCell useTypography={false}>
          <Status translate={i18nTranslate} keyword={translatePrefix} status={status} />
        </TableCell>
      </Hider>
      <Hider isHidden={hideActions}>
        <TableCell>
          <IconButton onClick={(event) => {setAnchorElement(event.currentTarget); event.stopPropagation();}} >
            <MoreHoriz />
          </IconButton>
          <PresentationRequestButtons
            translate={i18nTranslate}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            hideSignButton={hideSignButton}
            jti={presentationRequest.storedJwt.jti}
            signedToken={signedToken}
            presentationRequest={presentationRequest}
          />
        </TableCell>
      </Hider>
    </TableRow>
  );
}
