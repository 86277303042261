import { ChevronRight, ExpandMore } from '@mui/icons-material';
import Hider from '../Hider/Hider.component';
import { Checkbox, Grid, IconButton } from '@mui/material';
import { useState } from 'react';

const DEFAULT_OBJECT_WITH_CHILDREN = {
  code: '',
  name: '',
  children: []
};
const DEFAULT_SELECTED_VALUES = [];
const DEFAULT_CHECKBOX_CHANGE = (object, checkBoxValue) => {};

export default function TextDropdown(props) {
  const object = props.object || DEFAULT_OBJECT_WITH_CHILDREN;
  const selectedValues = props.selectedValues || DEFAULT_SELECTED_VALUES;
  const checkBoxChange = props.checkBoxChange || DEFAULT_CHECKBOX_CHANGE;

  const [open, setOpen] = useState(false);

  const hasChildren = object.children && object.children.length > 0;
  let children = [];
  let indeterminate = false;

  const handleChildChanged = (jurisdictionList, checked) => {
    if (!checked) {
      if (
        object.children.every(
          (jurisdiction) => jurisdictionList.includes(jurisdiction) || !selectedValues.includes(jurisdiction)
        )
      ) {
        jurisdictionList.push(object);
      }
    } else {
      jurisdictionList.push(object);
    }

    checkBoxChange(jurisdictionList, checked);
  };

  if (object.children) {
    const indeterminateRecursiveCheck = (object) => {
      if (object.children && object.children.length > 0) {
        for (const child of object.children) {
          const indeterminate = indeterminateRecursiveCheck(child);

          if (indeterminate === true) return indeterminate;
        }
      }

      return !selectedValues.includes(object);
    };

    for (const child of object.children) {
      children.push(
        <TextDropdown
          key={child.code}
          object={child}
          selectedValues={selectedValues}
          checkBoxChange={handleChildChanged}
        />
      );

      const anyChildrenIndeterminate = indeterminateRecursiveCheck(child);

      if (anyChildrenIndeterminate) {
        indeterminate = true;
      }
    }
  }

  const ArrowComponent = open ? ExpandMore : ChevronRight;

  return (
    <Grid container direction='column'>
      <Grid item='true'>
        <Grid container direction='row' alignItems='center' justifyContent='left'>
          <Grid item='true'>
            <IconButton
              size='small'
              disabled={!hasChildren}
              sx={{ opacity: hasChildren ? '1' : '0' }}
              onClick={() => setOpen(!open)}>
              <ArrowComponent />
            </IconButton>
          </Grid>
          <Grid item='true'>
            <Checkbox
              checked={selectedValues.includes(object)}
              indeterminate={indeterminate && selectedValues.includes(object)}
              onChange={(e) => {
                const recursiveChange = (object) => {
                  const list = [];

                  if (object.children && object.children.length > 0) {
                    for (const child of object.children) {
                      list.push(...recursiveChange(child));
                    }
                  }

                  list.push(object);

                  return list;
                };

                const toChange = recursiveChange(object);
                checkBoxChange(toChange, e.target.checked);
              }}
            />
          </Grid>
          <Grid item='true'>{object.name}</Grid>
        </Grid>
      </Grid>
      <Hider isHidden={!open}>
        <Grid item='true' paddingLeft={5}>
          <Grid container direction='column'>
            {children}
          </Grid>
        </Grid>
      </Hider>
    </Grid>
  );
}
