import React from 'react'
import { Fragment, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../../store/reducers/customers';

import {
  clearEntityPresentationRequestsCache,
  fetchEntityPresentationRequestsAsync,
  selectEntityPresentationRequests,
  selectEntityPresentationRequestsCount,
  selectEntityPresentationRequestsPage,
  selectEntityPresentationRequestsState
} from '../../../../store/reducers/entityPresentationRequests';

import PresentationFilters from '../../../../components/private/Filters/SearchFilter.component';

import ViewsLayout from '../../MainView.layout';
import DigitalSignatureSignRequestsTable from '#privateComponents/Tables/DigitalSignatureSignRequestsTable.component.js';

export default function SignRequests (props) {
 
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const [page, setPage] = useState(useSelector(selectEntityPresentationRequestsPage));
  const [searchCdiName, setSearchCdiName] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);

  let presentations = useSelector(selectEntityPresentationRequests);
  const presentationState = useSelector(selectEntityPresentationRequestsState);
  const presentationCount = useSelector(selectEntityPresentationRequestsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const isLoading = presentationState === REDUCER_STATUS.LOADING;
  const needsReload = presentationState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  // const signRequestMockData = 

  // if (usersNeedsReload) {
  //   dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  // }

  // if (needsReload) {
  //   dispatch(
  //     fetchEntityPresentationRequestsAsync({
  //       page,
  //       searchCdiName,
  //       issuer: customer ? customer.did : undefined,
  //       //status: status ? status.status : undefined
  //     })
  //   );
  // }

  // const onFilterSubmit = () => {
  //   setPage(0);
  //   dispatch(clearEntityPresentationRequestsCache());
  // };

  return (
    <ViewsLayout pageTitle={translate('digital-signature-forms-sign-requests-title')} layoutSize={layoutSize}>
      <Grid item='true'>
        <PresentationFilters
          translate={translate}
          // users={customers}
          // isRequest={false}
          // isLoading={isLoadingUsers}
          // search={searchCdiName}
          // setSearch={setSearchCdiName}
          // customer={customer}
          // setCustomer={setCustomer}
          // status={status}
          // setStatus={setStatus}
          // onFilterSubmit={onFilterSubmit}
          // refresh={onFilterSubmit}
        />
      <DigitalSignatureSignRequestsTable
        translate={translate}
        isLoading={isLoading}
        presentationRequests={(status) ? presentations.filter((pr) => pr.storedJwt.status === status.status) : presentations}
        presentationCount={presentationCount}
        page={page}
        setPage={setPage}
        buttonComponent={Fragment}
        hideActions={false}
      />
      </Grid>
    </ViewsLayout>
  );
}
