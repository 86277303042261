// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useLinkClickHandler, useNavigate } from 'react-router-dom';
import { post } from '../../../helpers/axios.helper';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';


import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';

// ------FILE MODULES---------------------------------------------------------
import { PAGE_ROUTES } from '#constants';

import TableCell from '../../shared/Tables/TableCell.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_TRANSLATE_PREFIX = 'notification-table';
const DEFAULT_NOTIFICATION = {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function NotificationRow(props) {
  const i18nTranslate = props.translate || DEFAULT_TRANSLATE;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;
  const notification = props.notification || DEFAULT_NOTIFICATION;

  let notificationImage = <img src={notification.src} alt='icon' width='24px' />
  let notificationIconDelete ;
  const to = notification.url || PAGE_ROUTES.NotificationInbox;
  const isRead = notification.isRead;
  const data = JSON.parse(notification.data);
  const title = data.title;
  const type = data.type;
  const name = data.name;
  const by = data.by;
  const createdAt = notification.createdAt;

  const navigate = useNavigate();

  const createdAtDate = createdAt ? new Date(createdAt).toLocaleDateString(undefined, { dateStyle: 'short' }) : 'N/A';
  const createdAtTime = createdAt ? new Date(createdAt).toLocaleTimeString(undefined, { timeStyle: 'short' }) : 'N/A';
  
  if (!notification.src) {
    notificationImage = <NotificationsIcon sx={{ opacity: isRead ? 0.5 : 1 }} />
    notificationIconDelete = <DeleteIcon onClick={() => handleDelete(notification.notificationId)} sx={{ cursor: 'pointer' }} />;
  }

  const translate = (string) => {
    return i18nTranslate(`${translatePrefix}-${string}`);
  };

  const handleDelete = (id) => {
    post(`/notifications/${id}`, { ...notification, isDeleted: true });
    window.location.reload();
  };

  const handleMarkAsRead = async (id) => {
    await post(`/notifications/${id}`, { ...notification, isRead: true });
    navigate(to, { replace: true });
  };


  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA', opacity: isRead ? 0.5 : 1}} >
      <TableCell useTypography={false} >
        <Link
          onClick={() => handleMarkAsRead(notification.notificationId)}
          href={to}
          sx={{
            textDecoration: 'none',
            'a:visited': {
              textDecoration: 'none'
            }
          }}>
          <Grid container spacing={2}>
            <Grid item='true'>
              {notificationImage}
            </Grid>            
            <Grid item='true'>
              <Grid container direction='column' spacing={1}>
                <Grid item='true'>
                  <Typography fontWeight='800' sx={{  opacity: isRead ? 0.5 : 1 }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item='true'>
                  <Grid container spacing={0.75}>
                    <Grid item='true'>
                      <Typography sx={{color: '#8F8F8F' , opacity: isRead ? 0.5 : 1}} >{translate(`${type}`)}</Typography>
                    </Grid>
                    <Grid item='true'>
                      <Typography>{name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item='true'>
                  <Grid container spacing={0.75}>
                    <Grid item='true'>
                      <Typography sx={{color: '#8F8F8F', opacity: isRead ? 0.5 : 1}} >{translate(`${type}-action`)}</Typography>
                    </Grid>
                    <Grid item='true'>
                      <Typography sx={{  opacity: isRead ? 0.5 : 1 }} >{by}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </TableCell>
      <TableCell useTypography={false}>
          <Grid container direction='column' alignItems='flex-end' alignContent='flex-end'> 
            <Grid item='true'>
              <Typography sx={{  opacity: isRead ? 0.5 : 1 }}>
                {createdAtDate}
              </Typography>
            </Grid>
            <Grid item='true' >
              <Typography sx={{  opacity: isRead ? 0.5 : 1 }}>
                {createdAtTime}
              </Typography>
            </Grid>
            <Grid item='true' sx={{ mt: 2 }}>
              {notificationIconDelete}
            </Grid> 
          </Grid>
      </TableCell>
    </TableRow>
  );
}
