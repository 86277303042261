// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchCorporateAsync, selectWhoAmI, selectWhoAmIStatus } from '../../../../../store/reducers/corporate';

import { useParams } from 'react-router-dom';

import { groupBy } from 'lodash';

// ------ FILE MODULES ---------------------------------------------------------
import { ROW_COUNT, REDUCER_STATUS } from '#constants';

import PresentationRequestVisualizeTable from '../../../../../components/private/Tables/PresentationRequestVisualizeTable.component';
import PresentationRequestTable from '../../../../../components/private/Tables/PresentationRequestTable.component';
import SendPresentationPopup from '../../../../../components/private/Popups/SendPresentationPopup.component'
import VisualizerLayout from '../../../../../layouts/Visualizer/Visualizer.layout';

import services from '../../../../../services';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function getPr(cdi, setPr, setIsPrLoading) {
  setIsPrLoading(true);
  let prs = await services.orchestrator.getPresentationRequestEntity(cdi);
  console.log(prs);

  const groupedById = groupBy(prs, pr => pr.decoded.pr.procId);
  const result = Object.values(groupedById);
  prs = result.map((cdiPrs) => {
    var mostRecentCreatedAt = new Date(Math.max.apply(null, cdiPrs.map( e => {
      return new Date(e.storedJwt.createdAt);
   })));
   return cdiPrs.filter( e => { 
       var d = new Date( e.storedJwt.createdAt ); 
       return d.getTime() === mostRecentCreatedAt.getTime();
   })[0];
  });
  console.log(prs);
  setPr(prs);
  setIsPrLoading(false);
}

function parsePresentation(obj) {
  console.log(obj)
  if(obj.decoded.vc) {
    return obj;
  } else {
    console.log(obj);
    obj.decoded.vp.verifiableCredential.map((pres) => {
      console.log(pres);
      return parsePresentation(pres); })
  };
}


function parsePresentation2(obj) {
  console.log(obj)
  if(obj.decoded.vp.verifiableCredential[0].decoded.vc) {
    return obj;
  } else {
    parsePresentation2(obj);
  };
}

async function getPresentations(cdi, setIsPRFLoading, setPresentation, whoAmI) {
  setIsPRFLoading(true);
  let presentations = await services.orchestrator.getPresentationByCdi(cdi);
  // presentations.map(async (presentation) => {
  //   return parsePresentation(presentation);
  // });
  console.log(presentations);
  console.log(whoAmI);
  let a = []
  presentations.map((presentation) => {
    if(presentation.decoded.vp.verifiableCredential[0].decoded.vc) {
      a.push(presentation);
    } else {
      if(presentation.decoded.iss !== whoAmI.did) {
      presentation.decoded.vp.verifiableCredential.map((vp) => {
        if (vp.decoded.vp.verifiableCredential[0].decoded.vc) {
          a.push(vp);
        }
      })
    }}
  });
  
  console.log(a);
  presentations = a.filter((presentation) => presentation.decoded.vp.verifiableCredential[0].decoded !== undefined);
  setPresentation(presentations);
  setIsPRFLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PRVisualizer(props) {
  const translate = props.translator;

  const whoAmI = useSelector(selectWhoAmI);
  const whoAmIStatus = useSelector(selectWhoAmIStatus);

  const whoAmINeedsRefresh = whoAmIStatus === REDUCER_STATUS.NEEDS_REFRESH;
  const whoAmIIsReady = whoAmIStatus === REDUCER_STATUS.READY;

  const dispatch = useDispatch();

  const pathParams = useParams();
  const cdi = pathParams.cdi;

  const [pr, setPr] = useState([]);
  const [presentations, setPresentation] = useState([]);
  const [isPrLoading, setIsPrLoading] = useState(undefined);
  const [isPRFLoading, setIsPRFLoading] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_COUNT[0]);
  const [isOpen, setIsOpen] = useState(false);


  if (whoAmINeedsRefresh) {
    dispatch(fetchCorporateAsync());
  }

  if (isPrLoading === undefined) {
    getPr(cdi,  setPr, setIsPrLoading, setPresentation);
  }

  if (isPRFLoading === undefined) {
    if(whoAmI) {
      getPresentations(cdi, setIsPRFLoading, setPresentation, whoAmI);
    }
  }

  // if (isPrtLoading === undefined) {
  //   //getPrtData(prtId, setPrt, setIsPrtLoading);
  // }

  // const credentials = JSON.parse(prt.data).credentials;
  // let credentialsPaginated = [];
  // let credentialCount = 0;

  // if (credentials) {
  //   credentialCount = credentials.length;
  //   credentialsPaginated = [...credentials].splice(page * rowsPerPage, rowsPerPage);
  // }
  console.log(pr)
  console.log(presentations);
  console.log( pr.some((obj) => obj.storedJwt.status === 'Accepted'))
  return (
    <VisualizerLayout
      pageTitle={translate('prt-details-page-title')}
      primaryTableComponent={PresentationRequestTable}
      primaryTableProps={{
        translate: translate,
        isLoading: isPrLoading,
        hidePagination: true,
        presentationRequests: pr,
        openPopupInstead: true,
        hideIssuer: false,
        hideStatus: false,
        hideActions: true,
        disableOnClick: true
      }}
      secondaryTableTitle={translate('prt-details-page-title-forms')}
      secondaryTableComponent={PresentationRequestVisualizeTable}
      secondaryTableProps={{
        translate: translate,
        PresentationRequests: pr,
        presentations: presentations,
        isLoading: isPRFLoading,
        setIsPrLoading: setIsPRFLoading,
        page,
        rowsPerPage,
        hideAdminActions: true
      }}
      buttons={[
        {
          text: translate('presentation-request-details-page-sign-request-button'),
          onClick: () => {
            setIsOpen(true);
          },
          isDisabled: pr.decoded || pr.some((obj) => obj.storedJwt.status === 'Accepted')
        }
      ]}>
      <SendPresentationPopup
        translate={translate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        cdi={cdi}
        presentations={presentations}
        presentationRequest={pr.find((pr) => pr.decoded.iss !== whoAmI.did)}
        formSent={() => {
          setIsPrLoading(undefined);
        }}
      />
    </VisualizerLayout>
  );
}
