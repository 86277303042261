// WEB VITALS
import reportWebVitals from './reportWebVitals';

// INTERNATIONALIZATION
import i18n from './i18n';

// CSS
import './App.css';

// THEME
import * as Themes from '../config/themes';
import { ThemeProvider } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

// STORE
import store from '../store';
import { Provider } from 'react-redux';

// HOOKS
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// CONFIG
import ErrorPopup from '../components/shared/DialogBoxes/ErrorPopup.component'
import DataRouter from './DataRouter';
import config from '#config';

function App() {
  const [error, setError] = useState(false);
  const translator = useTranslation();
  const translate = translator.t;

  const [theme, setTheme] = useState(/*Themes.sybolTheme*/Themes[config.INTERNAL.THEME]);

  // const xsOrS = useMediaQuery('(min-width:600px)');
  const m = useMediaQuery('(min-width:800px)');
  const lOrXl = useMediaQuery('(min-width:1400px)');

  const changeLanguage = () => {
    let lang = sessionStorage.getItem('lang') || i18n.language || window.localStorage.i18nextLng || navigator.language || navigator.userLanguage;

    if (lang.startsWith('en')) {
      lang = 'es'
    } else if (lang.startsWith('es')) {
      lang = 'en'
    }

    i18n.changeLanguage(lang);
    sessionStorage.setItem('lang', lang);
  };

  const layoutSizeSelector = () => {
    return lOrXl ? 'lOrXl' : m ? 'm' : 'xsOrS'
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* <ErrorPopup translate={translate} /> */}
        <DataRouter translator={translate} changeLanguage={changeLanguage} sidebarBackgroundColor={theme.palette.primary.main} textColor={theme.typography.h6.color} layoutSize={layoutSizeSelector()}/>
      </ThemeProvider>
    </Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;
