// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

// ------FILE MODULES---------------------------------------------------------
import PresentationRequestTemplateRow from "./PresentationRequestTemplateRow.component";
import PRTCredentialTable from "../Tables/PRTCredentialTable.component";
import Hider from "../../shared/Hider/Hider.component";
import TableCell from '@mui/material/TableCell';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => { return string };
const DEFAULT_PRESENTATION_REQUEST_TEMPLATE = {};
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_HIDE_FILE = false;
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_PRESENTATION_REQUEST_TEMPLATES = [];

const DEFAULT_MAX_NAME_LENGTH = 50;
const DEFAULT_COLLAPSED_NAME_PADDING = 5;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CollapsiblePRTRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const presentationRequestTemplate = props.presentationRequestTemplate || DEFAULT_PRESENTATION_REQUEST_TEMPLATE;
  const openPopupInstead = props.openPopupInstead || DEFAULT_OPEN_POPUP_INSTEAD;
  const hideFile = props.hideFile || DEFAULT_HIDE_FILE;
  const selectedPresentationRequestTemplates = props.selectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const setSelectedPresentationRequestTemplates = props.setSelectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;

  const [open, setOpen] = useState(false);
  function collapsedRows() {
    return (
      <TableCell colSpan={6}>
      <PRTCredentialTable
        translate={translate}
        catalog={JSON.parse(presentationRequestTemplate.data).credentials}
        catalogCount={JSON.parse(presentationRequestTemplate.data).credentials.length}
        hidePagination={true}
        maxNameLength={70}
        maxIssuerLength={70}
        maxHolderLength={70}
      />
      </TableCell>
    );
  }

  return (
    <>
      <PresentationRequestTemplateRow
        key={presentationRequestTemplate.prtId}
        translate={translate}
        setErrorPopup={setErrorPopup}
        presentationRequestTemplate={presentationRequestTemplate}
        openPopupInstead={openPopupInstead}
        hideFile={hideFile}
        hideActions={true}
        hideDelete={false}
        open={open}
        setOpen={setOpen}
        hideCollapsible={false}
        selectedPresentationRequestTemplates={selectedPresentationRequestTemplates}
        setSelectedPresentationRequestTemplates={setSelectedPresentationRequestTemplates}
        maxLengthDescription={30}
      />
      <Hider isHidden={!open}>
        {collapsedRows()}
      </Hider>
    </>
  )
}
