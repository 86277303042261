// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import MoreVert from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

// ------FILE MODULES---------------------------------------------------------

import TableCell from '../../shared/Tables/TableCell.component';
import PRTButtons from '../Dropdowns/PRTButtons';
import Hider from '../../shared/Hider/Hider.component';
import { Link } from '@mui/material';
import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import { MAX_LENGTH } from '#constants';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_HIDE_FILE = false;
const DEFAULT_PRESENTATION_REQUEST_TEMPLATE = { data: {} };
const DEFAULT_OPEN = false;
const DEFAULT_SET_OPEN = () => { };
const DEFAULT_HIDE_COLLAPSIBLE = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_DELETE = false;
const DEFAULT_PRESENTATION_REQUEST_TEMPLATES = [];
const DEFAULT_MAX_LENGTH_DESCRIPTION = MAX_LENGTH[0];


// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTemplateRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const openPopupInstead = props.openPopupInstead || DEFAULT_OPEN_POPUP_INSTEAD;
  const hideFile = props.hideFile || DEFAULT_HIDE_FILE;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideDelete = props.hideDelete || DEFAULT_HIDE_DELETE;
  const presentationRequestTemplate = props.presentationRequestTemplate || DEFAULT_PRESENTATION_REQUEST_TEMPLATE;
  const open = props.open || DEFAULT_OPEN;
  const setOpen = props.setOpen || DEFAULT_SET_OPEN;
  const hideCollapsible = props.hideCollapsible || DEFAULT_HIDE_COLLAPSIBLE;
  const selectedPresentationRequestTemplates = props.selectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const setSelectedPresentationRequestTemplates = props.setSelectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const  maxLengthDescription = props.maxLengthDescription || DEFAULT_MAX_LENGTH_DESCRIPTION;
  const [anchorElement, setAnchorElement] = useState(null);
  console.log(presentationRequestTemplate)
  const jti = presentationRequestTemplate.prtId;
  const data = JSON.parse(presentationRequestTemplate.data);
  const createdAt = presentationRequestTemplate.createdAt;
  console.log(createdAt);
  const name = data.title;
  const purpose = data.description;
  const creationDate = createdAt ? new Date(createdAt).toLocaleDateString(undefined, { dateStyle: 'medium' }) : 'N/A';
  const fileUrl = data.fileUrl;

  const deleteRow = (jti) => {
    setSelectedPresentationRequestTemplates(selectedPresentationRequestTemplates.filter((prt) => prt.prtId !== jti));
  };

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <Hider isHidden={hideCollapsible}>
        <TableCell useTypography={false}>
          <Grid container direction="row" alignItems="center">
            <Grid item='true'>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <Grid container direction="row" alignItems="center">
          <Grid item='true'>
            <MaxLengthText maxLength={MAX_LENGTH[0]} text={name}></MaxLengthText>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText maxLength={maxLengthDescription} text={purpose}></MaxLengthText>
      </TableCell>
      <TableCell>{creationDate}</TableCell>
      <Hider isHidden={hideFile}>
        <TableCell useTypography={false}>
          <Link
            sx={{
              fontSize: '0.938rem',
              fontWeight: '400',
              textDecoration: 'none',
              color: '#666666',
              'a:visited': {
                textDecoration: 'none',
                color: '#666666'
              }
            }}
            target='_blank'
            href={fileUrl}>
            {translate('prt-table-download-document')}
          </Link>
        </TableCell>
      </Hider>
      <Hider isHidden={hideActions}>
        <TableCell useTypography={false}>
          <IconButton
            onClick={(e) => {
              setAnchorElement(e.target);
            }}>
            <MoreVert />
          </IconButton>
          <PRTButtons
            translate={translate}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            openPopupInstead={openPopupInstead}
            jti={jti}
            presentationRequestTemplate={presentationRequestTemplate}
          />
        </TableCell>
      </Hider>
      <Hider isHidden={hideDelete}>
        <TableCell useTypography={false}>
          <IconButton
            onClick={() => {
              deleteRow(jti);
            }}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </Hider>
    </TableRow>
  );
}
