// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH, ROW_COUNT } from '#constants';

import PresentationRow from '../Rows/PresentationRow.component'
import LOATooltip from '../Tooltips/LOATooltip.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './PRTCredentialTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_PRESENTATION = {};
const DEFAULT_PRESENTATION_REQUEST = {};
const DEFAULT_IS_LOADING = false;
const DEFAULT_CATALOG_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[1];
const DEFAULT_MAX_ISSUER_LENGTH = MAX_LENGTH[2];
const DEFAULT_MAX_HOLDER_LENGTH = MAX_LENGTH[2];

function getHeaders(translate) {
  return [
    { text: 'prt-credential-table-credential-name' },
    {
      text: 'prt-credential-table-loa',
      component: <LOATooltip translate={translate} />
    },
    { text: 'owner-table' },
    { text: 'value-table' }
  ]
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const presentation = props.presentation || DEFAULT_PRESENTATION;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxIssuerLength = props.maxIssuerLength || DEFAULT_MAX_ISSUER_LENGTH;
  const presentationRequest = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;

  const headers = getHeaders(translate);

  const spinnerColSpan = headers.length;
  console.log(presentationRequest);
  console.log(presentation);
  return (
    <Grid item='true'>
      <TableContainer className='prt-credential-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('prt-credential-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {presentationRequest.decoded.pr.data.map((catalogEntry) => {
                return (
                  <PresentationRow
                    key={catalogEntry.jti}
                    translate={translate}
                    catalogEntry={catalogEntry}
                    maxNameLength={maxNameLength}
                    credential={presentation.decoded.vp.verifiableCredential.find((credential) => /*(credential.decoded.vc.catalog.name === catalogEntry.name && credential.decoded.vc.catalog.jurisdiction === catalogEntry.jurisdiction) || */(credential.decoded.vc.type[1] === catalogEntry.name))}
                  />
                );
              })}
            </SpinnerLoader>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
