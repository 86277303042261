import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2';
import React from 'react'
import Slide from '../../../../components/shared/Slides/Slide.component'
import benefitConfig from './benefits.config'
import InfoCard from '../../../../components/shared/Cards/InfoCard.component'
import './Benefits.slide.css'

const titleProps = {
  variant: 'h5',
  fontSize: '2em',
  lineHeight: '5.22vh',
  fontWeight: 'normal'
}

const subtitleProps = {
  variant: 'h4',
  fontSize: '4.3vh',
  lineHeight: '5.22vh',
  fontWeight: 'bold'
}

const descriptionProps = {
  variant: 'body',
  fontSize: '1.3em',
  lineHeight: '2.1vh',
  color: 'black'
}

const cardProps = {
  spacing: 2,
  titleProps,
  subtitleProps,
  descriptionProps
}

function title(translate) {
  return (
    <Grid container direction='column' spacing={8}>
      <Grid item='true'>
        <Typography variant='h4' fontWeight='bold' fontSize='3.3vw' lineHeight='5.22vh'>
          {translate('benefits-title')}
        </Typography>
      </Grid>
      <Grid item='true'>
        <Typography variant='h5' className='benefit-description' fontWeight='bold' fontSize='3.3vh' lineHeight='3.5vh'>
          {translate('benefits-description')}
        </Typography>
      </Grid>
    </Grid>
  )
}

function content(translate) {
  return (
    <Grid container spacing={3} direction='row'>
      {benefitConfig.map((element, index) => {
        return (
          <InfoCard key={index}
            identifier={'0' + (index + 1)}
            image={element.image}
            title={translate(element.title)}
            description={translate(element.description)}
            {...cardProps}
          />
        )
      })}
    </Grid>
  )
}

const benefits = { name: 'benefits', title, content }
export default function Benefits(props) {
  benefits.translator = props.translator;
  benefits.layoutSize = props.layoutSize;

  return (
      <Slide {...benefits} />
  );
}