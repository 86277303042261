import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPRTAsync = createAsyncThunk(
  'presentationRequestTemplates/fetchPRT',
  async ({ page, rowsPerPage, search, createDate }) => {
    // count should be made user configurable.
    const limit = rowsPerPage === undefined ? initialState.rowsPerPage : rowsPerPage;
    const form = new URLSearchParams();
    let endpoint = '/dit/presentationRequestTemplate';
    let didFilter = 'issuer';

    let prts = [];
    let count = 0;
    const whoAmIResponse = await get('/corporate');

    if (isOk(whoAmIResponse.status)) {
      const whoAmI = getResponseJson(whoAmIResponse);
      form.append(didFilter, whoAmI.did);
      form.append('offset', limit * page);
      form.append('limit', limit);
      form.append('isDeleted', false);

      if (search) {
        form.append('search', search);
      }

      if (createDate) {
        const createdAt = new Date(createDate * 1000);
        form.append('createdAt', `${createdAt.getUTCFullYear()}-${createdAt.getUTCMonth() + 1}-${createdAt.getUTCDate()}`);
      }

      const response = await get(endpoint, form);

      if (isOk(response.status)) {
        prts = getResponseJson(response);
        count = Number(response.headers['x-app-total-count']) || 0;
      }
    }

    return { page, rowsPerPage, count, value: prts };
  }
);

export const presentationRequestTemplatesSlice = createSlice({
  name: 'presentationRequestTemplates',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearPresentationRequestTemplatesCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPRTAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchPRTAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearPresentationRequestTemplatesCache } = presentationRequestTemplatesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectPresentationRequestTemplates = (state) => state.presentationRequestTemplates.value;
export const selectPresentationRequestTemplatesState = (state) => state.presentationRequestTemplates.status;
export const selectPresentationRequestTemplatesPage = (state) => state.presentationRequestTemplates.page;
export const selectPresentationRequestTemplatesRowsPerPage = (state) => state.presentationRequestTemplates.rowsPerPage;
export const selectPresentationRequestTemplatesCount = (state) => state.presentationRequestTemplates.count;

export default presentationRequestTemplatesSlice.reducer;
