import { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { decodeJwt } from 'jose';

import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Apartment from '@mui/icons-material/Apartment';

import { fetchCorporateAsync, selectWhoAmI, selectWhoAmIStatus } from '../../../store/reducers/corporate';
import { PAGE_ROUTES, REDUCER_STATUS, USER_NAME_SOURCES } from '#constants';

import UserButtons from '../Dropdowns/UserButtons';

import ChevronRightIcon from '../../shared/Icons/ChevronRightIcon.component';
import ChevronDownIcon from '../../shared/Icons/ChevronDownIcon.component';

import './Header.component.css';
import config from '#config';
import { selectAccessToken } from '../../../store/reducers/session';

function CurrentPath(props) {
  const translate = props.translate;

  const [currentLocation, setCurrentLocation] = useState(window.location.pathname);

  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  let pathBrokenDown = [];

  switch (currentLocation) {
    case PAGE_ROUTES.NotificationInbox:
      pathBrokenDown = [translate('link-to-notification-inbox')];
      break;
    case PAGE_ROUTES.Dashboard:
      pathBrokenDown = [translate('sidebar-digital-identity'), translate('link-to-dashboard')];
      break;
    case PAGE_ROUTES.EntityCredentials:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-my-identity'),
        translate('link-to-my-credentials')
      ];
      break;
    case PAGE_ROUTES.EntityCredentialRequests:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-my-identity'),
        translate('link-to-my-credential-requests')
      ];
      break;
    case PAGE_ROUTES.EntityPresentations:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-my-identity'),
        translate('link-to-my-presentations')
      ];
      break;
    case PAGE_ROUTES.EntityPresentationRequests:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-my-identity'),
        translate('link-to-my-presentation-requests')
      ];
      break;
    case PAGE_ROUTES.AdminCredentials:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-issuance'),
        translate('link-to-issued-credentials')
      ];
      break;
    case PAGE_ROUTES.AdminCredentialRequests:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-issuance'),
        translate('link-to-received-requests')
      ];
      break;
    case PAGE_ROUTES.AdminPresentationRequestHistory:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-issuance'),
        translate('link-to-requests-history')
      ];
      break;
    case PAGE_ROUTES.AdminPresentationRequestTemplates:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-issuance'),
        translate('link-to-requests-library')
      ];
      break;
    case PAGE_ROUTES.ConctactsCorporate:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-contacts'),
        translate('link-to-corporate-contacts')
      ];
      break;
    case PAGE_ROUTES.ContactsIndividuals:
      pathBrokenDown = [
        translate('sidebar-digital-identity'),
        translate('sidebar-contacts'),
        translate('link-to-individual-contacts')
      ];
      break;
    default:
      if (currentLocation.startsWith(PAGE_ROUTES.AdminPresentationRequestTemplates)) {
        pathBrokenDown = [
          translate('sidebar-digital-identity'),
          translate('sidebar-issuance'),
          translate('link-to-requests-library'),
          translate('prt-details-page-title')
        ];
      } else {
        pathBrokenDown = [];
      }
      break;
  }

  return (
    <Grid container alignItems='center'>
      {pathBrokenDown.map((path, index) => {
        let color = '#666666';
        let rightArrow = <Fragment />;

        if (index !== pathBrokenDown.length - 1) {
          color = '#662D91';
          rightArrow = (
            <Grid item='true' display='flex' alignItems='center'>
              <ChevronRightIcon />
            </Grid>
          );
        }
 
        return (
          <Fragment key={index}>
            <Grid item='true'>
              <Typography sx={{ color, fontSize: '1rem' }} >{path}</Typography>
            </Grid>
            {rightArrow}
          </Fragment>
        );
      })}
    </Grid>
  );
}

export default function Header(props) {
  const translate = props.translator;
  const sidebarBackgroundColor = props.sidebarBackgroundColor;
  const layoutSize = props.layoutSize;
  const changeLanguage = props.changeLanguage;

  const accessToken = useSelector(selectAccessToken);
  const whoAmI = useSelector(selectWhoAmI);
  const whoAmIStatus = useSelector(selectWhoAmIStatus);

  const dispatch = useDispatch();

  const whoAmINeedsRefresh = whoAmIStatus === REDUCER_STATUS.NEEDS_REFRESH;
  const whoAmIIsReady = whoAmIStatus === REDUCER_STATUS.READY;

  if (whoAmINeedsRefresh) {
    dispatch(fetchCorporateAsync());
  }

  let userName = '?';
  if (whoAmIIsReady) {
    window.document.title = config.INTERNAL.TAB_TITLE;

    if (whoAmI) {
      window.document.title = whoAmI.businessName;
    }

    if (config.INTERNAL.USER_NAME_SOURCE === USER_NAME_SOURCES.OAUTH_TOKEN && accessToken) {
      const accessTokenJson = decodeJwt(accessToken);
      userName = accessTokenJson.name || accessTokenJson.given_name || accessTokenJson.preferred_username || '?';
    } else if (whoAmI) {
      userName = whoAmI.businessName;
    }
  }

  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <Grid
      id={'header-component-mainlayout'}
      className='header'
      container
      direction='row'
      alignItems='center'>
      {layoutSize !== 'xsOrS' && <Grid item='true' size={{ s: 0, m: 8, l: 8}}>
        <CurrentPath translate={translate} />
      </Grid>}

      <Grid container size={{ s: 12, m: 4, l: 4}} justifyContent={'flex-end'} style={{'&:hover': {backgroundColor: 'yellow'}}} >
        <UserButtons translate={translate} anchorElement={anchorElement} setAnchorElement={setAnchorElement} changeLanguage={changeLanguage}/>
        <Button
          sx={{ textTransform: 'none', width: '250px', display: 'flex', minWidth: '250px', borderRadius: '5px'}}
          onClick={(e) => {
            setAnchorElement(e.target);
          }}>
              <div className='header-user-logo-background' style={{justifyContent: 'right', alignItems: 'center', backgroundColor: sidebarBackgroundColor}}>
                <Apartment fontSize='large' className='header-use-logo' style={{ color: 'white' }} />
              </div>
              <div style={{display: 'block', width: '180px', paddingLeft: '0.5em'}}>
                <Typography textAlign='left' fontWeight='bold' fontSize='0.938rem' sx={{ color: sidebarBackgroundColor }}>
                  {userName}
                </Typography>
                <Typography textAlign='left' fontWeight='light' fontSize='0.813rem' sx={{ color: '#666666' }}>
                  {translate('header-company-role')}
                </Typography>
              </div>
              <ChevronDownIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
