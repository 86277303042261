// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import TableCell from '../../shared/Tables/TableCell.component';
import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import Hider from '../../shared/Hider/Hider.component';
import Status from '../Status/Status.component';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CONTACT = {};
const DEFAULT_HIDE_TELEPHONE = false;
const DEFAULT_HIDE_CIF = false;
const DEFAULT_HIDE_ALIAS = false;
const DEFAULT_HIDE_STATUS = false;
const DEFAULT_HIDE_EMAIL = false;

const MAX_LENGTH = 40;
const MAX_DID_LENGTH = 25;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function ContactRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const contact = props.contact || DEFAULT_CONTACT;
  const hideCollapsible = props.hideCollapsible || false;
  const open = props.open || false;
  const setOpen = props.setOpen || false;
  const maxDidLength = props.maxDidLength || MAX_DID_LENGTH;
  const hideTelephone = props.hideTelephone || DEFAULT_HIDE_TELEPHONE;
  const hideCif = props.hideCif || DEFAULT_HIDE_CIF;
  const hideAlias = props.hideAlias || DEFAULT_HIDE_ALIAS;
  const hideStatus = props.hideStatus || DEFAULT_HIDE_STATUS;
  const hideEmail = props.hideEmail || DEFAULT_HIDE_EMAIL;

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <Hider isHidden={hideCollapsible}>
        <TableCell useTypography={false}>
          <Grid container direction="row" alignItems="center">
            <Grid item='true'>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <MaxLengthText text={contact.businessName} maxLength={MAX_LENGTH} />
      </TableCell>
      <Hider isHidden={hideAlias}>
        <TableCell>{contact.alias}</TableCell>
      </Hider>
      <Hider isHidden={hideEmail}>
        <TableCell useTypography={false}>
          <MaxLengthText text={contact.email} maxLength={MAX_LENGTH} typographyStyle={{ color: '#666666' }} />
        </TableCell>
      </Hider>
      <Hider isHidden={hideTelephone}>
        <TableCell>{contact.tel}</TableCell>
      </Hider>
      <Hider isHidden={hideCif}>
        <TableCell>{contact.cif}</TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <Hider isHidden={!contact.did}>
          <MaxLengthText text={contact.did} maxLength={maxDidLength} typographyStyle={{ color: '#666666' }} />
        </Hider>
      </TableCell>
      <Hider isHidden={hideStatus}>
        <TableCell useTypography={false}>
          <Status translate={translate} keyword={'contacts-table-row'} status={contact.status} />
        </TableCell>
      </Hider>
    </TableRow>
  );
}
