import routes from './routes.config';
import mockedData from './mockedData.config'

export const AVAILABLE_ALGS = [
  'HS256',
  'HS384',
  'HS512',
  'RS256',
  'RS384',
  'RS512',
  'ES256',
  'ES384',
  'ES512',
  'ES256K',
  'EdDSA',
  'none'
];
export const ALASTRIA_US = ['US12', 'US211', 'US221', 'US231', 'US142'];
export const W3C_CREDENTIAL_CONTEXT = 'https://www.w3.org/2018/credentials/v1.';
export const W3C_CREDENTIAL_BASIC_TYPE = 'VerifiableCredential';
export const W3C_PRESENTATION_BASIC_TYPE = 'VerifiablePresentation';
export const ALASTRIA_TOKEN_TYPE = 'AlastriaToken';
export const JWT_VEIA_CONTEXT = 'https://www.veia.es/2023/jwt/context/v1';
export const JWT_VEIA_SCHEMA = 'https://www.veia.es/2023/jwt/schema/v1';
export const TRUST_FRAMEWORKS = ['ala', 'veia'];
export const TECHNOLOGIES = ['besu', 'quor', 'fabric', 'geth'];
export const NETWORK_IDS = ['veia', 'local', 'redT', 'redB'];
export const SETTINGS_NETWORKS = [
  { networkId: NETWORK_IDS[2], networkTechnology: TECHNOLOGIES[1] },
  { networkId: NETWORK_IDS[3], networkTechnology: TECHNOLOGIES[0] }
];
export const VALID_ID = /^(?:0x)?[0-9A-Fa-f]{40}$/;

// Backend Constants
export const PLATFORM_TYPE = ['WWC', 'MOBILE'];
export const CUSTOMER_STATUS = ['STARTED', 'COMPLETED', 'INVALID'];
export const PRESENTATION_STATUS = ['Valid', 'Received', 'AskDeletion', 'DeletionConfirmation'];
export const PRESENTATION_REQUEST_CUSTOMER_STATUS = ['Pending', 'Accepted', 'Rejected'];
export const PRESENTATION_REQUEST_STATUS = ['Created', 'Deleted'];
export const CREDENTIAL_REQUEST_STATUS = ['Valid', 'Deleted', 'Resolved', 'Rejected'];
export const CREDENTIAL_STATUS = ['Valid', 'Expired', 'Revoked', 'DeletedBySubject'];

// Frontend Constants
export const CREDENTIAL_FILTER_STATUS = ['Revoked', 'Pending', 'Rejected', 'Expired', 'Valid'];
export const PAGE_ROUTES = routes;
export const MAX_LENGTH = [35, 25, 15, 10, 5];
export const ROW_COUNT = [25, 50, 100];
export const LEVELS_OF_ASSURANCE = [0, 1, 2, 3];
export const LEVELS_OF_ASSURANCE_SIMPLIFIED = [1, 2, 3];
export const REDUCER_STATUS = {
  READY: 0,
  NEEDS_REFRESH: 1,
  LOADING: 2
};

export const AUTH_SERVERS = { 
  SAFEGATE: {
    URIS: {
      LOGOUT: '/veia/v4/idm/public/self-service/logout/browser',
      LOGIN_BROWSER: '/veia/v4/idm/public/self-service/login/browser',
      LOGIN_FLOWS: '/veia/v4/idm/public/self-service/login/flows',
      LOGIN: '/veia/v4/idm/public/self-service/login'
    }
  }
};
export const PRESENTATION_TYPES = {
  FORM_TYPE: 'Form',
  DELEGATED_TYPE: 'Delegated'
};
export const USER_NAME_SOURCES = {
  OAUTH_TOKEN: 'OAUTH_TOKEN',
  WHO_AM_I: 'WHO_AM_I'
}

export const MOCKED_DATA = mockedData;