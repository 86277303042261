import { MAX_LENGTH } from '#constants';

import MaxLengthText from '../../shared/Typography/MaxLengthText.component';

import './Status.component.css';

const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_STATUS = 'UNKNOWN';
const DEFAULT_KEYWORD = '';

export default function Status(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const status = props.status || DEFAULT_STATUS;
  const keyword = props.keyword || DEFAULT_KEYWORD;

  const statusId = `status-${keyword}-${status.toLowerCase()}`;

  return (
    <div className={`container-${statusId}`}>
      <div className='holder'>
        <MaxLengthText
          text={translate(statusId)}
          maxLength={MAX_LENGTH[3]}
          fontSize='0.813rem'
          typographyStyle={{ color: 'inherit' }}
        />
      </div>
    </div>
  );
}
