// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useRef, useState } from 'react';

import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TEXT = '';
const DEFAULT_COPIED_TEXT = 'Copied!';
const DEFAULT_MAX_LENGTH = null;
const DEFAULT_FONT_SIZE = '0.938rem';
const DEFAULT_TYPOGRAPHY_STYLE = {
  color: '#662D91'
};
const DEFAULT_TYPOGRAPHY_PROPS = {};
const DEFAULT_TOOLTIP_PROPS = {
  arrow: true
};
const DEFAULT_LINK_STYLE = {
  textDecoration: 'none',
  'a:visited': {
    textDecoration: 'none'
  }
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function MaxLengthText(props) {
  const text = props.text || DEFAULT_TEXT;
  const copiedText = props.copiedText || DEFAULT_COPIED_TEXT;
  const maxLength = props.maxLength || DEFAULT_MAX_LENGTH;
  const fontSize = props.fontSize || DEFAULT_FONT_SIZE;
  // Doing the ... before an object deconstructs it so all its keys and values are assigned to the new object.
  // If there is key conflictsm the last use of the object deconstruction is the one that takes priority for the values.
  const typographyStyle = { ...DEFAULT_TYPOGRAPHY_STYLE, ...props.typographyStyle, fontSize };
  const typographyProps = { ...DEFAULT_TYPOGRAPHY_PROPS, ...props.typographyProps, sx: typographyStyle };

  const [title, setTitle] = useState(text);

  const tooltipProps = { ...DEFAULT_TOOLTIP_PROPS, ...props.tooltipProps, title: title };
  const linkStyle = { ...DEFAULT_LINK_STYLE, '&.MuiLink-root': { ...typographyStyle }, ...props.linkStyle };

  const timeOut = useRef(null);

  // If no max length was specified and we defaulted, we will simply return.
  // Otherwise, if we are under or equal to the max length, we will also simply return, as we do not need to truncate the text.
  if (maxLength === null || text.length <= maxLength) {
    return (
      // This is valid for JSX, as it will use all keys from the object as props.
      <Typography {...typographyProps}>{text}</Typography>
    );
  }

  return (
    <Tooltip {...tooltipProps}>
      <Link
        onClick={(e) => {
          e.preventDefault();

          navigator.clipboard.writeText(text);
          setTitle(copiedText);

          clearTimeout(timeOut.current);
          timeOut.current = setTimeout(() => {
            setTitle(text);
          }, 1000);
        }}
        sx={linkStyle}
        href='#'>
        {`${text.substring(0, maxLength)}...`}
      </Link>
    </Tooltip>
  );
}
