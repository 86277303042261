// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH, ROW_COUNT } from '#constants';

import CredentialRow from '../Rows/CredentialRow.component';

import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';
import EnttiyCredentialButtons from '../Dropdowns/EntityCredentialButtons';

import './CredentialTable.component.css';
import LOATooltip from '../Tooltips/LOATooltip.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
// const DEFAULT_CREDENTIALS = [];
const DEFAULT_CREDENTIAL_COUNT = 0;
const DEFAULT_IS_CREDENTIAL_REQUESTS = false;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_TRANSLATE_PREFIX = 'digital-signature-sign-catalog';

const actionsText = 'actions';

function getHeaders(hideLoa, hideDates, hideActions, translate, translatePrefix) {
  return [
    { text: 'name', align: 'left' },
    { text: 'description' },
    { text: 'file' },
    { text: 'date' },
    { text: actionsText }
  ]
    .filter((header) => {
      return true;
    })
    .map((header) => {
      console.log("`${translatePrefix}-${header.text}`", `${translatePrefix}-${header.text}`)
      header.text = translate(`${translatePrefix}-${header.text}`);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DigitalSignatureCatalogTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  // const credentials = props.credentials || DEFAULT_CREDENTIALS;
  const credentialCount = props.credentialCount || DEFAULT_CREDENTIAL_COUNT; //------------------PEND change ??
  const isCredentialRequests = props.isCredentialRequests || DEFAULT_IS_CREDENTIAL_REQUESTS; //------------------PEND change
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX; //------------------PEND change

  //add mocked data
  const catalogMockData = [{
    name: "Políticas de Riesgos Laborales",
    subjectName: "Riesgos laborales Repsol Techlab...",
    // archivo descarga???
    endDate: 1734393600
    // estado ???
  },
  {
    name: "Protocolo de Emergencia",
    subjectName: "Protocolo de emergencia en oficinas...",
    // archivo descarga???
    endDate: 1734393600
    // estado ???
  },
  {
    name: "Políticas de Tratamiento de Datos",
    subjectName: "Políticas de tratamiento de datos para...",
    // archivo descarga???
    endDate: 1734393600
    // estado ???
  }]

  const hideLevelOfAssurance = isCredentialRequests;
  const hideDates = isCredentialRequests;

  const headers = getHeaders(hideLevelOfAssurance, hideDates, hideActions, translate, translatePrefix);  //------------------PEND change

  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  return (
    <Grid item='true' paddingBottom={4}>
      <TableContainer>
        <Table>
          <TableHeader headers={headers} fontWeight='bold' />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate(`${translatePrefix}-loading`)}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {catalogMockData.map((oneDocCatalog) => {
                return (
                  <CredentialRow
                    translate={translate}
                    actionsComponent={EnttiyCredentialButtons}
                    name={oneDocCatalog.name}
                    maxNameLength={MAX_LENGTH[0]}
                    endDate={oneDocCatalog.endDate}
                    subjectName={oneDocCatalog.subjectName}
                    hideHashButton={true}
                    hideActions={false}
                    hideLevelOfAssurance={true}
                    hideStatus={true}
                    hideDates={false}
                    valueIsFile={true}
                    translatePrefix={translatePrefix}
                  />
                );
              })}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    labelRowsPerPage={translate(`rows-per-page`)}
                    component='div'
                    count={credentialCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
