import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AdminRequestHistoryIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M15 6L12 3M12 3L9 6M12 3V15M17 9H17.8C18.9201 9 19.4802 9 19.908 9.21799C20.2843 9.40973 20.5903 9.71569 20.782 10.092C21 10.5198 21 11.0799 21 12.2V17.8C21 18.9201 21 19.4802 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.0799 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V12.2C3 11.0799 3 10.5198 3.21799 10.092C3.40973 9.71569 3.71569 9.40973 4.09202 9.21799C4.51984 9 5.0799 9 6.2 9H7'
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
