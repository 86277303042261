// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../../store/reducers/customers';

import {
  clearEntityCredentialsCache,
  fetchEntityCredentialsAsync,
  selectEntityCredentials,
  selectEntityCredentialsCount,
  selectEntityCredentialsPage,
  selectEntityCredentialsRowsPerPage,
  selectEntityCredentialsState
} from '../../../../store/reducers/entityCredentials';

import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import CredentialFilters from '../../../../components/private/Filters/CredentialFilters.component';
import CredentialTable from '../../../../components/private/Tables/CredentialTable.component';
import EnttiyCredentialButtons from '../../../../components/private/Dropdowns/EntityCredentialButtons';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// PRIVATE COMPONENTS
// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityCredentials(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const credentials = useSelector(selectEntityCredentials);
  const credentialsState = useSelector(selectEntityCredentialsState);
  const credentialsCount = useSelector(selectEntityCredentialsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectEntityCredentialsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectEntityCredentialsRowsPerPage));

  const isCredentialsLoading = credentialsState === REDUCER_STATUS.LOADING;
  const credentialsNeedsReload = credentialsState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialsNeedsReload) {
    dispatch(
      fetchEntityCredentialsAsync({
        page,
        rowsPerPage,
        search,
        customer: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearEntityCredentialsCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearEntityCredentialsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearEntityCredentialsCache());
  };

  return (
    <ViewsLayout pageTitle={translate('entity-credentials-page-my-credentials')} layoutSize={layoutSize}>
      <Grid item='true'>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={false}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        isLoading={isCredentialsLoading}
        credentials={credentials}
        credentialCount={credentialsCount}
        isCredentialRequests={false}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideLevelOfAssurance={false}
        translatePrefix={'credential-table'}
        actionsComponent={EnttiyCredentialButtons}
      />
    </ViewsLayout>
  );
}
