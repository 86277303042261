import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const DEFAULT_PAGE_TITLE = '';
const DEFAULT_BUTTONS = [];
const DEFAULT_CHILDREN = <></>;

export default function MainView(props) {
  const pageTitle = props.pageTitle || DEFAULT_PAGE_TITLE;
  const buttons = props.buttons || DEFAULT_BUTTONS;
  const children = props.children || DEFAULT_CHILDREN;
  const layoutSize = props.layoutSize;

  return (
    <Grid id={'view-layout-grid-l0'} direction='column' alignItems='stretch' spacing={3} paddingLeft={'3em'} paddingRight={'3em'} paddingTop={'1.5em'}>
        <Grid id={'view-layout-page-title'} item='true' paddingBottom={layoutSize === 'xsOrS' ? '1em' : '0px'}>
          <Typography color='textPrimary' variant='h5' fontWeight='bold' fontSize='1.75rem'>
            {pageTitle}
          </Typography>
        </Grid>
        <Grid id={'view-layout-filters-buttons'} container direction='row' justifyContent='flex-end' alignItems='baseline' paddingBottom={'10px'}>
              {buttons.map((button) => (
                <Grid item='true' key={button.text} paddingLeft={'1em'} >
                  <Button
                    color='primary'
                    variant='contained'
                    sx={{
                      height: '2.5rem',
                      textTransform: 'none'
                    }}
                    disabled={button.isDisabled}
                    onClick={button.onClick}
                    size='large'>
                    <Typography fontSize='1.125rem' fontWeight='bold' paddingRight={1} paddingLeft={1} >
                      {button.text}
                    </Typography>
                  </Button>
                </Grid>
              ))}
        </Grid>
        <Grid id={'view-layout-children'}>
          {children}
        </Grid>
    </Grid>
  );
}
