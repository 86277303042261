import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, getResponseJson, isOk } from '../../helpers/axios.helper';
import config from '#config';
import { decodeAllJwts, mapPresentationRequests } from '../../helpers/jwt.helpers';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';
import mockedData from '../../config/mockedData.config';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPresentationsAsync = createAsyncThunk(
  'presentations/fetchPresentations',
  async ({ page, rowsPerPage, search, startDate, endDate, prtId, cdi, searchCdiName }) => {
    // count should be made user configurable.
    const limit = rowsPerPage === undefined ? initialState.rowsPerPage : rowsPerPage;
    const form = new URLSearchParams();
    let jwts = [];
    let endpoint = '/dit/presentationRequest';
    let didFilter = 'issuer';

    let count = 0;
    if (config.INTERNAL.MOCKED) {
      jwts = mockedData.presentationRequests;
      count = jwts.length;
    } else {
      const whoAmIResponse = await get('/corporate');

      if (isOk(whoAmIResponse.status)) {
        const whoAmI = getResponseJson(whoAmIResponse);
        form.append(didFilter, whoAmI.did);
        form.append('offset', limit * page);
        form.append('limit', limit);
        form.append('isDeleted', false);

        if (search) {
          form.append('search', search);
        }

        if (searchCdiName) {
          form.append('searchCdiName', searchCdiName);
        }  

        if (startDate) {
          form.append('validUntil', startDate);
        }

        if (endDate) {
          form.append('expiredFrom', endDate);
        }

        if (prtId) {
          form.append('prtId', prtId);
        }

        if (cdi) {
          form.append('cdi', cdi);
        }

        const response = await get(endpoint, form);

        if (isOk(response.status)) {
          jwts = getResponseJson(response);
          count = Number(response.headers['x-app-total-count']) || 0;
        }
      }
    }
    console.log(jwts);
    const value = await mapPresentationRequests(decodeAllJwts(jwts), false);
    return { page, rowsPerPage, count, value };
  }
);

export const presentationsSlice = createSlice({
  name: 'presentations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearPresentationsCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresentationsAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchPresentationsAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearPresentationsCache } = presentationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectPresentations = (state) => state.presentations.value;
export const selectPresentationsState = (state) => state.presentations.status;
export const selectPresentationsPage = (state) => state.presentations.page;
export const selectPresentationsRowsPerPage = (state) => state.presentations.rowsPerPage;
export const selectPresentationsCount = (state) => state.presentations.count;

export default presentationsSlice.reducer;
