import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ContactCorporateIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M3.23047 20.7109V3.71094H12.1151V7.75901H21.7689V20.7109H3.23047ZM3.99969 19.9417H11.3458V16.6436H3.99969V19.9417ZM3.99969 15.8744H11.3458V12.5956H3.99969V15.8744ZM3.99969 11.8263H11.3458V8.52824H3.99969V11.8263ZM3.99969 7.75901H11.3458V4.48016H3.99969V7.75901ZM12.1151 19.9417H20.9997V8.52824H12.1151V19.9417ZM14.4997 12.5956V11.8263H17.9516V12.5956H14.4997ZM14.4997 16.6436V15.8744H17.9516V16.6436H14.4997Z'
        fill='#F0F3FF'
      />
    </SvgIcon>
  );
}
