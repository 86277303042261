import { CREDENTIAL_REQUEST_STATUS, CREDENTIAL_STATUS, CUSTOMER_STATUS, ROW_COUNT} from '#constants';
import { get, getResponseJson, isOk, post } from '../../helpers/axios.helper';
import { createHashForObject } from '../../helpers/hash.helper';
import { decodeAllJwts, mapPresentationRequests, mapPresentations, sign } from '../../helpers/jwt.helpers';

import { uploadFileToFileManager } from '../files';

import config from '#config';

// ENDPOINT: /notification

export async function getNotifications({ page, limit, search, createDate, customer, type, isRead }) {
  const finalLimit = limit === undefined ? ROW_COUNT[0] : limit;
  let notifications = [];
  let filteredData = [];
  let count = 0;

  var urlForm = new URLSearchParams();

  if (finalLimit) {
    if (page) {
      urlForm.append('offset', finalLimit * page);
    }
    urlForm.append('limit', finalLimit);
  }

  urlForm.append('isDeleted', false);
  
  
  if (isRead !== undefined) {
    urlForm.append('isRead', isRead);
  }

  if (search) {
    urlForm.append('search', search);
  }

  if (customer) {
    urlForm.append('customer', customer);
  }

  if (type) {
    urlForm.append('type', type);
  }

  if (createDate) {
    const createdAt = new Date(createDate * 1000);
    urlForm.append('createdAt', `${createdAt.getUTCFullYear()}-${createdAt.getUTCMonth() + 1}-${createdAt.getUTCDate()}`);
  }
  
  const response = await get('/notifications', urlForm);
  if (isOk(response.status)) {
    notifications = getResponseJson(response);
    filteredData = notifications.reduce((acc, obj) => {
      const type = JSON.parse(obj.data).type;
      if (type === 'createPresentationRequest') {
        return (acc.some(item => (item.objectId === obj.objectId) && JSON.parse(item.data).type === 'createPresentationRequest')) ? acc : [...acc, obj]
      } else {
        return [...acc, obj]
      }
    }, []);
    //count = Number(response.headers['x-app-total-count']) || 0;
    count = filteredData.length
  }
  return { value: filteredData, page, rowsPerPage: limit, count };
}

// ENDPOINT: /invitation

export async function inviteCustomer(form, onError) {
  if (config.mocked) {
    const mockedResponse = { status: CUSTOMER_STATUS[1], ...form };
    config.mockedData.invitations.push(mockedResponse);
    return true;
  }

  const result = await post('/invitation', form);

  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

// ENDPOINT: /customer

export async function getCustomer(options) {
  const searchParams = new URLSearchParams();
  const optionKeys = Object.keys(options);

  optionKeys.forEach((optionKey) => {
    const optionValue = options[optionKey];
    searchParams.append(optionKey, optionValue);
  });

  const result = await get(`/customer`, searchParams);

  const customer = {};

  if (!isOk(result.status)) {
    return customer;
  }

  const responseJson = getResponseJson(result);

  if (!Array.isArray(responseJson) || responseJson.length <= 0) {
    return customer;
  }

  return responseJson[0];
}

export async function addCustomer(form, onError) {
  if (config.mocked) {
    const mockedResponse = { status: CUSTOMER_STATUS[1], ...form };
    config.mockedData.invitations.push(mockedResponse);
    return true;
  }

  const result = await post('/customer', form);

  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

// ENDPOINT: /corporate

export async function getCorporateNonExpiredCredentials(options) {
  return await getCorporateCredentials({ ...options, expiresAfter: Math.floor(Date.now() / 1000) });
}

export async function getCorporateCredentials(options) {
  const searchParams = new URLSearchParams();
  const optionKeys = Object.keys(options);

  optionKeys.forEach((optionKey) => {
    const optionValue = options[optionKey];
    searchParams.append(optionKey, optionValue);
  });

  const credentialResult = await get('/corporate/credential', searchParams);
  return isOk(credentialResult.status) ? getResponseJson(credentialResult) : [];
}

export async function getCorporateCredentialRequests(options) {
  const searchParams = new URLSearchParams();
  const optionKeys = Object.keys(options);

  optionKeys.forEach((optionKey) => {
    const optionValue = options[optionKey];
    searchParams.append(optionKey, optionValue);
  });

  const credentialResult = await get('/corporate/credentialRequest', searchParams);
  return isOk(credentialResult.status) ? getResponseJson(credentialResult) : [];
}

// ENDPOINT: /dit

export async function createCredentialRequest(form, onError) {
  if (config.mocked) {
    const jwt = await sign(form);
    const signCredential = { jwt, status: 'OK' };

    config.mockedData.entityCredentials.push(signCredential);
    return true;
  }

  if (typeof form.file !== 'string') {
    const procHash = createHashForObject(form);
    const result = uploadFileToFileManager(form.file, procHash, onError);

    if (result === null) return false;

    form.file = result;
  }

  const response = await post('/dit/credentialRequest', form);

  if (isOk(response.status)) return response;

  onError(response);
}

export async function createCredential(form, onError) {
  if (config.mocked) {
    const jwt = await sign(form);
    const signCredential = { jwt, status: 'OK' };

    config.mockedData.credentials.push(signCredential);
    return true;
  }

  const result = await post(`/dit/credential`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function rejectCredentialRequest(jti, form, onError) {
  form.status = CREDENTIAL_REQUEST_STATUS[3];

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credentialRequest/${jti}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function cancelCredentialRequest(jti, form, onError) {
  form.status = CREDENTIAL_REQUEST_STATUS[1];

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credentialRequest/${jti}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function deleteCredentialRequest(jti, form, onError) {
  let queryParams = new URLSearchParams();

  form.isDeleted = true;
  queryParams.append('propagated', true);

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credentialRequest/${jti}?${queryParams}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function revokeCredential(jti, form, onError) {
  form.status = CREDENTIAL_STATUS[2];

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credential/${jti}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function deleteCredential(jti, form, onError) {
  let queryParams = new URLSearchParams();

  form.isDeleted = true;
  queryParams.append('propagated', true);

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credential/${jti}?${queryParams}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function revokeCredentialBySubject(jti, form, onError) {
  form.status = CREDENTIAL_STATUS[3];

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/credential/${jti}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function createPresentationRequestTemplate(form, onError) {
  const result = await post(`/dit/presentationRequestTemplate`, { data: form });
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function getPresentationRequestTemplate(presentationRequestTemplateId) {
  const result = await get(`/dit/presentationRequestTemplate/${presentationRequestTemplateId}`);

  let prt = { data: '{}' };
  if (isOk(result.status)) {
    prt = getResponseJson(result);
  }

  return prt;
}

export async function deletePresentationRequestTemplate(prtId, form, onError) {
  let queryParams = new URLSearchParams();

  form.isDeleted = true;
  queryParams.append('propagated', true);

  if (config.mocked) {
    return true;
  }
  console.log(form)
  const result = await post(`/dit/presentationRequestTemplate/${prtId}?${queryParams}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function createPresentationRequest(form, onError) {
  const result = await post('/dit/presentationRequest', form);
  console.log(result);
  let pr = undefined;
  if (isOk(result.status)) {
    pr = getResponseJson(result);
  } else {
    onError(result);
  }

  return pr;
}

export async function assignPresentationRequest(jti, form, onError) {
  const result = await post(`/dit/presentationRequest/${jti}`, form);

  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function getPresentationRequest(presentationRequestId) {
  const result = await get(`/dit/presentationRequest/${presentationRequestId}`);

  let presentation = {};
  if (isOk(result.status)) {
    const presentations = await mapPresentationRequests(decodeAllJwts([getResponseJson(result)]), true);

    if (presentations.length > 0) {
      presentation = presentations[0];
    }
  }

  return presentation;
}

export async function getPresentationRequestEntity(cdi, sub) {
  let queryParams = new URLSearchParams();
  queryParams.append('cdi', cdi);
  queryParams.append('subject', sub)

  const result = await get(`/corporate/presentationRequest?${queryParams}`);

  let presentations = [];
  
  if (isOk(result.status)) {
    presentations = await mapPresentationRequests(decodeAllJwts(getResponseJson(result)), true);

  }

  return presentations;
}

export async function getPresentationRequestAdmin(cdi, iss, procHash) {
  let queryParams = new URLSearchParams();
  queryParams.append('cdi', cdi);
  queryParams.append('issuer', iss);
  if(procHash) {
    queryParams.append('procHash', procHash);
  }

  const result = await get(`/dit/presentationRequest?${queryParams}`);

  let presentations = [];
  
  if (isOk(result.status)) {
    presentations = await mapPresentationRequests(decodeAllJwts(getResponseJson(result)), true);

  }

  return presentations;
}

export async function deletePresentationRequest(jti, form, onError) {
  let queryParams = new URLSearchParams();

  form.isDeleted = true;
  queryParams.append('propagated', true);

  if (!form.fileUri) {
    form.fileUri = '';
  }

  if (config.mocked) {
    return true;
  }

  const result = await post(`/dit/presentationRequest/${jti}?${queryParams}`, form);
  if (isOk(result.status)) {
    return true;
  }

  onError(result);
  return false;
}

export async function getPresentationByCdi(cdi) {
  let queryParams = new URLSearchParams();
  queryParams.append('cdi', cdi);

  const result = await get(`/dit/presentation?${queryParams}`);

  let presentations = [];
  
  if (isOk(result.status)) {
    presentations = await mapPresentations(decodeAllJwts(getResponseJson(result)), true);

  }

  return presentations;
}

// ENDPOINT: /catalog

export async function getCredentialFromCatalog(credentialId) {
  if (config.mocked) {
    return config.mockedData.catalog.filter((catalogEntry) => catalogEntry.name === credentialId);
  }

  const response = await get(credentialId, undefined, true);

  let catalogEntry = {};

  if (isOk(response.status)) {
    catalogEntry = getResponseJson(response);
  }

  return catalogEntry;
}

export async function getCatalog() {
  if (config.mocked) {
    return config.mockedData.catalog;
  }

  const response = await get(`/catalog`);

  let catalog = [];

  if (isOk(response.status)) {
    catalog = getResponseJson(response);
  }

  return catalog;
}

export async function getJurisdictions() {
  const response = await get(`/catalog/jurisdictions`);

  let jurisdictions = [];

  if (isOk(response.status)) {
    jurisdictions = getResponseJson(response);
  }

  return jurisdictions;
}
