import { createTheme } from '@mui/material';

// -------------- equivale a EMPRESA TRANSPORTISTA 1 (verde #857E50)
export const transportTheme = createTheme({
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

// -------------- equivale a BFE (azul #211951)
export const b4eTheme = createTheme({
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

// -------------- equivale a REPSOL QUÍMICA (naranja #FF8200)
export const repsolTheme = createTheme({
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

// -------------- equivale a EMPRESA TRANSPORTISTA 2 (azul #211951)
export const sybolTheme = createTheme({
  default: true,
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#000000'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

// -------------- equivale a REPSOL LAE (rojo #a4161a)
export const repsolLAETheme = createTheme({
  default: true,
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#000000'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});