import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChevronRightIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 30, height: 30 }}>
      <path
        d='M11.7428 21.6346L11.0481 20.9399L16.9952 14.9928L11.0481 9.02162L11.7428 8.3269L18.3846 14.9928L11.7428 21.6346Z'
        fill='#AEAEAE'
      />
    </SvgIcon>
  );
}
