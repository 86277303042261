// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '#reducers/customers';
import {
  clearCredentialsCache,
  fetchCredentialsAsync,
  selectCredentials,
  selectCredentialsCount,
  selectCredentialsPage,
  selectCredentialsState,
  selectCredentialsRowsPerPage
} from '#reducers/adminCredentials';

import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import CredentialFilters from '#privateComponents/Filters/CredentialFilters.component';
import CredentialTable from '#privateComponents/Tables/CredentialTable.component';

import IssueCredentialPopup from '#privateComponents/Popups/IssueCredentialPopup.component';
import AdminCredentialButtons from '#privateComponents/Dropdowns/AdminCredentialButtons';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AdminCredentials(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  const dispatch = useDispatch();

  const credentials = useSelector(selectCredentials);
  const credentialsState = useSelector(selectCredentialsState);
  const credentialsCount = useSelector(selectCredentialsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectCredentialsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectCredentialsRowsPerPage));

  const isLoadingCredentials = credentialsState === REDUCER_STATUS.LOADING;
  const credentialsNeedsReload = credentialsState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialsNeedsReload) {
    dispatch(
      fetchCredentialsAsync({
        page,
        rowsPerPage,
        search,
        customerId: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCredentialsCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCredentialsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearCredentialsCache());
  };

  return (
    <ViewsLayout
      pageTitle={translate('admin-credentials-page-title')}
      buttons={[
        {
          onClick: () => setIsOpen(true),
          text: translate('admin-credentials-page-create-button')
        }
      ]}
      layoutSize={layoutSize}>
      <IssueCredentialPopup translate={translate} isOpen={isOpen} setIsOpen={setIsOpen} />

      <Grid item='true'>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={false}
          isAdmin={true}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        isLoading={isLoadingCredentials}
        credentials={credentials}
        credentialCount={credentialsCount}
        isCredentialRequests={false}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideLevelOfAssurance={false}
        translatePrefix={'admin-credential-table'}
        actionsComponent={AdminCredentialButtons}
      />
    </ViewsLayout>
  );
}
