import { KeyboardArrowDown } from '@mui/icons-material';

export function SelectDropdownSquareIcon() {
  return (
    <KeyboardArrowDown
      sx={{
        backgroundColor: 'rgba(4, 146, 148, 1)',
        borderRadius: '0%',
        right: '0%',
        color: '#FFFFFF',
        fontSize: '3.7rem',
        userSelect: 'none',
        pointerEvents: 'none',
        position: 'absolute'
      }}
    />
  );
}
