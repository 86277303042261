export const Root = '';

export const Login = `${Root}/login`;
export const LoginForm = `${Login}/form`;

export const Notification = `${Root}/notifications`;
export const NotificationInbox = `${Notification}/inbox`;

export const Dashboard = `${Root}/dashboard`;

export const Admin = `${Root}/admin`;
export const AdminPresentationRequestHistory = `${Admin}/presentation-request-history`;
export const AdminCredentialRequests = `${Admin}/credential-requests`;
export const AdminCredentials = `${Admin}/credentials`;
export const AdminPresentationRequestTemplates = `${Admin}/presentation-request-templates`;
export const AdminPresentationRequestCreation = `${Admin}/presentation-request-creation`;

export const Entity = `${Root}/entity`;
export const EntityPresentationRequests = `${Entity}/presentation-requests`;
export const EntityCredentialRequests = `${Entity}/credential-requests`;
export const EntityCredentials = `${Entity}/credentials`;
export const EntityPresentations = `${Entity}/presentations`;

export const DigitalSignature = `${Root}/digital-signature`;
export const DigitalSignatureSignRequest = `${DigitalSignature}/sign-request`;
export const DigitalSignatureSignRequestDetail = `${DigitalSignatureSignRequest}/detail`;
export const DigitalSignatureRequestsSent = `${DigitalSignature}/presentation-request-history`; //-----------
export const DigitalSignatureRequestsSentDetail = `${Root}/mocks/signatureSentDetail`; //-----------

export const DigitalSignatureCatalog = `${DigitalSignature}/catalog`;
export const DigitalSignatureForm = `${DigitalSignature}/form`;
export const DigitalSignatureSignRequestRowPopUp = `${Root}/mocks/signatureFormCreate`;

export const Contacts = `${Root}/contacts`;
export const ConctactsCorporate = `${Contacts}/corporate`;
export const ContactsIndividuals = `${Contacts}/individuals`;

const _default = {
  Root,
  Login,
  LoginForm,
  NotificationInbox,
  Dashboard,
  Admin,
  AdminCredentials,
  AdminCredentialRequests,
  AdminPresentationRequestHistory,
  AdminPresentationRequestTemplates,
  AdminPresentationRequestCreation,
  Entity,
  EntityCredentials,
  EntityCredentialRequests,
  EntityPresentations,
  EntityPresentationRequests,
  DigitalSignature,
  DigitalSignatureSignRequest,
  DigitalSignatureRequestsSent,
  DigitalSignatureSignRequestDetail,
  DigitalSignatureRequestsSentDetail,
  DigitalSignatureCatalog,
  DigitalSignatureForm,

  DigitalSignatureSignRequestRowPopUp,

  Contacts,
  ContactsIndividuals,
  ConctactsCorporate
};

export default _default;
