// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import SearchBar from '../../shared/SearchBars/SearchBar.component';
import DatePicker from '../../shared/DatePicker/DatePicker.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_START_DATE = 0;
const DEFAULT_SET_START_DATE = () => {};
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTemplateFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const createDate = props.createDate || DEFAULT_START_DATE;
  const setCreateDate = props.setCreateDate || DEFAULT_SET_START_DATE;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;

  const HandlerRefresh = () => {
    setCreateDate(null);
    setSearch('');
    refresh();
  }

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' paddingTop='0.5em' paddingBottom='0.5em'>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('prt-filter-search')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
        />
      </Grid>

      <Grid item='true'>
        <DatePicker
          required
          placeholder={translate('prt-filter-create-date')}
          value={createDate}
          max={new Date().getTime() + 86400000}
          onChange={(unixTime) => {
            setCreateDate(unixTime);
            onFilterSubmit();
          }}
        />
      </Grid>

      <Grid item='true'>
        <Button
          color='primary'
          variant='outlined'
          sx={{
            height: '2.5rem',
            textTransform: 'none'
          }}
          onClick={HandlerRefresh}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('presentations-page-refresh')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
