import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MyCredentialRequestsIcon() {
  return (
    <SvgIcon viewBox='0 0 50 50'>
      <path
        fill='#fefffe'
        d='M 27.5,8.5 C 29.2603,11.7006 30.0936,15.3673 30,19.5C 30.9138,18.3463 31.7471,18.513 32.5,20C 28.6765,24.7821 26.1765,24.2821 25,18.5C 25.414,18.9574 25.914,19.2907 26.5,19.5C 27.4892,15.8927 27.8226,12.226 27.5,8.5 Z'
      />
      <path
        fill='#fefffe'
        d='M 41.5,21.5 C 42.8333,21.5 44.1667,21.5 45.5,21.5C 45.6602,23.1992 45.4935,24.8659 45,26.5C 41.0776,29.2114 37.2443,32.0447 33.5,35C 27.9445,36.2392 22.2778,36.9059 16.5,37C 14.3359,37.9143 12.3359,39.081 10.5,40.5C 9.28882,39.7261 9.28882,38.8928 10.5,38C 12.2746,36.5611 14.2746,35.5611 16.5,35C 21.1667,34.6667 25.8333,34.3333 30.5,34C 35.1667,30.6667 39.8333,27.3333 44.5,24C 40.0898,24.8715 36.0898,26.8715 32.5,30C 28.7887,30.8253 25.122,30.6586 21.5,29.5C 24.7848,28.6764 28.1182,28.1764 31.5,28C 27.6908,26.4118 23.6908,26.0784 19.5,27C 14.6075,29.0295 9.77418,31.1961 5,33.5C 3.39248,33.0261 3.22581,32.3594 4.5,31.5C 9.5,29.3333 14.5,27.1667 19.5,25C 24.3598,24.0321 29.0265,24.5321 33.5,26.5C 36.2308,24.8084 38.8975,23.1417 41.5,21.5 Z'
      />
    </SvgIcon>
  );
}
