// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------ FILE MODULES ---------------------------------------------------------
import { clearPresentationsCache, fetchPresentationsAsync, selectPresentations, selectPresentationsCount, selectPresentationsPage, selectPresentationsRowsPerPage, selectPresentationsState } from '#reducers/adminPresentations';
import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '#reducers/customers';

import PresentationFilters from '#privateComponents/Filters/PresentationFilters.component';
import PresentationRequestTable from '#privateComponents/Tables/PresentationRequestTable.component';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestHistory(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const [page, setPage] = useState(useSelector(selectPresentationsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectPresentationsRowsPerPage));

  const dispatch = useDispatch();

  const [searchCdiName, setSearchCdiName] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);

  const presentationRequests = useSelector(selectPresentations);
  const presentationCount = useSelector(selectPresentationsCount);
  const presentationState = useSelector(selectPresentationsState);
  
  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const isLoading = presentationState === REDUCER_STATUS.LOADING;
  const needsReload = presentationState === REDUCER_STATUS.NEEDS_REFRESH;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (needsReload) {
    dispatch(fetchPresentationsAsync({page, rowsPerPage, searchCdiName}));
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearPresentationsCache());
  };

  // const onCancelOrExit = () => {
  // };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearPresentationsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearPresentationsCache());
  };

  return (
    <ViewsLayout pageTitle={translate('admin-presentation-history-page-title')} layoutSize={layoutSize}>

      <Grid item='true'>
        <PresentationFilters
          translate={translate}
          users={customers}
          isRequest={true}
          search={searchCdiName}
          setSearch={setSearchCdiName}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <PresentationRequestTable
        translate={translate}
        isLoading={isLoading}
        hideIssuer={true}
        hideSignButton={true}
        presentationRequests={(status) ? presentationRequests.filter((pr) => pr.storedJwt.status === status.status) : presentationRequests}
        presentationCount={presentationCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={handleSetPage}
        setRowsPerPage={handleSetRowsPerPage}
        admin={true}
        hideActions={false}
        hideType={true}
      />
    </ViewsLayout>
  );
}
