// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import SearchBar from '../../shared/SearchBars/SearchBar.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_WIDTH = '16.563rem';
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};
const DEFAULT_OPEN_ADVANCED = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SearchFilter(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const width = props.width || DEFAULT_WIDTH;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;
  const openAdvanced = props.openAdvanced || DEFAULT_OPEN_ADVANCED;

  const style = { width };

  const HandlerRefresh = () => {
    setSearch('');

    refresh();
  }

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' paddingTop='0.5em' paddingBottom='0.5em'>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('catalog-table-filters-search')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
          style={style}
        />
      </Grid>
    </Grid>
  );
}
