import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATUS, ROW_COUNT } from '#constants';
import { getNotifications } from '../../services/orchestrator';

const initialState = {
  value: [],
  status: REDUCER_STATUS.NEEDS_REFRESH,
  page: 0,
  rowsPerPage: ROW_COUNT[0],
  count: 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchNotificationsAsync = createAsyncThunk(
  'notifications/fetchNotifications',
  getNotifications
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearNotificationsCache: (state) => {
      state.value = initialState.value;
      state.status = initialState.status;
      state.count = initialState.count;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        const payload = action.payload;
        state.value = payload.value;
        state.page = payload.page;
        state.rowsPerPage = payload.rowsPerPage;
        state.count = payload.count;
      });
  }
});

export const { clearNotificationsCache } = notificationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectNotifications = (state) => state.notifications.value;
export const selectNotificationsState = (state) => state.notifications.status;
export const selectNotificationsPage = (state) => state.notifications.page;
export const selectNotificationsRowsPerPage = (state) => state.notifications.rowsPerPage;
export const selectNotificationsCount = (state) => state.notifications.count;

export default notificationsSlice.reducer;
