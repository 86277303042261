import axios from "axios";

import { useState } from "react";

import { useDispatch } from "react-redux";

import { useNavigate, useSearchParams } from "react-router-dom";

import DigitalisIcon from "../../shared/Icons/DigitalisIcon.component";

import {  Typography, Button, TextField, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';

import { ArrowForwardIos, VisibilityOff, Visibility } from "@mui/icons-material";

import { PAGE_ROUTES } from "#constants";

import { setSession } from "../../../store/reducers/session";
import { setError } from '../../../store/reducers/error';
import { isOk } from "../../../helpers/axios.helper";

import { AUTH_SERVERS } from "#constants";

import config from "#config";

const mockUsername = "example@example.com";
const mockPassword = "example";

async function onFormSubmit(username, password, navigate, dispatch, setSession, setErrorPopup, searchParams) {
  if (!username || !password) {
    return;
  }

  if (config.INTERNAL.MOCKED) {
    if (mockUsername === username && mockPassword === password) {
      navigate(PAGE_ROUTES.NotificationInbox)
    }

    return null;
  }

  const id = searchParams.get("flow")
  let response = await axios.get(AUTH_SERVERS.SAFEGATE.URIS.LOGIN_FLOWS, {headers: {Accept: 'application/json'}, params: {id}, withCredentials: true});

  var form = new URLSearchParams();
  form.append("identifier",  username);
  form.append("password", password);
  form.append("csrf_token", response.data.ui.nodes[0].attributes.value);
  form.append("method", "password");

  response = await axios.post(AUTH_SERVERS.SAFEGATE.URIS.LOGIN, form, {withCredentials: true, params: {flow: id}});

  //const response = await post(config.keycloakURL, form);

  if (isOk(response.status)) {
    //dispatch(setSession(response.data.access_token));
    navigate(PAGE_ROUTES.NotificationInbox);
    return;
  } 

  // If auth usage is disabled, the response from keycloak is ignored, and we are forcefully "logged in".
  // One should disable auth when testing locally without a keycloak server to point to.
  if (!config.INTERNAL.AUTH_ENABLED) {
    navigate(PAGE_ROUTES.NotificationInbox)
    return;
  }

  setErrorPopup(response);
}

export default function Login(props) {
  const translate = props.translator;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [searchParams] = useSearchParams()

  if (!searchParams.get("flow")) {
    window.location.href = AUTH_SERVERS.SAFEGATE.URIS.LOGIN_BROWSER;
    return null;
  }

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const usernameChanged = (event) => {
    setUsername(event.target.value);
  };

  const passwordChanged = (event) => {
    setPassword(event.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(username, password, navigate, dispatch, setSession, setErrorPopup, searchParams);
  };

  return (
    <Container id={'lading-slide-l0-container'} sx={{ position: 'absolute', color: '#f1f1f1', backgroundColor: '#00575CD9', width: '100%', minHeight: '700px'}}>
    <video autoPlay loop muted className='video-background'>
      <source src='/images/landing.mp4' type='video/mp4' />
    </video>
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" padding={5}>
        <Grid item='true' md={4}>
          <DigitalisIcon />
        </Grid>

        <Grid item='true' md={4}>
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid item='true' paddingTop={15}>
              <Typography sx={{ fontSize: "20px" }}>{translate("login-title")}</Typography>
            </Grid>

            <Grid item='true' paddingTop={5}>
              <Typography sx={{ fontSize: "20px" }}>{translate("login-description")}</Typography>
            </Grid>

            <Grid item='true' paddingTop={2}>

    <form onSubmit={formSubmit}>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Grid item='true'>
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid item='true'>
              <Typography sx={{ fontSize: "20px" }}>{translate("login-email")}</Typography>
            </Grid>
            <Grid item='true'>
              <TextField
                fullWidth
                required
                name="username"
                value={username || ""}
                onChange={usernameChanged}
                id="outlined-required"
                sx={{
                    border: "2px solid #049294",
                    borderRadius: "4px",
                    backgroundColor: "rgba(1, 42, 45, 1)",
                    input: { color: "white" },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item='true'>
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid item='true'>
              <Typography sx={{ fontSize: "20px" }}>{translate("login-password")}</Typography>
            </Grid>
            <Grid item='true'>
              <OutlinedInput
                sx={{
                  border: "2px solid #049294",
                  borderRadius: "4px",
                  backgroundColor: "rgba(1, 42, 45, 1)",
                  input: { color: "white" },
                }}
                fullWidth
                required
                name="password"
                value={password || ""}
                onChange={passwordChanged}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <VisibilityOff sx={{ color: "#048183" }} /> : <Visibility sx={{ color: "#048183" }} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item='true'>
          <Grid container justifyContent="flex-end">
            <Button
              type="submit"
              sx={{
              border: "3px solid #049294",
              borderRadius: "4px",
              color: "white",
              fontSize: "18px",
              }}
            >
              <Typography paddingRight={5} paddingLeft={5}>
                {translate("login-button")}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
    </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}