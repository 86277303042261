// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { ROW_COUNT } from '#constants';
import { PLATFORM_TYPE, REDUCER_STATUS } from '#constants';

import customers, {
  fetchCustomersAsync,
  selectCustomers,
  selectCustomersState
} from '../../../store/reducers/customers';

import CollapsibleContactRow from '../Rows/CollapsibleContactRow.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './PresentationDetailsTable.component.css';

import { selectWhoAmI } from '../../../store/reducers/corporate';
import services from '../../../services';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_IS_LOADING = false;
const DEFAULT_CONTACTS = [];
const DEFAULT_CONTACT_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_PRESENTATIONS = [];

function getHeaders() {
  return [
    {
    },
    { text: 'Name' },
    { text: 'did' }
  ];
}

async function getDelegatedPrs(presentationRequest, setDelegatedPrs, setIsDelegatedPrsLoading, whoAmI) {
  setIsDelegatedPrsLoading(true);
  const delegatedPrs = await services.orchestrator.getPresentationRequestAdmin(presentationRequest.decoded.cdi, whoAmI.did, presentationRequest.decoded.pr.procHash);
  setDelegatedPrs(delegatedPrs);
  setIsDelegatedPrsLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationDetailsTable (props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;

  const dispatch = useDispatch();

  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;

  const presentations = props.presentations || DEFAULT_PRESENTATIONS;
  const presentationRequest = props.presentationRequest;

  const headers = getHeaders(translate);

  const contactsDb = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const needsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  const [delegatedPrs, setDelegatedPrs] = useState([]);
  const [isDelegatedPrsLoading, setIsDelegatedPrsLoading] = useState(undefined);

  const whoAmI = useSelector(selectWhoAmI);
  
  if (needsReload) {
    dispatch(
      fetchCustomersAsync({
        comms: PLATFORM_TYPE[0],
        page,
        limit: rowsPerPage
      })
    );
  }

  if (isDelegatedPrsLoading === undefined) {
    getDelegatedPrs(presentationRequest,  setDelegatedPrs, setIsDelegatedPrsLoading, whoAmI);
  }

  let dids2 = [];
  console.log(delegatedPrs);
  if(delegatedPrs) {
    let delegatedContacts = delegatedPrs.map((pr) => {
      return pr.decoded.customers[0];
    });
    dids2 = delegatedContacts.filter((contact) => contact.customerStatus === 'Accepted').map((contact) => contact.customer.did);
    console.log(dids2);

    delegatedContacts = delegatedPrs.map((pr) => {
      return pr.decoded.customers[0];
    });
    console.log(delegatedContacts);
    delegatedContacts.map((contact) => {
      contact.customer.customerStatus = contact.customerStatus;
      if(contact.customerStatus !== 'Accepted') {
        //contact.customer.presentations = presentations.filter((presentation) => presentation.decoded.iss === contact.customer.did);
        return contact.customer.did;
      }
    });
  }


  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;
  console.log(presentations);
  // Add presentation dids
  const issuers = [ ...dids2, ...presentations.map((presentation) => presentation.decoded.iss)]
  console.log(issuers);
  const dids = issuers.filter((currentValue, index, arr) => (
		arr.indexOf(currentValue) === index
	));
  /*issuers.filter((value, index, current_value) => index === current_value.findIndex((t) => (t.did === value.did)));*/
  console.log(dids);

  const contacts = contactsDb.filter((contactDb) => dids.includes(contactDb.did));
  console.log(contacts);
  const contactCount = contacts.length

  return (
    <Grid item='true' paddingLeft={5} paddingRight={5} paddingTop={3} style={{maxWidth: '100%'}}>
      <TableContainer className='contacts-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate(`contacts-corporate-table-loading`)}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {contacts.map((contact) => (
                <CollapsibleContactRow
                  key={contact.did}
                  translate={translate}
                  contact={contact}
                  presentations={presentations}
                  presentationRequest={presentationRequest}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    component='div'
                    labelRowsPerPage={translate(`rows-per-page`)}
                    count={contactCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
