import crypto from 'crypto-browserify';

export function createHashForObject(object) {
  let procHash = `0x0000000000000000000000000000000000000000000000000000000000000000`;

  const hash = crypto.createHash('sha256');
  hash.update(`${JSON.stringify(object)}${Date.now()}`);
  procHash = `0x${hash.digest().toString('hex')}`;

  return procHash;
}
