// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';
// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../store/reducers/customers';
import { clearNotificationsCache, fetchNotificationsAsync, selectNotifications, selectNotificationsCount, selectNotificationsPage, selectNotificationsRowsPerPage, selectNotificationsState } from '../../../store/reducers/notifications';
import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import NotificationTable from '../../../components/private/Tables/NotificationTable.component';
import NotificationFilters from '../../../components/private/Filters/NotificationFilters.component';

import ViewsLayout from '../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function NotificationInbox(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;

  const notifications = useSelector(selectNotifications);
  const notificationsState = useSelector(selectNotificationsState);
  const notificationsCount = useSelector(selectNotificationsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [page, setPage] = useState(useSelector(selectNotificationsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectNotificationsRowsPerPage));
  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [createDate, setCreateDate] = useState(null);
  const dispatch = useDispatch();

  const isLoadingNotifications = notificationsState === REDUCER_STATUS.LOADING;
  const notificationsNeedsReload = notificationsState === REDUCER_STATUS.NEEDS_REFRESH;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;


  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (notificationsNeedsReload) {
    dispatch(
      fetchNotificationsAsync({ 
        page,
        limit: rowsPerPage,
        search,
        createDate: Math.round(createDate / 1000),
        customer: customer ? customer.businessName : null
      })
    );
  }

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearNotificationsCache());
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearNotificationsCache());
  };
  
  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearNotificationsCache());
  };
  

  const handleRefresh = () => {
    dispatch(
      fetchNotificationsAsync({})
    );
  };

  return (
    <ViewsLayout pageTitle={translate('notification-inbox-page-title')} layoutSize={layoutSize}>
        <Grid item='true' marginBottom={layoutSize === 'lOrXl' ? '0px' : '1em'}>
          <NotificationFilters
            translate={translate}
            users={customers}
            search={search}
            setSearch={setSearch}
            customer={customer}
            setCustomer={setCustomer}
            createDate={createDate}
            setCreateDate={setCreateDate}
            onFilterSubmit={onFilterSubmit}
            refresh={handleRefresh}
            layoutSize={layoutSize}
          />
      </Grid>
      <NotificationTable
        translate={translate}
        isLoading={isLoadingNotifications}
        notifications={notifications}
        notificationCount={notificationsCount}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        translatePrefix={'notification-inbox-table'}
      />
    </ViewsLayout>
  );
}
