import { useState } from 'react';

import { useDispatch } from 'react-redux';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Fingerprint from '@mui/icons-material/Fingerprint';
import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';

import { clearEntityCredentialsCache } from '../../../store/reducers/entityCredentials';
import { setError } from '../../../store/reducers/error';
import { CREDENTIAL_STATUS } from '#constants';

import Hider from '../../shared/Hider/Hider.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import services from '../../../services';

const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ANCHOR_ELEMENT = undefined;
const DEFAULT_SET_ANCHOR_ELEMENT = () => {};
const DEFAULT_HIDE_HASH_BUTTON = false;
const DEFAULT_TRANSACTION_HASH = '';
const DEFAULT_SIGNED_TOKEN = '';
const DEFAULT_STATUS = '';
const DEFAULT_CREDENTIAL = {};

export default function EnttiyCredentialButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const anchorElement = props.anchorElement || DEFAULT_ANCHOR_ELEMENT;
  const setAnchorElement = props.setAnchorElement || DEFAULT_SET_ANCHOR_ELEMENT;
  const hideHashButton = props.hideHashButton || DEFAULT_HIDE_HASH_BUTTON;
  const transactionHash = props.transactionHash || DEFAULT_TRANSACTION_HASH;
  const signedToken = props.signedToken || DEFAULT_SIGNED_TOKEN;
  const status = props.status || DEFAULT_STATUS;
  const credential = props.credential || DEFAULT_CREDENTIAL;

  const [actionInProgress, setActionInProgress] = useState(false);

  const dispatch = useDispatch();

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const onRevokeClicked = async () => {
    setActionInProgress(true);
    const success = await services.orchestrator.revokeCredentialBySubject(
      credential.storedJwt.jti,
      { ...credential.storedJwt },
      setErrorPopup
    );
    setActionInProgress(false);

    if (success) dispatch(clearEntityCredentialsCache());
  };

  const onDeleteClicked = async () => {
    setActionInProgress(true);
    const success = await services.orchestrator.deleteCredential(
      credential.storedJwt.jti,
      { ...credential.storedJwt },
      setErrorPopup
    );
    setActionInProgress(false);

    if (success) dispatch(clearEntityCredentialsCache());
  };

  const handleClose = () => {
    if (actionInProgress) return;

    setAnchorElement(null);
  };

  const copyHashHandler = () => {
    navigator.clipboard.writeText(transactionHash);
    handleClose();
  };

  const copyTokenHandler = () => {
    navigator.clipboard.writeText(signedToken);
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          minWidth: '15%'
        }
      }}>
      <Typography sx={{ paddingLeft: '1.25rem' }} fontSize='0.938rem' fontWeight='bold'>
        {translate('credential-action-proofs')}
      </Typography>
      <Hider isHidden={hideHashButton}>
        <MenuItem onClick={copyHashHandler}>
          <Grid container spacing={1}>
            <Grid item='true'>
              <Fingerprint sx={{ color: '#662D91' }} />
            </Grid>
            <Grid item='true'>
              <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
                {translate('credential-action-copy-hash')}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Hider>
      <MenuItem onClick={copyTokenHandler}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <VerifiedUserOutlined sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('credential-action-copy-token')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>

      <Divider />

      <SpinnerLoader isLoading={actionInProgress} text={translate('credential-request-action-in-progress')}>
        <Hider isHidden={status !== CREDENTIAL_STATUS[0]}>
          <MenuItem onClick={onRevokeClicked}>
            <Grid container spacing={1}>
              <Grid item='true'>
                <CancelOutlined sx={{ color: '#662D91' }} />
              </Grid>
              <Grid item='true'>
                <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
                  {translate('credential-request-action-revoke')}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Hider>
        <Hider isHidden={status === CREDENTIAL_STATUS[0]}>
          <MenuItem onClick={onDeleteClicked}>
            <Grid container spacing={1}>
              <Grid item='true'>
                <DeleteOutlined sx={{ color: '#662D91' }} />
              </Grid>
              <Grid item='true'>
                <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
                  {translate('credential-action-delete')}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Hider>
      </SpinnerLoader>
    </Menu>
  );
}
