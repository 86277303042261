import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MyRequestsIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 25, height: 25 }}>
      <path
        d='M17.5 9.55713H18.3C19.4201 9.55713 19.9802 9.55713 20.408 9.77512C20.7843 9.96686 21.0903 10.2728 21.282 10.6491C21.5 11.077 21.5 11.637 21.5 12.7571V18.3571C21.5 19.4772 21.5 20.0373 21.282 20.4651C21.0903 20.8414 20.7843 21.1474 20.408 21.3391C19.9802 21.5571 19.4201 21.5571 18.3 21.5571H6.7C5.5799 21.5571 5.01984 21.5571 4.59202 21.3391C4.21569 21.1474 3.90973 20.8414 3.71799 20.4651C3.5 20.0373 3.5 19.4772 3.5 18.3571V12.7571C3.5 11.637 3.5 11.077 3.71799 10.6491C3.90973 10.2728 4.21569 9.96686 4.59202 9.77512C5.01984 9.55713 5.5799 9.55713 6.7 9.55713H7.5'
        stroke='#F0F3FF'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M9.5 12.5571L12.5 15.5571M12.5 15.5571L15.5 12.5571M12.5 15.5571L12.5 3.55713'
        stroke='#F0F3FF'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
