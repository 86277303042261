// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { clearCatalogCache } from '../../../store/reducers/catalog';

import TextDropdown from '../../shared/TextDropdown/TextDropdown.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function retrieveJurisdictions(setJurisdictions, setJurisdictionsLoading) {
  setJurisdictionsLoading(true);
  // API CALL
  // let jurisdictionsResult = await getJurisdictions();
  let jurisdictionsResult = [
    { jurisdictionId: 1, code: 'gov', name: 'Public Schema', parentId: null },
    { jurisdictionId: 2, code: 'cor', name: 'Other Issuers', parentId: null },
    { jurisdictionId: 3, code: 'eur', name: 'European Union', parentId: 1 },
    { jurisdictionId: 4, code: 'usa', name: 'United States', parentId: 1 },
    { jurisdictionId: 5, code: 'repsol', name: 'Repsol', parentId: 2 },
    { jurisdictionId: 6, code: 'logistica', name: 'Logistica', parentId: 5 },
    { jurisdictionId: 7, code: 'esp', name: 'Spain', parentId: 3 },
    { jurisdictionId: 8, code: 'fra', name: 'France', parentId: 3 },
    { jurisdictionId: 9, code: 'gre', name: 'Greece', parentId: 3 }
  ];
  let jurisdictionsResultUnmodified = [...jurisdictionsResult];
  let jurisdictionsToSave = [];

  for (const jurisdiction of jurisdictionsResultUnmodified) {
    const parentJurisdiction = recursiveFindAndAddJurisdictionParent(jurisdiction, jurisdictionsResult);

    if (!jurisdictionsToSave.includes(parentJurisdiction)) {
      jurisdictionsToSave.push(parentJurisdiction);
    }
  }

  setJurisdictions(jurisdictionsToSave);
  setJurisdictionsLoading(false);
}

function recursiveFindAndAddJurisdictionParent(jurisdiction, jurisdictionList) {
  if (!jurisdiction.parentId) {
    return jurisdiction;
  }

  const parentJurisdiction = jurisdictionList.find(
    (jurisdictionP) => jurisdiction.parentId === jurisdictionP.jurisdictionId
  );

  if (!parentJurisdiction.children) {
    parentJurisdiction.children = [];
  }

  if (!parentJurisdiction.children.includes(jurisdiction)) {
    parentJurisdiction.children.push(jurisdiction);
  }

  return recursiveFindAndAddJurisdictionParent(parentJurisdiction, jurisdictionList);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreatePRTCredentialSelectionFilters(props) {
  const translate = props.translate;
  const handleClose = props.handleClose;
  const isOpen = props.isOpen;
  const setJurisdictionsParent = props.setJurisdictions;

  const [jurisdictions, setJurisdictions] = useState([]);
  const [jurisdictionsLoading, setJurisdictionsLoading] = useState(false);

  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);

  const dispatch = useDispatch();

  if (jurisdictions.length === 0) {
    retrieveJurisdictions(setJurisdictions, setJurisdictionsLoading);
  }

  const getJurisdictionStringRecursive = (parent) => {
    const consolidation = {
      finalCodes: [parent.code],
      selectedChildrenCount: 0,
      totalChildrenCount: parent.children ? parent.children.length : 0
    };

    if (!parent.children || consolidation.totalChildrenCount <= 0) {
      return consolidation;
    }

    let childrenCodes = [];
    for (const child of parent.children) {
      if (!selectedJurisdictions.includes(child)) {
        continue;
      }

      consolidation.selectedChildrenCount++;
      childrenCodes.push(getJurisdictionStringRecursive(child));
    }

    const finalFinalCodes = [];
    let indeterminateChildren = false;

    for (const childCode of childrenCodes) {
      for (const childFinalCode of childCode.finalCodes) {
        finalFinalCodes.push(`${parent.code}.${childFinalCode}`);
      }

      if (childCode.selectedChildrenCount !== childCode.totalChildrenCount) {
        indeterminateChildren = true;
      }
    }

    if (
      indeterminateChildren ||
      finalFinalCodes.length !== consolidation.totalChildrenCount ||
      consolidation.selectedChildrenCount !== consolidation.totalChildrenCount
    ) {
      consolidation.finalCodes = finalFinalCodes;
    }

    return consolidation;
  };

  const applyFilters = () => {
    const parentJurisdictions = selectedJurisdictions.filter((jurisdiction) => jurisdiction.parentId === null);

    const jurisdictionCodes = [];
    for (const parentJurisdiction of parentJurisdictions) {
      const bulk = getJurisdictionStringRecursive(parentJurisdiction);

      for (const code of bulk.finalCodes) {
        jurisdictionCodes.push(code);
      }
    }

    setJurisdictionsParent(jurisdictionCodes);
    dispatch(clearCatalogCache());
    handleClose(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('admin-prt-popup-create-template')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item='true' paddingTop='10px' paddingBottom='10px'>
            <Typography fontWeight='bold' fontSize='0.938rem'>
              {translate('admin-prt-popup-credential-selection-filters-title')}
            </Typography>
          </Grid>

          <Grid item='true'>
            <Grid container spacing={1} padding={1}>
              <Grid item='true' paddingRight={1}>
                <Grid container direction='column' alignItems='center' justifyContent='center'>
                  <Grid item='true'>
                    <Typography fontWeight='bold' fontSize='0.938rem'>
                      {translate('admin-prt-popup-credential-selection-filters-jurisdiction')}
                    </Typography>
                  </Grid>

                  <Grid item='true'>
                    <SpinnerLoader
                      translate={translate}
                      text={translate('admin-prt-popup-credential-selection-filters-jurisdictions-loading')}
                      isLoading={jurisdictionsLoading}>
                      {jurisdictions.map((jurisdiction) => (
                        <TextDropdown
                          key={jurisdiction.code}
                          object={jurisdiction}
                          selectedValues={selectedJurisdictions}
                          checkBoxChange={(jurisdictionList, checked) => {
                            const selectedCopy = [...selectedJurisdictions];

                            if (checked) {
                              for (const jurisdiction of jurisdictionList) {
                                if (selectedCopy.includes(jurisdiction)) {
                                  continue;
                                }

                                selectedCopy.push(jurisdiction);
                              }
                            } else if (!checked) {
                              for (const jurisdiction of jurisdictionList) {
                                const index = selectedCopy.indexOf(jurisdiction);
                                if (index === -1) {
                                  continue;
                                }

                                selectedCopy.splice(index, 1);
                              }
                            }

                            setSelectedJurisdictions(selectedCopy);
                          }}
                        />
                      ))}
                    </SpinnerLoader>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item='true' paddingBottom='15px' paddingTop='10px'>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
              <Grid item='true'>
                <Button
                  onClick={handleClose}
                  color='secondary'
                  variant='contained'
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-prt-popup-cancel-button')}
                </Button>
              </Grid>
              <Grid item='true'>
                <Button
                  onClick={applyFilters}
                  color='secondary'
                  variant='contained'
                  sx={{
                    border: '1px solid #E5E5E5',
                    fontSize: '0.938rem',
                    fontWeight: '600',
                    width: '130px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      border: '1.25px solid #E5E5E5',
                      boxShadow: 'none'
                    }
                  }}>
                  {translate('admin-prt-popup-apply-button')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
