// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

// ------FILE MODULES---------------------------------------------------------
import { LEVELS_OF_ASSURANCE_SIMPLIFIED } from '#constants';

import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_CATALOG_ENTRY = {};
const DEFAULT_SELECTED_CREDENTIALS = {};
const DEFAULT_HIDE_CHECKBOXES = false;
const DEFAULT_DISABLE_CHECKBOXES = false;
const DEFAULT_ON_CREDENTIAL_SELECTION_CHANGED = () => {};
const DEFAULT_MAX_NAME_LENGTH = 50;
const DEFAULT_MAX_USE_CASE_LENGTH = 40;
const DEFAULT_PADDING_LEFT = undefined;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogCredentialRow(props) {
  const catalogEntry = props.catalogEntry || DEFAULT_CATALOG_ENTRY;
  const selectedCredentials = props.selectedCredentials || DEFAULT_SELECTED_CREDENTIALS;
  const hideCheckboxes = props.hideCheckboxes || DEFAULT_HIDE_CHECKBOXES;
  const disableCheckboxes = props.disableCheckboxes || DEFAULT_DISABLE_CHECKBOXES;
  const onCredentialSelectionChanged = props.onCredentialSelectionChanged || DEFAULT_ON_CREDENTIAL_SELECTION_CHANGED;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxUseCaseLength = props.maxUseCaseLength || DEFAULT_MAX_USE_CASE_LENGTH;
  const textPaddingLeft = props.textPaddingLeft || DEFAULT_PADDING_LEFT;

  const [loa, setLOA] = useState(selectedCredentials[catalogEntry.name] ? selectedCredentials[catalogEntry.name].loa : LEVELS_OF_ASSURANCE_SIMPLIFIED[0]);
  
  const onCheckboxCheckChanged = (e) => {
    onCredentialSelectionChanged(e, catalogEntry, loa);
  };

  const onLoaChanged = (e) => {
    setLOA(Number(e.target.value));
    onCredentialSelectionChanged({ target: { checked: true } }, catalogEntry, e.target.value);
  };

  let selectedLoa = selectedCredentials[catalogEntry.name];

  if (selectedLoa) {
    selectedLoa = selectedLoa.loa;
  }

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <Hider isHidden={hideCheckboxes}>
        <TableCell useTypography={false}>
          <Checkbox
            onChange={onCheckboxCheckChanged}
            value={selectedLoa === loa}
            checked={selectedLoa === loa}
            disabled={disableCheckboxes}
          />
        </TableCell>
        <TableCell useTypography={false}>
          <TextField
            required
            value={loa}
            disabled={disableCheckboxes}
            type='number'
            InputProps={{
              inputProps: { min: LEVELS_OF_ASSURANCE_SIMPLIFIED[0], max: LEVELS_OF_ASSURANCE_SIMPLIFIED[2] }
            }}
            onChange={onLoaChanged}
            sx={{
              height: '2.5rem',
              width: '3.5rem',
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '0.313rem',
              fontSize: '0.938rem',
              '.MuiInputBase-root': {
                height: '2.5rem',
                '&:hover': {
                  border: '1.25px solid #E5E5E5'
                }
              },
              '&.Mui-focused': {
                border: 'none'
              },
              '&.MuiOutlinedInput-root:hover': {
                border: 'none'
              }
            }}
          />
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <MaxLengthText
          text={catalogEntry.alias}
          maxLength={maxNameLength}
          typographyProps={{ paddingLeft: textPaddingLeft }}
        />
      </TableCell>
      <TableCell useTypography={false}>      
        <MaxLengthText text={catalogEntry.origin[0].qualifiedProvider} maxLength={maxNameLength} typographyProps={{ paddingLeft: textPaddingLeft }}/>
      </TableCell>
      <TableCell>{catalogEntry.holder}</TableCell>
      <TableCell>{catalogEntry.format.charAt(0).toUpperCase() + catalogEntry.format.slice(1)}</TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.tags[0].category} maxLength={maxUseCaseLength} />
      </TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.tags[0].subcategory} maxLength={maxUseCaseLength} />
      </TableCell>
    </TableRow>
  );
}
