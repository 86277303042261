// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH, ROW_COUNT } from '#constants';

import PRTCredentialRow from '../Rows/PRTCredentialRow.component';
import LOATooltip from '../Tooltips/LOATooltip.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './PRTCredentialTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CATALOG = [];
const DEFAULT_IS_LOADING = false;
const DEFAULT_CATALOG_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SET_ROWS_PER_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[1];
const DEFAULT_MAX_ISSUER_LENGTH = MAX_LENGTH[2];
const DEFAULT_MAX_HOLDER_LENGTH = MAX_LENGTH[2];

function getHeaders(translate) {
  return [
    { text: 'prt-credential-table-credential-name' },
    {
      text: 'prt-credential-table-loa',
      component: <LOATooltip translate={translate} />
    },
    { text: 'prt-credential-table-official-issuer' },
    { text: 'prt-credential-table-holder' }
  ]
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PRTCredentialTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const catalog = props.catalog || DEFAULT_CATALOG;
  const catalogCount = props.catalogCount || DEFAULT_CATALOG_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const setRowsPerPage = props.setRowsPerPage || DEFAULT_SET_ROWS_PER_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxIssuerLength = props.maxIssuerLength || DEFAULT_MAX_ISSUER_LENGTH;
  const maxHolderLength = props.maxHolderLength || DEFAULT_MAX_HOLDER_LENGTH;

  const headers = getHeaders(translate);

  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  return (
    <Grid item='true' paddingBottom={4}>
      <TableContainer className='prt-credential-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('prt-credential-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {catalog.map((catalogEntry) => {
                return (
                  <PRTCredentialRow
                    key={catalogEntry.name}
                    translate={translate}
                    catalogEntry={catalogEntry}
                    maxNameLength={maxNameLength}
                    maxIssuerLength={maxIssuerLength}
                    maxHolderLength={maxHolderLength}
                  />
                );
              })}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    labelRowsPerPage={translate(`rows-per-page`)}
                    component='div'
                    count={catalogCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={ROW_COUNT}
                    page={page}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value);
                    }}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px 0px 0px 5px'
                      }
                    }}
                    nextIconButtonProps={{
                      sx: {
                        padding: '2px',
                        border: '1px solid #E5E5E5',
                        borderRadius: '0px 5px 5px 0px'
                      }
                    }}
                    SelectProps={{
                      sx: {
                        border: '1px solid #E5E5E5',
                        borderRadius: '5px'
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
