// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------
// ------NODE MODULES---------------------------------------------------------
import React from 'react';

import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_PLACEHOLDER = 'search-box-text';
const DEFAULT_VALUE = '';
const DEFAULT_DISABLED = false;
const DEFAULT_ON_CHANGE = () => {};
const DEFAULT_ON_SUBMIT = () => {};
const DEFAULT_STYLE = {
  height: '2.5rem',
  width: '17.188rem',
  backgroundColor: 'white',
  border: '1px solid #E5E5E5',
  borderRadius: '0.313rem',
  fontSize: '0.938rem',
  fontWeight: '500',
  lineHeight: '1rem',
  '&:hover': {
    border: '2px solid #E5E5E5'
  },
  '.MuiFilledInput-input': {
    paddingTop: '16.5px',
    paddingBottom: '16.5px',
    color: '#666666'
  }
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SearchBar(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const placeholder = props.placeholder || translate(DEFAULT_PLACEHOLDER);
  const value = props.value || DEFAULT_VALUE;
  const disabled = props.disabled || DEFAULT_DISABLED;
  const onChange = props.onChange || DEFAULT_ON_CHANGE;
  const onSubmit = props.onSubmit || DEFAULT_ON_SUBMIT;
  const style = { ...DEFAULT_STYLE, ...props.style };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl variant='filled'>
        <FilledInput
          disableUnderline
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          sx={style}
          type='text'
        />
      </FormControl>
    </form>
  );
}
