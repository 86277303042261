// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import Grid from '@mui/material/Grid2';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MoreVert from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

// ------FILE MODULES---------------------------------------------------------

import TableCell from '../../shared/Tables/TableCell.component';
import PRTButtons from '../Dropdowns/PRTButtons';
import Hider from '../../shared/Hider/Hider.component';
import { Link } from '@mui/material';
import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import { MAX_LENGTH } from '#constants';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_OPEN = false;
const DEFAULT_SET_OPEN = () => { };
const DEFAULT_HIDE_COLLAPSIBLE = false;
const DEFAULT_HIDE_ENTITY_ACTIONS = false;
const DEFAULT_HIDE_ADMIN_ACTIONS = false;

const DEFAULT_PRESENTATION_REQUEST = {};
const DEFAULT_PRESENTATIONS = [];
const DEFAULT_MAX_LENGTH_DESCRIPTION = MAX_LENGTH[0];


// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestDetailRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const presentationRequest = props.PresentationRequest || DEFAULT_PRESENTATION_REQUEST;
  const presentations = props.presentations || DEFAULT_PRESENTATIONS;
  const open = props.open || DEFAULT_OPEN;
  const setOpen = props.setOpen || DEFAULT_SET_OPEN;
  const hideCollapsible = props.hideCollapsible || DEFAULT_HIDE_COLLAPSIBLE;
  const hideEntityActions = props.hideEntityActions || DEFAULT_HIDE_ENTITY_ACTIONS;
  const hideAdminActions = props.hideAdminActions || DEFAULT_HIDE_ADMIN_ACTIONS;
  const setIsPresentationDetailsOpen = props.setIsPresentationDetailsOpen || false;
  const setIsPresentationAttachOpen = props.setIsPresentationAttachOpen || false;
  const setIsPresentationSendOpen = props.setIsPresentationSendOpen || false;
  const setSelectedPresentationRequest = props.setSelectedPresentationRequest || false;
  const  maxLengthDescription = props.maxLengthDescription || DEFAULT_MAX_LENGTH_DESCRIPTION;

  const name = presentationRequest.decoded.pr.procId;
  console.log(presentationRequest);
  const createdAt = presentationRequest.storedJwt.createdAt;
  const purpose = presentationRequest.decoded.pr.procDesc;
  const creationADate = createdAt ? new Date(createdAt).toLocaleDateString(undefined, { dateStyle: 'medium' }) : 'N/A';
  const creationTime = createdAt ? new Date(createdAt).toLocaleTimeString(undefined, { timeStyle: 'medium' }) : 'N/A';
  const creationDate = `${creationADate} - ${creationTime}`


  const isPresentationResponse = () => {
    return presentations.length > 0;
  };

  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <Hider isHidden={hideCollapsible}>
        <TableCell useTypography={false}>
          <Grid container direction="row" alignItems="center">
            <Grid item='true'>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <Grid container direction="row" alignItems="center">
          <Grid item='true'>
            <MaxLengthText maxLength={MAX_LENGTH[0]} text={name}></MaxLengthText>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText maxLength={maxLengthDescription} text={purpose}></MaxLengthText>
      </TableCell>
      <TableCell>{creationDate}</TableCell>
      <TableCell useTypography={false}>
        <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
          <Hider isHidden={/*hideAdminActions*/false}>
              <Grid item='true'>
                <Button
                  color='primary'
                  variant='contained'
                  fullWidth
                  size='small'
                  sx={{
                    height: '2.5rem',
                    textTransform: 'none'
                  }}
                  disabled={!isPresentationResponse()}
                  onClick={ () => {
                    setIsPresentationDetailsOpen(true)
                    setSelectedPresentationRequest(presentationRequest)
                    }
                  }>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{translate('pr-credential-button-see-details')}</Typography>
                </Button>
              </Grid>
          </Hider>
          <Hider isHidden={hideEntityActions}>
              <Grid item='true'>
                <Button
                  color='primary'
                  variant='contained'
                  fullWidth
                  size='small'
                  sx={{
                    height: '2.5rem',
                    textTransform: 'none'
                  }}
                  disabled={/*isPresentationResponse()*/false}
                  onClick={ () => {
                    setIsPresentationAttachOpen(true)
                    setSelectedPresentationRequest(presentationRequest)
                    }
                  }>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{translate('pr-credential-button-attach')}</Typography>
                </Button>
              </Grid>
          </Hider>
          <Hider isHidden={hideEntityActions}>
              <Grid item='true'>
                <Button
                  color='primary'
                  variant='contained'
                  fullWidth
                  size='small'
                  sx={{
                    height: '2.5rem',
                    textTransform: 'none'
                  }}
                  disabled={/*isPresentationResponse()*/false}
                  onClick={ () => {
                    setIsPresentationSendOpen(true)
                    setSelectedPresentationRequest(presentationRequest)
                    }
                  }>
                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{translate('pr-credential-button-forward')}</Typography>
                </Button>
              </Grid>
          </Hider>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
