// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

// ------FILE MODULES---------------------------------------------------------
import PresentationRequestDetailRow from "./PresentationRequestDetailRow.component";
import PRTCredentialTable from "../Tables/PRTCredentialTable.component";
import Hider from "../../shared/Hider/Hider.component";
import TableCell from '@mui/material/TableCell';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => { return string };
const DEFAULT_PRESENTATION_REQUEST_TEMPLATE = {};
const DEFAULT_OPEN_POPUP_INSTEAD = false;
const DEFAULT_HIDE_FILE = false;
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_PRESENTATION_REQUEST = {};
const DEFAULT_PRESENTATIONS = [];

const DEFAULT_MAX_NAME_LENGTH = 50;
const DEFAULT_COLLAPSED_NAME_PADDING = 5;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CollapsiblePRRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const presentationRequest = props.PresentationRequest || DEFAULT_PRESENTATION_REQUEST;
  const presentations = props.presentations || DEFAULT_PRESENTATIONS;
  const hideFile = props.hideFile || DEFAULT_HIDE_FILE;
  const hideEntityActions = props.hideEntityActions || false;
  const hideAdminActions = props.hideAdminActions || false;
  const setIsPresentationDetailsOpen = props.setIsPresentationDetailsOpen || false;
  const setIsPresentationAttachOpen = props.setIsPresentationAttachOpen || false;
  const setIsPresentationSendOpen = props.setIsPresentationSendOpen || false;
  const setSelectedPresentationRequest = props.setSelectedPresentationRequest || false;

  const [open, setOpen] = useState(false);

  function collapsedRows() {
    return (
      <TableCell colSpan={6}>
      <PRTCredentialTable
        translate={translate}
        catalog={presentationRequest.decoded.pr.data}
        catalogCount={presentationRequest.decoded.pr.data.length}
        hidePagination={true}
        maxNameLength={70}
        maxIssuerLength={70}
        maxHolderLength={70}
      />
      </TableCell>
    );
  }

  return (
    <>
      <PresentationRequestDetailRow
        key={presentationRequest.decoded.jti}
        translate={translate}
        PresentationRequest={presentationRequest}
        open={open}
        setOpen={setOpen}
        hideCollapsible={false}
        maxLengthDescription={30}
        hideEntityActions={hideEntityActions}
        hideAdminActions={hideAdminActions}
        setIsPresentationDetailsOpen={setIsPresentationDetailsOpen}
        setIsPresentationAttachOpen={setIsPresentationAttachOpen}
        setIsPresentationSendOpen={setIsPresentationSendOpen}
        setSelectedPresentationRequest={setSelectedPresentationRequest}
        presentations={presentations}
      />
      <Hider isHidden={!open}>
        {collapsedRows()}
      </Hider>
    </>
  )
}
