import { configureStore } from '@reduxjs/toolkit';
import corporate from './reducers/corporate';
import catalog from './reducers/catalog';
import credentials from './reducers/adminCredentials';
import credentialRequests from './reducers/adminCredentialRequests';
import presentations from './reducers/adminPresentations';
import presentationRequestTemplates from './reducers/presentationRequestTemplates';
import entityCredentials from './reducers/entityCredentials';
import entityCredentialRequests from './reducers/entityCredentialRequests';
import entityPresentations from './reducers/entityPresentations';
import entityPresentationRequests from './reducers/entityPresentationRequests';
import customer from './reducers/customers';
import notifications from './reducers/notifications';
import error from './reducers/error';
import session from './reducers/session';

export default configureStore({
  reducer: {
    corporate: corporate,
    catalog: catalog,
    credentials: credentials,
    credentialRequests: credentialRequests,
    presentations: presentations,
    entityCredentials: entityCredentials,
    entityCredentialRequests: entityCredentialRequests,
    presentationRequestTemplates: presentationRequestTemplates,
    entityPresentations: entityPresentations,
    entityPresentationRequests: entityPresentationRequests,
    customer: customer,
    notifications: notifications,
    error: error,
    session: session
  }
});
