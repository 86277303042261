// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { PLATFORM_TYPE, REDUCER_STATUS } from '#constants';

import {
  clearCustomerCache,
  fetchCustomersAsync,
  selectCustomers,
  selectCustomersCount,
  selectCustomersPage,
  selectCustomersState,
  selectCustomersRowsPerPage
} from '#reducers/customers';

import ConnectIndividualContactPopup from '#privateComponents/Popups/ConnectIndividualContactPopup.component';
import InviteIndividualContactPopup from '#privateComponents/Popups/InviteIndividualContactPopup.component';
import ContactFilters from '#privateComponents/Filters/ContactFilters.component';
import ContactsTable from '#privateComponents/Tables/ContactsTable.component';

import ViewsLayout from '../../../../views/Main/MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function IndividualContacts(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  
  const dispatch = useDispatch();

  const [isConnectPopupOpen, setIsConnectPopupOpen] = useState(false);
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectCustomersPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectCustomersRowsPerPage));

  const contacts = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);
  const contactCount = useSelector(selectCustomersCount);

  const isLoading = customerState === REDUCER_STATUS.LOADING;
  const needsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCustomerCache());
  };

  if (needsReload) {
    dispatch(
      fetchCustomersAsync({
        comms: PLATFORM_TYPE[1],
        page,
        limit: rowsPerPage,
        search,
        status: status ? status.status : undefined
      })
    );
  }

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearCustomerCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCustomerCache());
  };

  return (
    <ViewsLayout
      pageTitle={translate('contacts-individual-page-title')}
      buttons={[
        {
          onClick: () => setIsConnectPopupOpen(true),
          text: translate('contacts-individual-page-connect')
        },
        {
          onClick: () => setIsInvitePopupOpen(true),
          text: translate('contacts-individual-page-send-invitation')
        }
      ]}
      layoutSize={layoutSize}>
      <ConnectIndividualContactPopup
        translate={translate}
        isOpen={isConnectPopupOpen}
        setIsOpen={setIsConnectPopupOpen}
      />
      <InviteIndividualContactPopup
        translate={translate}
        isOpen={isInvitePopupOpen}
        setIsOpen={setIsInvitePopupOpen}
      />

      <Grid item='true'>
        <ContactFilters
          translate={translate}
          search={search}
          setSearch={setSearch}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <ContactsTable
        translate={translate}
        isLoading={isLoading}
        contacts={contacts}
        contactCount={contactCount}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideTelephone={false}
        hideCif={true}
        translatePrefix={`contacts-individual-table`}
      />
    </ViewsLayout>
  );
}
