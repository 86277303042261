// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useDispatch } from 'react-redux';

import TableRow from '@mui/material/TableRow';

// ------FILE MODULES---------------------------------------------------------
import { setError } from '../../../store/reducers/error';
import { MAX_LENGTH } from '#constants';

import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import TableCell from '../../shared/Tables/TableCell.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_CATALOG_ENTRY = {};
const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[1];
const DEFAULT_MAX_ISSUER_LENGTH = MAX_LENGTH[2];
const DEFAULT_MAX_HOLDER_LENGTH = MAX_LENGTH[2];

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PRTCredentialRow(props) {
  const catalogEntry = props.catalogEntry || DEFAULT_CATALOG_ENTRY;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxIssuerLength = props.maxIssuerLength || DEFAULT_MAX_ISSUER_LENGTH;
  const maxHolderLength = props.maxHolderLength || DEFAULT_MAX_HOLDER_LENGTH;

  const dispatch = useDispatch();
  const levelOfAssurance = catalogEntry.loa || catalogEntry.levelOfAssurance;
  console.log(catalogEntry);

  let qualifiedProvider = '';
 
  if (!catalogEntry.origin || !catalogEntry.origin[0]) {
    dispatch(setError({ reason: 'catalogNotAvailable'}));
  } else {
    qualifiedProvider = catalogEntry.origin[0].qualifiedProvider;
  }
 
  return (
    <TableRow sx={{ border: '1px solid #E5E5E5', backgroundColor: '#FAFAFA' }}>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.alias || catalogEntry.name || catalogEntry.type} maxLength={maxNameLength} />
      </TableCell>
      <TableCell>{levelOfAssurance}</TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={qualifiedProvider} maxLength={maxIssuerLength} />
      </TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.holder || ''} maxLength={maxHolderLength} />
      </TableCell>
    </TableRow>
  );
}
