// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { createRef, forwardRef, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// ------FILE MODULES---------------------------------------------------------
import CalendarIcon from '../Icons/CalendarIcon.component';

import './DatePicker.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_REQUIRED = false;
const DEFAULT_DISABLED = false;
const DEFAULT_TIME = '09:00:00';
const DEFAULT_OFFSET = 86400000;
const DEFAULT_PLACEHOLDER = '';
const DEFAULT_MIN = '';
const DEFAULT_MAX = '';
const DEFAULT_VALUE = '';
const DEFAULT_WIDTH = '17.188rem';
const DEFAULT_ON_CHANGE = () => {};

const DEFAULT_INNER_INPUT_PROPS = {
  sx: {
    padding: '0.5rem 1.188rem'
  }
};
const DEFAULT_INPUT_STYLE = {
  height: '2.5rem',
  color: '#666666',
  backgroundColor: 'white',
  border: '1px solid #E5E5E5',
  borderRadius: '0.313rem',
  fontSize: '0.938rem',
  '&:hover': {
    border: '2px solid #E5E5E5'
  },
  paddingRight: '0px'
};
const DEFAULT_INPUT_PROPS = {
  endAdornment: (
    <InputAdornment position='end'>
      <CalendarIcon />
    </InputAdornment>
  )
};
const DEFAULT_TEXTFIELD_PROPS = {};

function Input(props, ref) {
  const text = props.text;
  const className = props.className + ' date-picker-field';

  const customWidth = props.customWidth;
  const onChangeNative = props.onChangeNative;
  const value = props.value;
  const inputProps = {...props, onChangeNative: undefined};
  delete inputProps.customWidth;
  delete inputProps.onChangeNative;
  delete inputProps.value;

  // This is necessary to access the DOM and be able to both listen to the
  // native onChange event (which for date pickers is necessary, as onInput
  // is fired EVERY TIME the input is interacted with, and react's onChange
  // hooks onto the DOM's onInput rather than the DOM's onChange) as well as
  // setting the DOM's element value, so that clear on the date picker works.
  const input = createRef();

  useEffect(() => {
    input.current.onchange = onChangeNative;
    input.current.value = value;
  }, [input, onChangeNative, value]);

  return (
    <div className={customWidth ? 'date-picker-div-small' : 'date-picker-div'}>
      <input {...inputProps} className={className} ref={input} />
      <span className='date-picker-span'>{text}</span>
    </div>
  );
}
// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DatePicker(props) {
  const required = props.required || DEFAULT_REQUIRED;
  const disabled = props.disabled || DEFAULT_DISABLED;
  const defaultTime = props.defaultTime || DEFAULT_TIME;
  const offset = props.offset || DEFAULT_OFFSET;
  const min = props.min ? new Date(props.min + offset).toISOString().split('T')[0] : DEFAULT_MIN;
  const max = props.max ? new Date(props.max - offset).toISOString().split('T')[0] : DEFAULT_MAX;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;
  const value = props.value ? new Date(props.value).toISOString().split('T')[0] : DEFAULT_VALUE;
  const width = props.width || DEFAULT_WIDTH;
  const onChange = props.onChange || DEFAULT_ON_CHANGE;

  const handleChange = (event) => {
    if (!event.target.value)
      onChange(undefined);

    onChange(new Date(`${event.target.value} ${defaultTime}`).getTime());
  };

  const innerInputProps = {
    ...DEFAULT_INNER_INPUT_PROPS,
    ...props.innerInputProps,
    text: value || placeholder,
    value: value,
    placeholder,
    required,
    selection: value,
    customWidth: width !== DEFAULT_WIDTH,
    onChangeNative: handleChange,
    min,
    max
  };

  const inputProps = {
    ...DEFAULT_INPUT_PROPS,
    ...props.inputProps,
    inputProps: innerInputProps,
    inputComponent: forwardRef(Input),
    sx: {
      ...DEFAULT_INPUT_STYLE,
      width: width
    }
  };

  const textFieldProps = {
    ...DEFAULT_TEXTFIELD_PROPS,
    ...props.textFieldProps,
    type: 'date',
    disabled,
    InputProps: inputProps
  };

  return <TextField {...textFieldProps} />;
}
